.delivery-bg {
    background: unset;
    background: #fff;
}

.delivery-section-1.m-t-5 {
    margin-top: 5%;
}

/* .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
} */


.delivery-section-1 {
    /* margin-top: 5%; */
}

.sub-2 .product-amount {
    font: 700 24px/19px Sarabun bold;
    color: #083863;
}

.product-amount.offer-amount span {
    color: #666666;
    text-decoration: line-through;
    font: 16px Sarabun;
}

.sub-2 .product-amount {
    font: 700 24px/19px Sarabun bold;
    color: #083863;
}

.delivery-container-1 .sub-1 img {
    height: 50px;
    display: block;
    vertical-align: middle;
    margin: auto;
    margin-bottom: 13%;
}

.delivery-container-1 .sub-1 {
    display: flex;
    color: #083863;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 8%;
    font: 500 18px/23.4px Sarabun;
}

.product-description ul {
    padding-left: 4%;
}

.product-description li {
    font: 400 17px/23px Sarabun;
    color: #353636;
    margin: 2% 0;
    list-style-type: none;
}

.product-description ul li::before {
    content: "\2022";
    color: #d2c061;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 24px;
}

/* .pin-input {
    margin: auto;
    margin-bottom: 6%;
    width: 100%;
} */

.product-description-1 span {
    color: #000;
    font: 600 16px/24px Sarabun;
}

.product-description-1 {
    padding-left: 2%;
    font: 400 16px/15px Sarabun;
    margin-bottom: 8%;
    color: #353636;
}

.product-description h3 {
    font: 800 24px/20px Sarabun;
}

.m-header {
    display: none;
}

.h4, h4 {
    font-size: 1.5rem;
}

.local-nav, .local-nav a {
    color: #083863;
    font: 500 16px Sarabun;
}

.local-nav, .local-nav a {
    color: #083863;
    font: 500 16px Sarabun;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

/* .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
} */

.carousel {
    position: relative;
}

#custCarousel .carousel-inner {
    width: 61% !important;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}
#custCarousel .carousel-item img {
    width: 80%;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}
.carousel-indicators {
    bottom: -14% !important;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol, ul {
    list-style: none;
}

.sub-2 {
    padding: 0 5%;
}

.sub-2 div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8%;
    margin-top: 3%;
}

.offer-div {
    justify-content: left !important;
    margin-bottom: 3% !important;
    color: #00bbb4;
    font: 13px Sarabun;
    font-style: italic;
    font-weight: 700;
}

.offer-div span {
    background: rgba(0, 187, 180, 0.12);
    padding: 5px 7px;
    margin-right: 3%;
    color: #083863;
    font-style: normal;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.offer-div span img {
    width: 20px;
    /* -webkit-animation: scaling-img 1.5s infinite linear; */
}

.offer-div em {
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
}
.desktop-only {
    display: block;
}

.sub-2 h3 {
    color: #00bbb4;
    font: 600 28px/35px Sarabun;
}

.sub-2 div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8%;
    margin-top: 3%;
}

.sub-2 .product-amount {
    font: 700 24px/19px Sarabun bold;
    color: #083863;
}

.sub-2 .product-amount {
    font: 700 24px/19px Sarabun bold;
    color: #083863;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}