@import url(https://fonts.googleapis.com/css?family=Sarabun:100,300,400,500,600,700,800&display=swap);
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

/* buy coin */
.delivery-bg {
    background: url('https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/header-slant-bg-desktop.svg') 0px -73px;
    background-repeat: no-repeat;
    background-size: 100%;
}

.delivery-container-1 h1 {
    font: 700 40px/60px Sarabun;
    color: #083863;
    margin-bottom: 16%;
}

.delivery-section-1.m-t-5 {
    margin-top: 5%;
}

.delivery-container-1 .sub-1.m-b.sub__2 {
    margin-bottom: 28%;
}

.delivery-container-1 .sub-1 {
    display: flex;
    color: #083863;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 22%;
    font: 500 18px/23.4px Sarabun;
}

.delivery-container-1 .sub-1 img {
    height: 50px;
    display: block;
    vertical-align: middle;
    margin: auto;
    margin-bottom: 13%;
}

img {
    /* vertical-align: middle; */
    border-style: none;
}

.delivery-container-1 .sub-1.m-b div {
    padding: 0 1%;
}

.pincode-wrapper h4.p-h4 {
    color: #083863;
}

.pincode-wrapper h4 {
    font: 800 32px/55.8px Sarabun;
}

.pin-input {
    background: #f7f7f7;
    color: #a6a0a0;
    font: 600 16px/20px Sarabun;
    border-radius: 5px;
    height: 64px;
    width: 472px;
    padding: 20px 10px;
}

.form-group {
    margin-bottom: 1rem;
}

.pincode-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4%;
}

.delivery-section-1.delivery-section__2.m-t-5.mobile-t-15.row {
    margin-top: 0%;
}

.deliverable-span {
    margin-top: 6%;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.pin-input img {
    margin-right: 2%;
}
img {
    vertical-align: middle;
    border-style: none;
}

.pin-input input[type="tel"] {
    background: transparent;
    border: 0px;
    outline: none;
    width: 63%;
    font-weight: normal;
}

input, textarea {
    background-image: none!important;
}

button, input {
    overflow: visible;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.pin-input span {
    color: #00bbb4;
    cursor: pointer;
    font-weight: normal;
}

.disabled-pin {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.custom-container {
    width: 95%;
    /* margin: auto; */
}

.product-container {
    display: flex;
    flex-wrap: wrap;
}

.product-container .product-wrapper {
    flex: 0 0 32.333%;
    position: relative;
    margin-right: 1%;
    margin-bottom: 1%;
}

.delivery-container-1 {
    position: relative;
}

.delivery-section-1 {
    padding: 0rem 8%;
}

.promo-offer {
    position: absolute;
    left: 2px;
    height: auto;
    width: 55%;
    color: #083863;
    padding: 5px;
    z-index: 1;
    background: rgba(0, 187, 180, 0.12);
    border-radius: 5px;
}

.promo-offer span {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    vertical-align: middle;
}

.btn-primary1.e-pd {
    padding: 14px 10%;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.btn {
    font-size: 14px!important;
}
.btn-primary1 {
    height: 48px;
    border-radius: 5px;
    min-width: 120px;
    color: #fff;
    padding: 14px 24px;
    background-color: #00bbb4;
    box-shadow: 0 5px 30px -10px rgb(0, 187, 180, .5);
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    -ms-transition: .2s;
}

.product-price.offer-price span {
    color: #707070 !important;
    text-decoration: line-through;
    font-weight: 500;
    margin-right: 10px;
}


.text-center {
    text-align: center!important;
}

.c-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
}

.c-b {
    border: 1px solid #d5d5d5;
    /* border: 1px solid rgba(212, 212, 212, 0.3); */
    margin: 1%;
    padding-bottom: 4%;
    min-height: 480px;
    background: #fff;
    cursor: pointer;
}

.promo-offer {
    position: absolute;
    left: 2px;
    height: auto;
    width: 55%;
    color: #083863;
    padding: 5px;
    z-index: 1;
    background: rgba(0, 187, 180, 0.12);
    border-radius: 5px;
}

.coin-img img {
    width: 65%;
    height: auto;
    position: relative;
}

img {
    vertical-align: middle;
    border-style: none;
}

.product-name {
    font: 600 18px/21px Sarabun;
    width: 62%;
    margin: auto;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.product-price {
    font: 600 20px/15px Sarabun;
    color: #00bbb4;
    margin: 6% 0px;
}

#online-accordion .card.d-card {
    box-shadow: none;
    border: 0px;
}

.accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card .card-header {
    margin-bottom: -1px;
}

.accordion .card-header:not(:last-child) {
    border-bottom: 1px solid #ddd;
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.accordion .card-header {
    background: #fff;
    padding-left: 3rem;
    padding-right: 7rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.card-header {
    border: 0px !important;
}

.mb-0, .my-0 {
    margin-bottom: 0!important;
}
.h5, h5 {
    font-size: 1.25rem;
}
#heading-1 a {
    font: 700 32px/48px Sarabun;
    color: #083863 !important;
}
a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.public-faq-section .delivery-container-1 {
    padding: 0 8rem;
}

.public-faq-section .mb-0 > a:after {
    content: "+";
    position: absolute;
    right: 2%;
    font-size: 3rem;
    line-height: 3rem;
}
.card-body {
    background: #fff !important;
    border: 0px !important;
}
.accordion .card-body {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background: #fafafa;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-size: 16px;
    line-height: 1.5;
    color: #666;
}

.review-header {
    display: flex;
    justify-content: space-between;
    font: 400 25px/15px Sarabun;
    align-items: center;
    margin-bottom: 3%;
    color: #000;
}
.review-header span {
    font: 800 32px/50px Sarabun;
    color: #083863;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}




ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.container1 {
    margin: 0 10rem;
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

* {
    font-family: Sarabun, sans-serif;
    scroll-behavior: smooth;
}

body,
html {
    font-family: Sarabun, sans-serif;
}

html {
    font-size: 62.5%;
}

.image-container img {
    max-width: 100%;
}

.video-container {
    border-radius: 10px;
    overflow: hidden;
}

.video-container source,
.video-container video {
    max-width: 100%;
}

.video-container div,
.video-container iframe {
    border-radius: 10px;
    overflow: hidden;
}

h1 {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.25;
}

footer {
    font-family: Sarabun, sans-serif;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

footer .footer-outer {
    -webkit-clip-path: polygon(0 9%, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 9%, 100% 0, 100% 100%, 0 100%);
    background: radial-gradient(366.99% 902.34% at -156.91% 40.41%, #ffefc0 0, #dfaf10 33%, #bd8c2b 71%, #9d7c4f 100%);
}

@media (max-width: 480px) {
    footer .footer-outer {
        -webkit-clip-path: polygon(0 3%, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 3%, 100% 0, 100% 100%, 0 100%);
    }
}

footer .footer-outer .footer-inner {
    -webkit-clip-path: polygon(0 10%, 100% 1%, 100% 100%, 0 100%);
    clip-path: polygon(0 10%, 100% 1%, 100% 100%, 0 100%);
    background: #f6f9fc;
    padding-top: 15rem;
    padding-bottom: 2.2rem;
    padding-left: 12rem;
    padding-right: 6rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

@media (max-width: 480px) {
    footer .footer-outer .footer-inner {
        padding: 12rem 2% 4.2rem 2%;
        -webkit-clip-path: polygon(0 3.5%, 100% 0.5%, 100% 100%, 0 100%);
        clip-path: polygon(0 3.5%, 100% 0.5%, 100% 100%, 0 100%);
    }
}

footer.light .column-2 p {
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #666;
    opacity: 0.9;
}

footer.light .column-3 a,
footer.light .column-4 a {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #083863;
}

footer.light .column-5 .address-item .content-container {
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #666;
}

footer.light .column-2 .copyright-text {
    margin-top: 3.2rem;
    font-size: 14px;
    line-height: 18px;
    color: #999;
    opacity: 0.9;
}

@media (max-width: 768px) {
    footer.light .column-2 .copyright-text {
        display: none;
    }
}

footer .column-1 {
    margin-bottom: 3rem;
}

footer .column-2 .logo-container {
    margin-bottom: 3rem;
}

footer .column-2 p {
    font-size: 15px;
    opacity: 0.9;
    color: #fff;
}

footer .column-2 .other-logos-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin-top: 3.4rem;
}

footer .column-2 .other-logos-container .logo-1 {
    margin-right: 1.5rem;
}

footer .column-2 .other-logos-container .logo-1 .vistra {
    width: 120px;
}

footer .column-3 {
    padding-left: 7rem;
    margin-top: 4.6rem;
}

@media (max-width: 768px) {
    footer .column-3 {
        padding-left: 15px;
        margin-top: 5.2rem;
    }
}

footer .column-3 a {
    color: #fff;
    display: block;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2.9rem;
}

footer .column-3 a:hover {
    text-decoration: none;
}

footer .column-3 .vistra {
    width: 120px;
}

footer .column-4 {
    margin-top: 4.6rem;
}

@media (max-width: 768px) {
    footer .column-4 {
        margin-top: 5.2rem;
    }
}

footer .column-4 a {
    color: #fff;
    display: block;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2.9rem;
}

footer .column-4 a:hover {
    text-decoration: none;
}

footer .column-5 {
    margin-top: 0;
}

@media (max-width: 768px) {
    footer .column-5 {
        margin-top: 3rem;
    }
}

footer .column-5 .address-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

footer .column-5 .address-item:not(:first-child):not(:last-child) {
    margin-top: 2rem;
}

footer .column-5 .address-item:last-child {
    margin-top: 1.4rem;
}

footer .column-5 .address-item .image-container {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    min-width: 24px;
    margin-right: 2.2rem;
}

footer .column-5 .address-item .content-container {
    font-weight: 600;
    font-size: 15px;
    color: #fff;
}

footer .column-5 .address-item .content-container.hide {
    display: none;
}

footer .column-5 .social-media-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3.6rem;
    margin-left: 3rem;
}

@media (max-width: 768px) {
    footer .column-5 .social-media-links {
        margin-right: 0;
    }
}

footer .column-5 .social-media-links a {
    margin-left: 0.2rem;
}

footer .column-5 .social-media-links a .icon-container {
    border-radius: 50%;
    height: 42px;
    width: 42px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

footer .column-5 .social-media-links a .icon-container:hover {
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 187, 180, 0.1)), to(rgba(0, 187, 180, 0.1))); */
    background: linear-gradient(rgba(0, 187, 180, 0.1), rgba(0, 187, 180, 0.1));
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

footer .column-6 {
    margin-top: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    footer .column-6 {
        margin-top: 2.4rem;
    }
}

@media (max-width: 768px) {
    footer .column-6 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

footer .column-6 .copyright-text {
    display: none;
    font-size: 14px;
    line-height: 18px;
    color: #999;
    opacity: 0.9;
}

@media (max-width: 768px) {
    footer .column-6 .copyright-text {
        margin-top: 3.4rem;
        display: block;
    }
}

.footer-img {
    padding: 7.2rem;
    text-align: center;
}

.navbar {
    padding-top: 2.4rem !important;
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
    height: auto;
    font-size: 14px;
}

.navbar.navbar-light .active {
    color: #00bbb4;
}

.navbar.navbar-light .active::after {
    border: 1.5px solid #00bbb4 !important;
    background: #00bbb4 !important;
}

@media (max-width: 768px) {
    .navbar {
        padding-top: 2.8rem !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.navbar .nav-toggle-logo-container.lift-menu-button .navbar-toggle-left {
    position: relative;
    top: -1rem;
}

.navbar .nav-toggle-logo-container .navbar-toggle-left {
    margin-right: 3rem;
}

.navbar .nav-toggle-logo-container .navbar-toggle-left img {
    -webkit-transform: scale(-1);
    transform: scale(-1);
}

.navbar .nav-toggle-logo-container .logo {
    width: 146px !important;
}

.navbar .login-button-mobile {
    display: none;
    font-size: 14px;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
}

@media (max-width: 768px) {
    .navbar .login-button-mobile {
        border: none !important;
        display: block;
        background: #00bbb4;
    }
}

.navbar .logo {
    width: 164px;
}

@media (max-width: 768px) {
    .navbar .logo {
        width: 120px;
    }
}

@media (max-width: 650px) {
    .navbar .logo {
        width: 30%;
    }
}

@media (max-width: 550px) {
    .navbar .logo {
        width: 35%;
    }
}

@media (max-width: 480px) {
    .navbar {
        padding: 2.2rem 0 !important;
    }
    .navbar .logo {
        width: 40%;
    }
}

@media (max-width: 550px) {
    .navbar .logo {
        width: 45%;
    }
}

.navbar li {
    position: relative;
}

.navbar .active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.navbar .active::after {
    content: "";
    width: 42px;
    border: 1.5px solid #083863;
    background: #083863;
    margin: 0 auto;
    border-radius: 24px;
}

.navbar .sign-up-button button {
    color: #fff;
    background: #00bbb4;
    border-radius: 4px;
    border: 1px solid #00bbb4;
    padding: 1rem 2.4rem;
    font-size: 16px;
    -webkit-box-shadow: 0 4px 10px rgba(0, 187, 180, 0.25);
    box-shadow: 0 4px 10px rgba(0, 187, 180, 0.25);
}

.navbar .sign-up-button button:hover {
    -webkit-box-shadow: 0 4px 10px rgba(0, 187, 180, 0.5);
    box-shadow: 0 4px 10px rgba(0, 187, 180, 0.5);
}

.navbar .services-dropdown {
    display: none;
    left: 3rem;
    top: 4rem;
    position: absolute;
    background: #fff;
    padding: 1.4rem 2rem;
    border-radius: 5px;
    width: 17rem;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px 0;
}

.navbar .services-dropdown a {
    text-align: left;
    display: block;
    margin-bottom: 1rem;
    color: #222;
}

.navbar .services-dropdown a:hover {
    color: #222;
    text-decoration: none;
}

.navbar .services-dropdown a:last-child {
    margin-bottom: 0;
}

.navbar .navbar-toggle {
    font-size: 16px;
    color: #00bbb4;
    font-weight: 600;
}

.navbar .mobile-nav-container {
    display: none;
    padding: 0 9% !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    left: 100%;
}

@media (max-width: 768px) {
    .navbar .mobile-nav-container {
        padding: 0 8% !important;
        height: auto;
    }
}

@media (max-width: 650px) {
    .navbar .mobile-nav-container .logo {
        width: 30%;
    }
}

@media (max-width: 550px) {
    .navbar .mobile-nav-container .logo {
        width: 35%;
    }
}

@media (max-width: 480px) {
    .navbar .mobile-nav-container {
        padding: 0 7% !important;
    }
    .navbar .mobile-nav-container .logo {
        width: 40%;
    }
}

@media (max-width: 550px) {
    .navbar .mobile-nav-container .logo {
        width: 45%;
    }
}

@media (max-width: 990px) {
    .navbar .mobile-nav-container {
        display: block;
        position: fixed;
        height: 100%;
        width: 100%;
        background: #fff;
        top: 0;
        z-index: 5;
    }
}

.navbar .mobile-nav-container .logo-button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 2.2rem 0;
}

.navbar .mobile-nav-container .mobile-logo-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 0;
}

.navbar .mobile-nav-container .main-nav-mobile {
    margin-top: 0;
}

.navbar .mobile-nav-container .main-nav-mobile li {
    padding: 1rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: left;
}

.navbar .mobile-nav-container .main-nav-mobile li a {
    color: #083863;
    font-weight: 500;
    font-size: 18px;
}

.navbar .mobile-nav-container .main-nav-mobile li a.sign-up-button button {
    min-width: 186px;
}

.navbar .mobile-nav-container .main-nav-mobile li a:hover {
    text-decoration: none;
}

.navbar .mobile-nav-container .main-nav-mobile li a:focus {
    text-decoration: none;
}

.navbar .mobile-nav-container .main-nav-mobile li a button {
    font-size: 1.8rem;
    font-weight: 600;
}

.navbar .mobile-nav-container .main-nav-mobile li a img {
    margin-left: 0.8rem;
    width: 10px;
    height: 6.17px;
}

.navbar .mobile-nav-container .main-nav-mobile li #partnersSubmenu {
    margin-top: 1rem;
    padding-left: 1rem;
}

.navbar .mobile-nav-container .main-nav-mobile li #partnersSubmenu li a {
    font-size: 14px;
}

.navbar-scrolled {
    position: fixed !important;
    top: 0;
    left: 0;
    background: #fff;
    padding: 0.8rem 10.7% !important;
    height: 60px;
    width: 100%;
    z-index: 500;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px 0;
}

.main-nav {
    list-style-type: none;
    display: none;
}

.nav-links img {
    margin-left: 0.2rem;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
}

.nav-links .new-link-highlight {
    padding: 1px 4px;
    border-radius: 6.5px;
    margin-top: -15px;
    display: block;
    background-color: #f5edea;
    color: #ff7044;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
}

.logo,
.nav-links {
    text-decoration: none;
    color: #083863;
    font-weight: 600;
    font-size: 14px;
    outline: 0;
}

.logo:hover,
.nav-links:hover {
    text-decoration: none;
    color: #00bbb4;
}

.curr-page {
    color: #00bbb4;
}

.option-tabs li {
    cursor: pointer;
}

.p-gst {
    font-size: 1rem;
    color: 000000;
    opacity: 0.5;
}

.curr-page::after {
    content: "";
    position: absolute;
    left: 40%;
    bottom: 4px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 2px;
    width: 4rem;
    border-bottom: 2px solid #00bbb4;
    -webkit-transition: bottom 0.2s ease-out;
    transition: bottom 0.2s ease-out;
}

.curr-page:hover {
    color: #00bbb4;
}

.curr-page:hover::after {
    bottom: 2px;
}

.main-nav li {
    text-align: center;
    margin: 15px auto;
}

.logo {
    display: inline-block;
    width: 25%;
}

.logo img {
    width: 100%;
}

.navbar-toggle {
    font-size: 16px;
    color: #00bbb4;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (min-width: 991px) {
    .navbar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 2.6rem 10.7%;
        position: relative;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        top: 0;
        z-index: 99;
        width: 100%;
    }
    .main-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-bottom: 0;
    }
    .main-nav li {
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .nav-links {
        margin-left: 40px;
        line-height: 24px;
        display: block;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
    }
}

@media screen and (min-width: 991px) and (max-width: 1167px) {
    .nav-links {
        margin-left: 20px;
    }
}

@media screen and (min-width: 991px) {
    .logo {
        margin-top: 0;
        width: 14%;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }
    .logo img {
        width: 100%;
    }
    .navbar-toggle {
        display: none;
    }
}

.hero-section {
    position: relative;
    padding: 0 10.7%;
    height: calc(100vh - 56px - 2rem);
}

@media (max-width: 768px) {
    .hero-section {
        padding: 0 1.6rem;
    }
}

@media (max-width: 991px) {
    .hero-section {
        height: auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.hero-section .owl-carousel-hero-section {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    top: -4rem;
}

@media (max-width: 991px) {
    .hero-section .owl-carousel-hero-section {
        top: 0;
    }
}

.hero-section .owl-carousel-hero-section .owl-dots {
    position: absolute;
    bottom: 3.3rem;
    left: 2.5rem;
}

@media (max-width: 991px) {
    .hero-section .owl-carousel-hero-section .owl-dots {
        bottom: 8.3rem;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

.hero-section .owl-carousel-hero-section .owl-dots .owl-dot {
    outline: 0;
}

.hero-section .owl-carousel-hero-section .owl-nav {
    min-width: 12rem;
    position: absolute;
    bottom: 3.5rem;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hero-section .owl-carousel-hero-section .owl-nav button:hover {
    background: #fff;
}

@media (max-width: 991px) {
    .hero-section .owl-carousel-hero-section .owl-nav {
        bottom: 8.5rem;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

.hero-section .owl-carousel-hero-section .owl-nav .owl-next {
    -webkit-transform: scale(-1);
    transform: scale(-1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    outline: 0;
}

.hero-section .owl-carousel-hero-section .owl-nav .owl-prev {
    -webkit-transform: scale(-1);
    transform: scale(-1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    outline: 0;
}

.hero-section .owl-carousel-hero-section .owl-nav .carousel-control-left {
    -webkit-transform: scale(-1);
    transform: scale(-1);
}

.hero-section .owl-carousel-hero-section .owl-nav .carousel-control-right {
    -webkit-transform: scale(-1);
    transform: scale(-1);
}

@media (max-width: 991px) {
    .hero-section .owl-carousel-hero-section .item .row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.hero-section .column-1 {
    padding-right: 4rem;
}

@media (max-width: 768px) {
    .hero-section .column-1 {
        padding-left: 0;
    }
}

@media (max-width: 991px) {
    .hero-section .column-1 {
        margin-bottom: 14rem;
        margin-top: 3.6rem;
        padding-right: 0;
    }
}

.hero-section .column-1 .content-container {
    margin-top: 3rem;
}

.hero-section .column-1 .content-container h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.5;
    color: #083863;
    opacity: 0;
}

@media (max-width: 1088px) {
    .hero-section .column-1 .content-container h1 {
        font-size: 32px;
        line-height: 1.3;
    }
}

@media (max-width: 480px) {
    .hero-section .column-1 .content-container h1 {
        font-size: 30px;
    }
}

.hero-section .column-1 .content-container p {
    margin-top: 3rem;
    color: #083863;
    opacity: 0.9;
    font-size: 18px;
    font-weight: 300;
    opacity: 0;
    line-height: 1.5;
}

.hero-section .column-1 .content-container .primary-button {
    opacity: 0;
    margin-top: 4.2rem;
}

.hero-section .column-1 .content-container .button-video-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 4.2rem;
    opacity: 0;
}

.hero-section .column-1 .content-container .button-video-container .primary-button {
    margin-top: 0;
    opacity: 1;
}

.hero-section .column-1 .content-container .button-video-container .video-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 3rem;
}

.hero-section .column-1 .content-container .button-video-container .video-button img {
    width: 30px;
    height: 30px;
}

.hero-section .column-1 .content-container .button-video-container .video-button span {
    margin-left: 0.8rem;
    font-size: 16px;
    font-weight: 500;
    color: #00bbb4;
}

.hero-section .column-1 .content-container .button-video-container .video-button a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hero-section .column-1 .content-container .button-video-container .video-button a:hover {
    text-decoration: none;
}

.hero-section .column-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 991px) {
    .hero-section .column-2 {
        margin-top: 4.2rem;
    }
}

@media (max-width: 768px) {
    .hero-section .column-2 .image-container {
        width: 75%;
    }
}

@media (max-width: 480px) {
    .hero-section .column-2 .image-container {
        width: 65%;
    }
}

.hero-section .carousel-control {
    height: 12px;
    width: 16px;
    cursor: pointer;
}

.hero-section .arrow-down {
    position: absolute;
    bottom: 6rem;
    z-index: 10;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    outline: none;
}

@media (max-width: 991px) {
    .hero-section .arrow-down {
        bottom: 6rem;
    }
}

@media (max-width: 480px) {
    .hero-section .arrow-down {
        bottom: 4rem;
    }
}

.hero-section .arrow-down:hover {
    -webkit-transform: translate(-50%, -5px);
    transform: translate(-50%, -5px);
}

.primary-button {
    background: #00bbb4;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 1.4rem 2.4rem 1.4rem 3rem;
    -webkit-box-shadow: 0 10px 30px -12px rgba(0, 187, 180, 0.6);
    box-shadow: 0 10px 30px -12px rgba(0, 187, 180, 0.6);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.primary-button img {
    margin-left: 1.8rem;
    display: inline-block !important;
    width: auto !important;
}

.primary-button:hover {
    -webkit-box-shadow: 0 10px 30px -12px #00bbb4;
    box-shadow: 0 10px 30px -12px #00bbb4;
}

.primary-button:focus {
    border: 1px solid transparent;
    outline: 0;
}

.grid-section {
    position: relative;
    padding: 10rem 12% 1rem 16.91%;
    background: linear-gradient(240deg, #00bbb4, #00bbb4);
}

/* .homepage {
    background: url(../images/pages/jewellery-exchange/decor-vertical-1.svg) no-repeat;
} */

.login-form label,
.signup-form label {
    color: #00bbb4 !important;
    font-size: 14px;
    font-weight: 400;
}

.radio_lb {
    color: #333 !important;
    font-family: Sarabun;
    font-weight: 400;
}

.radio_lb.gold-radio {
    color: #fff !important;
    font-family: Sarabun;
    font-weight: 400;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]+label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: 0;
    vertical-align: middle;
    border: 2px solid #00bbb4;
    border-radius: 50%;
    padding: 2px;
    margin-right: 5px;
    margin-top: -5%;
}

input[type="radio"]:checked+label span {
    width: 18px;
    height: 18px;
    background: #00bbb4;
    border: 2px solid #00bbb4;
    background-clip: content-box;
}

.gold-radio>input[type="radio"]+label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: 0;
    vertical-align: middle;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 2px;
    margin: 0 5px;
}

.gold-radio>input[type="radio"]:checked+label span {
    width: 18px;
    height: 18px;
    background: #fff;
    border: 2px solid #fff;
    background-clip: content-box;
}

.RegistrationSelect select {
    /* background: url(/assets/sg-ui-v2/images/ico/select-arrow.svg) no-repeat right #f7f7f7 !important; */
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-position: right 10px center !important;
}

.customerName {
    display: flex;
}

.customerName input[type="text"] {
    flex: 1 42%;
    margin-bottom: 7px;
}

.customerName input[type="text"]:first-child {
    margin-right: 1%;
}

.firstpostImg {
    width: 75%;
}

.firstpost-li {
    width: 40%;
}

.m-t {
    margin-top: 2.6rem;
}

input[type="text"]:focus {
    outline: none!important;
}

.form-control.b-input {
    background: transparent;
    border-bottom: 1.5px solid #707070;
    border-radius: 0px;
    font-size: 23px;
    line-height: 0px;
}

/* .form-control.b-input:focus {
    border: 0px;
    border-bottom: 1.5px solid #707070;
} */

.form-control {
    display: block;
    width: 100%;
    height: 48px;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.42857143;
    color: #666;
    background-color: #f7f7f7;
    /* background-image: url(../images/ico/keyboard-left-arrow-button.svg); */
    border: none;
    font-family: Sarabun;
    border-radius: 4px;
    -webkit-box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.f-4rem {
    font-size: 4rem;
}

.btn.btn-primary1.f-w-btn {
    min-width: 100%;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group label {
    height: 3.7rem;
    padding-left: 0.75rem;
}

.form-label-group label {
    position: absolute;
    top: 0px;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:-moz-placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
}

.form-label-group input:not(:-ms-input-placeholder) {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
}

/* .form-label-group input:not(:placeholder-shown) {} */

.form-label-group input:not(:-moz-placeholder-shown)~label {
    font-size: 14px;
    color: #777;
    margin-top: -5%;
}

.form-label-group input:not(:-ms-input-placeholder)~label {
    font-size: 14px;
    color: #777;
    margin-top: -5%;
}

.form-label-group input:not(:placeholder-shown)~label {
    font-size: 14px;
    color: #777;
    margin-top: -5%;
}

.form-label-group input:not(:placeholder-shown) {
    border: 0px;
    border-bottom: 2px solid #00bbb4;
    outline: 0px;
}

.form-label-group input:-webkit-autofill~label {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 12px;
    color: #777;
}

@media screen and (max-width: 991px) {
    .radio_lb.gold-radio {
        color: #fff !important;
    }
    .gold-radio>input[type="radio"]+label span,
    .gold-radio>input[type="radio"]:checked+label span {
        border-color: #fff;
    }
    .gold-radio>input[type="radio"]:checked+label span {
        background-color: #fff;
    }
}

@supports (-ms-ime-align: auto) {
    .form-label-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    .form-label-group label {
        position: static;
    }
    .form-label-group input::-ms-input-placeholder {
        color: #777;
    }
}

label.f-label {
    color: #b2b1b1 !important;
    font-size: 23px;
}

.resend-link {
    color: #00bbb4 !important;
    font-size: 16px;
}

.resend-link,
#para,
.timer-box {
    font-size: 16px !important;
}

@media (max-width: 1100px) {
    .grid-section {
        padding: 10rem 4% 1rem 4%;
    }
}

@media (max-width: 767px) {
    .grid-section {
        padding: 4.2rem 12% 1rem 12%;
    }
}

@media (max-width: 480px) {
    .grid-section {
        padding: 4.2rem 7% 1rem 7%;
    }
}

.grid-section__heading {
    margin-bottom: 8.4rem;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media (max-width: 480px) {
    .grid-section__heading {
        margin-bottom: 4.6rem;
    }
}

@media (max-width: 480px) {
    .grid-section__heading {
        font-size: 24px;
    }
}

@media (max-width: 405px) {
    .grid-section__heading {
        font-size: 20px;
    }
}

.grid-section__heading .grid-section__divider {
    margin-top: 1.8rem;
    width: 6rem;
    height: 0.4rem;
    border: 2px solid #decc62;
}

.grid-section__item {
    margin-bottom: 9rem;
}

@media (max-width: 480px) {
    .grid-section__item {
        margin-bottom: 4rem;
    }
}

.grid-section__item h3 {
    margin-top: 3rem;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    line-height: 1.5;
}

.grid-section__item p {
    margin-top: 1.7rem;
    font-size: 15px;
    opacity: 0.8;
    color: #fff;
}

.grid-section .square-gold {
    position: absolute;
    top: 27.2rem;
    left: 5.4rem;
    -webkit-animation: rotation 7s infinite linear;
    animation: rotation 7s infinite linear;
}

@media (max-width: 480px) {
    .grid-section .square-gold {
        top: 58.25rem;
        left: 2.8rem;
        display: none;
    }
}

.grid-section .square-white {
    position: absolute;
    bottom: 24.6rem;
    right: 12.2rem;
    -webkit-animation: rotation 7s infinite linear;
    animation: rotation 7s infinite linear;
}

@media (max-width: 480px) {
    .grid-section .square-white {
        bottom: 20.6rem;
        right: 6rem;
        display: none;
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.tabs-section {
    position: relative;
    overflow: hidden;
}

.tabs-section__heading {
    padding: 0 7%;
    margin-top: 12rem;
    font-size: 30px;
    color: #333;
    line-height: 1.5;
    font-weight: 700;
    width: 100%;
    text-align: center;
}

@media (max-width: 480px) {
    .tabs-section__heading {
        margin-top: 6rem;
    }
}

@media (max-width: 768px) {
    .tabs-section__heading {
        font-size: 26px;
    }
}

@media (max-width: 480px) {
    .tabs-section__heading {
        font-size: 22px;
    }
}

.tabs-section__heading p {
    font-size: 15px;
    color: #666;
    margin-top: 1.6rem;
    font-weight: 400;
}

.tabs-section__tabs {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    margin-top: 6rem;
    margin-bottom: 3.6rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .tabs-section__tabs {
        margin: 3.6rem 0 0;
    }
}

.tabs-section__tabs .tab-item {
    cursor: pointer;
}

.tabs-section__tabs .tab-item a {
    font-size: 18px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.5);
    padding-bottom: 2.4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-bottom: 1px solid #ddd;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 480px) {
    .tabs-section__tabs .tab-item a {
        font-size: 15px;
    }
}

@media (max-width: 336px) {
    .tabs-section__tabs .tab-item a {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .tabs-section__tabs .tab-item a {
        padding-bottom: 1.65rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media (max-width: 336px) {
    .tabs-section__tabs .tab-item a {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
}

.tabs-section__tabs .active {
    padding-bottom: 2.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #333 !important;
    position: relative;
}

.tabs-section__tabs .active::after {
    position: absolute;
    left: 0;
    bottom: -2px;
    content: "";
    width: 100%;
    border: 1.5px solid #00bbb4;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.tabs-section .tab-content {
    margin-top: 7.2rem;
}

button#email__otp__1 {
    color: #00bbb4;
    margin-left: 6%;
    outline: none;
    background: transparent;
    border: none;
    font-size: 18px;
    font-weight: 500;
}

button#email__otp__1:disabled {
    color: #b2b1b1 !important;
    cursor: not-allowed !important;
}

.tabs-section .tab-content .row {
    padding: 0 16.91%;
}

@media (max-width: 768px) {
    .tabs-section .tab-content .row {
        padding: 0 12%;
    }
}

@media (max-width: 480px) {
    .tabs-section .tab-content .row {
        padding: 0 7%;
    }
}

.tabs-section .tab-content .row .tab-content__item {
    text-align: center;
}

@media (max-width: 768px) {
    .tabs-section .tab-content .row .tab-content__item {
        margin-bottom: 5.4rem;
    }
}

.tabs-section .tab-content .row .tab-content__item .image-container {
    position: relative;
    min-height: 80px;
}

.tabs-section .tab-content .row .tab-content__item .image-container .index {
    position: absolute;
    top: -2rem;
    left: 2rem;
    font-size: 42px;
    font-weight: 100;
    color: #333;
    opacity: 0.5;
    line-height: 42px;
}

@media (max-width: 480px) {
    .tabs-section .tab-content .row .tab-content__item .image-container .index {
        left: 12rem;
    }
}

.tabs-section .tab-content .row .tab-content__item h3 {
    margin-top: 4.2rem;
    color: #333;
    font-size: 18px;
    font-weight: 700;
}

.tabs-section .tab-content .row .tab-content__item p {
    font-size: 15px;
    margin-top: 1.8rem;
    color: #666;
}

.tabs-section .tab-content .row .tab-content__item .green-link {
    margin-top: 2.4rem;
}

.tabs-section .tab-content .row .tab-content__item .green-link:hover {
    text-decoration: none;
}

.tabs-section .tab-content .row .green-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5.2rem;
}

@media (max-width: 768px) {
    .tabs-section .tab-content .row .green-link {
        margin-top: 0.4rem;
    }
}

.tabs-section .tab-content .row .green-link:hover {
    text-decoration: none;
}

.tabs-section .tab-content .active .tab-content__item {
    -webkit-animation-name: appear;
    animation-name: appear;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

.tabs-section .tab-content .active .tab-content__item .green-link {
    -webkit-animation-name: appear;
    animation-name: appear;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

@-webkit-keyframes appear {
    from {
        opacity: 0;
        -webkit-transform: translate(0, 100px);
        transform: translate(0, 100px);
    }
    to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@keyframes appear {
    from {
        opacity: 0;
        -webkit-transform: translate(0, 100px);
        transform: translate(0, 100px);
    }
    to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.tabs-section .star-gold {
    position: absolute;
    top: 16rem;
    left: 14rem;
    -webkit-animation-name: floating;
    animation-name: floating;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@media (max-width: 480px) {
    .tabs-section .star-gold {
        top: 10rem;
        left: 1.4rem;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
        display: none;
    }
}

.tabs-section .star-turquoise {
    position: absolute;
    top: 40.9rem;
    right: 6.8rem;
    -webkit-animation-name: floating;
    animation-name: floating;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@media (max-width: 480px) {
    .tabs-section .star-turquoise {
        top: 34.9rem;
        right: 0.02rem;
        display: none;
    }
}

.tabs-section .bg-top-right {
    position: absolute;
    top: -15rem;
    right: -15rem;
}

.tabs-bg-1 .star-gold {
    position: absolute;
    top: 25rem;
    right: 9.4rem;
    left: auto;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-animation-name: floating;
    animation-name: floating;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@media (max-width: 480px) {
    .tabs-bg-1 .star-gold {
        display: none;
    }
}

.tabs-bg-1 .star-turquoise {
    position: absolute;
    top: 45.9rem;
    left: 9.5rem;
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    -webkit-animation-name: floating;
    animation-name: floating;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@media (max-width: 480px) {
    .tabs-bg-1 .star-turquoise {
        display: none;
    }
}

@-webkit-keyframes floating {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    65% {
        -webkit-transform: translate(0, -20px);
        transform: translate(0, -20px);
    }
    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@keyframes floating {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    65% {
        -webkit-transform: translate(0, -20px);
        transform: translate(0, -20px);
    }
    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.green-link {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #00bbb4 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.green-link img {
    margin-left: 0.8rem;
    width: 12px;
    height: 12px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.green-link:hover {
    text-decoration: none;
}

.green-link:hover img {
    margin-left: 1rem;
}

.gold-link {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #bf9f41 !important;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.gold-link img {
    margin-left: 0.8rem;
}

.gold-link:hover {
    text-decoration: none;
}

.gold-link:hover img {
    margin-left: 1rem;
}

.white-link {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #fff !important;
    cursor: pointer;
}

.white-link img {
    margin-left: 0.8rem;
}

.white-link:hover {
    text-decoration: none;
}

.partners-section {
    /* background: url(../images/global/background-1.svg); */
    background-repeat: no-repeat;
    background-position: center left -74px;
    padding: 15rem 7.2% 15rem 10.7%;
    position: relative;
}

@media (max-width: 768px) {
    .partners-section {
        padding: 7.2rem 10.7%;
    }
}

@media (max-width: 480px) {
    .partners-section {
        padding: 7.2rem 7% 3rem 7%;
    }
}

.partners-section .bg-clipped {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 88%);
}

@media (max-width: 768px) {
    .partners-section .column-1 {
        text-align: center;
    }
}

.partners-section .column-1 h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.5;
    color: #333;
}

.partners-section .column-1 p {
    color: #333;
    font-weight: 300;
    font-size: 30px;
    line-height: 1.5;
}

.partners-section .column-2 .carousel {
    padding: 0 9rem 0 7.4rem;
}

.partners-section .column-2 .carousel-item .row .col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2rem;
}

.partners-section .column-3 {
    margin-top: 3.6rem;
}

.partners-section .column-3 .col .image-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 4.2rem;
}

.partners-section .column-3 .show-more {
    margin-top: 1rem;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #00bbb4;
}


/* partner-page  */
.partners-index .partners-section .partner-logos-container .col, .partners-section .partners-section-mobile .logos-container .image-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 70px;
    margin: 0 0px 25px 25px;
}

.partners-section .partners-section-desktop .partner-logos-container .tanishq, .partners-schedule-a-demo .column-2 .partner-logos-container .tanishq {
    height: 42px;
    width: 63px;
    background: url('../images/sprite-image.png') no-repeat -355px -194px;
}
.partners-section .partners-section-desktop .partner-logos-container .phonepe, .partners-schedule-a-demo .column-2 .partner-logos-container .phonepe {
    height: 34px;
    width: 113px;
    background: url('../images/sprite-image.png') no-repeat -179px -5px;
}

.partners-section .partners-section-desktop .partner-logos-container .amazon-pay, .partners-schedule-a-demo .column-2 .partner-logos-container .amazon-pay {
    height: 30px;
    width: 124px;
    background: url('../images/sprite-image.png') no-repeat -327px -7px;
}
.partners-section .partners-section-desktop .partner-logos-container .mobikwik, .partners-schedule-a-demo .column-2 .partner-logos-container .mobikwik {
    height: 41px;
    width: 112px;
    background: url('../images/sprite-image.png') no-repeat -158px -266px;
}
.partners-section .partners-section-desktop .partner-logos-container .freecharge, .partners-schedule-a-demo .column-2 .partner-logos-container .freecharge {
    height: 27px;
    width: 130px;
    background: url('../images/sprite-image.png') no-repeat 0px -275px;
}
.partners-section .partners-section-desktop .partner-logos-container .five-paisa, .partners-schedule-a-demo .column-2 .partner-logos-container .five-paisa {
    height: 44px;
    width: 47px;
    background: url('../images/sprite-image.png') no-repeat -367px -133px;
}
.partners-section .partners-section-desktop .partner-logos-container .bajaj-markets, .partners-schedule-a-demo .column-2 .partner-logos-container .bajaj-markets {
    height: 30px;
    width: 110px;
    background: url('../images/sprite-image.png') no-repeat -644px -70px;
}
.partners-section .partners-section-desktop .partner-logos-container .caratlane, .partners-schedule-a-demo .column-2 .partner-logos-container .caratlane {
    height: 33px;
    width: 125px;
    background: url('../images/sprite-image.png') no-repeat -321px -69px;
}
.partners-section .partners-section-desktop .partner-logos-container .candere, .partners-schedule-a-demo .column-2 .partner-logos-container .candere {
    height: 33px;
    width: 117px;
    background: url('../images/sprite-image.png') no-repeat -485px -67px;
}
.partners-section .partners-section-desktop .partner-logos-container .axis-bank, .partners-schedule-a-demo .column-2 .partner-logos-container .axis-bank {
    height: 32px;
    width: 122px;
    background: url('../images/sprite-image.png') no-repeat -642px -135px;
}
.partners-section .partners-section-desktop .partner-logos-container .airtel-payments, .partners-schedule-a-demo .column-2 .partner-logos-container .airtel-payments {
    height: 18px;
    width: 122px;
    background: url('../images/sprite-image.png') no-repeat -806px -139px;
}
.partners-section .partners-section-desktop .partner-logos-container .dbs-bank, .partners-schedule-a-demo .column-2 .partner-logos-container .dbs-bank, .partners-section .partners-section-mobile .logos-container .image-container .dbs-bank {
    height: 35px;
    width: 145px;
    background: url('../images/sprite-image.png') no-repeat -616px -6px;
}
.partners-section .partners-section-desktop .partner-logos-container .iifl, .partners-schedule-a-demo .column-2 .partner-logos-container .iifl {
    height: 35px;
    width: 145px;
    background: url('../images/sprite-image.png') no-repeat -468px -201px;
}
.partners-section .partners-section-desktop .partner-logos-container .flipkart, .partners-schedule-a-demo .column-2 .partner-logos-container .flipkart, .partners-section .partners-section-mobile .logos-container .image-container .flipkart {
    height: 35px;
    width: 145px;
    background: url('../images/sprite-image.png') no-repeat -465px -6px;
}
.partners-section .partners-section-desktop .partner-logos-container .jar-logo, .partners-schedule-a-demo .column-2 .partner-logos-container .jar-logo, .partners-section .partners-section-mobile .logos-container .image-container .jar-logo {
    height: 35px;
    width: 145px;
    background: url('../images/sprite-image.png') no-repeat -770px -200px;
}

.partners-index .counter-section .bg-white-mandala {
    position: absolute;
    left: -14px;
    top: -215px;
}
.partners-index .partners-section .column-1 h2, .partners-index .counter-section h2 {
    color: #083863;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 20px;
}
.partners-index .counter-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0px 7.2% 65px 10.7%;
    margin-bottom: 72px;
    position: relative;
} 






@media (max-width: 768px) {
    .partners-section .partners-section-desktop {
        display: none;
    }
}

.partners-section .partners-section-desktop .partner-logos-container div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.partners-section .partners-section-mobile {
    display: none;
    margin-left: 0;
    margin-right: 0;
}

@media (max-width: 768px) {
    .partners-section .partners-section-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.partners-section .partners-section-mobile .logos-container .image-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 3.4rem;
}

.contact-section-bg-flipped {
    background-position: top left -150px !important;
}

.contact-section {
    position: relative;
    /* background: url(../images/global/background-1.svg); */
    background-position: top right -150px;
    background-repeat: no-repeat;
    padding-top: 12rem;
    padding-left: 16.91%;
    padding-right: 16.91%;
    margin-bottom: 11.7rem;
}

@media (max-width: 480px) {
    .contact-section {
        margin-top: 5.4rem;
    }
}

@media (max-width: 768px) {
    .contact-section {
        padding-left: 12%;
        padding-right: 12%;
    }
}

@media (max-width: 480px) {
    .contact-section {
        padding-left: 7%;
        padding-right: 7%;
    }
}

@media (max-width: 480px) {
    .contact-section {
        margin-bottom: 6.5rem;
    }
}

.contact-section__title {
    margin-left: -1.4rem;
}

.contact-section__title h2 {
    font-size: 36px;
    font-weight: 700;
    color: #333;
}

@media (max-width: 480px) {
    .contact-section__title h2 {
        font-size: 30px;
    }
}

.contact-section .contact-form {
    margin-top: 5.7rem;
}

@media (max-width: 480px) {
    .contact-section .contact-form {
        margin-top: 3.6rem;
    }
}

.contact-section .contact-form .column-1 {
    padding-left: 0;
    padding-right: 18%;
}

.contact-section .contact-form .column-1 .contact-detail {
    margin-bottom: 3rem;
}

.contact-section .contact-form .column-1 .contact-detail .image-container {
    width: 26px !important;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media (max-width: 480px) {
    .contact-section .contact-form .column-1 .contact-detail .image-container {
        width: 22px !important;
    }
}

.contact-section .contact-form .column-1 .contact-detail .contact-detail-text {
    margin-left: 2rem;
    color: #666;
    opacity: 0.9;
    font-size: 15px;
}

.contact-section .contact-form .column-1 .contact-detail .contact-detail-text a {
    color: #666;
}

.contact-section .contact-form .column-1 .contact-detail .contact-detail-text a:hover {
    text-decoration: none;
    color: #666;
}

.contact-section .contact-form .column-1 .contact-detail .contact-detail-text .heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0.6rem;
    color: #decc62;
}

.contact-section .contact-form .column-1 .contact-detail .contact-detail-text .content {
    font-size: 15px;
    color: #666;
    margin-bottom: 2.2rem;
    opacity: 0.9;
}

@media (max-width: 480px) {
    .contact-section .contact-form .column-2 {
        padding-left: 0;
        padding-right: 0;
    }
}

.contact-section .contact-form .column-2 h2 {
    color: #666;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 2.4rem;
}

.contact-section .contact-form .column-2 ::-webkit-input-placeholder {
    color: #666;
    opacity: 0.7;
    font-size: 33px;
}

.contact-section .contact-form .column-2 .form-field {
    margin-bottom: 1.6rem;
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    color: #666;
    opacity: 0.7;
    font-size: 15px;
    padding: 1.4rem 1.8rem;
    border-radius: 5px;
}

.contact-section .contact-form .column-2 .form-min-height {
    min-height: 4.8rem;
}

.contact-section .contact-form .column-2 button {
    margin-top: 1rem;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    padding: 1.2rem 4.1rem;
    background: #00bbb4;
    -webkit-box-shadow: 0 10px 30px -12px rgba(0, 187, 180, 0.6);
    box-shadow: 0 10px 30px -12px rgba(0, 187, 180, 0.6);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.contact-section .contact-form .column-2 button:hover {
    -webkit-box-shadow: 0 10px 30px -12px #00bbb4;
    box-shadow: 0 10px 30px -12px #00bbb4;
}

.section-heading {
    width: 100%;
    padding: 0 10.7%;
    text-align: center;
}

@media (max-width: 480px) {
    .section-heading {
        padding: 0 7%;
    }
}

.section-heading h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    color: #333;
}

@media (max-width: 480px) {
    .section-heading h2 {
        font-size: 24px;
    }
}

.section-heading p {
    margin-top: 1.3rem;
    font-size: 18px;
    font-weight: 300;
    color: #666;
}

.image-content-section {
    width: 100%;
    overflow: hidden;
    padding-top: 15rem;
    padding-bottom: 10rem;
}

.image-content-section .image-content-rows-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 8rem;
}

.image-content-section .image-content-rows-container .image-content-row {
    padding: 4.8rem 0;
}

@media (max-width: 768px) {
    .image-content-section .image-content-rows-container .image-content-row {
        padding: 3.8rem 0;
    }
}

.image-content-section .image-content-rows-container .image-content-row .col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .image-content-section .image-content-rows-container .image-content-row .col {
        margin-bottom: 2rem;
    }
}

.image-content-section .image-content-rows-container .image-content-row .col .content-container h3 {
    font-size: 24px;
    font-weight: 700;
    color: #333;
}

.image-content-section .image-content-rows-container .image-content-row .col .content-container p {
    margin-top: 2.4rem;
    font-size: 15px;
    line-height: 28px;
    color: #666;
}

.image-content-section .image-content-rows-container .image-content-row .column-1 {
    padding-left: 12%;
}

@media (max-width: 1000px) {
    .image-content-section .image-content-rows-container .image-content-row .column-1 {
        padding-left: 7%;
    }
}

@media (max-width: 768px) {
    .image-content-section .image-content-rows-container .image-content-row .column-1 {
        padding-left: 0;
        padding: 0 12%;
    }
}

@media (max-width: 480px) {
    .image-content-section .image-content-rows-container .image-content-row .column-1 {
        padding: 0 7%;
    }
}

.image-content-section .image-content-rows-container .image-content-row .column-2 {
    padding-right: 12%;
}

@media (max-width: 1000px) {
    .image-content-section .image-content-rows-container .image-content-row .column-2 {
        padding-right: 7%;
    }
}

@media (max-width: 768px) {
    .image-content-section .image-content-rows-container .image-content-row .column-2 {
        padding-right: 0;
        padding: 0 12%;
    }
}

@media (max-width: 480px) {
    .image-content-section .image-content-rows-container .image-content-row .column-2 {
        padding: 0 7%;
    }
}

.image-content-section .image-content-rows-container .row-1 .column-1 .content-container,
.image-content-section .image-content-rows-container .row-3 .column-1 .content-container {
    padding-right: 4rem;
}

@media (max-width: 900px) {
    .image-content-section .image-content-rows-container .row-1 .column-1 .content-container,
    .image-content-section .image-content-rows-container .row-3 .column-1 .content-container {
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .image-content-section .image-content-rows-container .flex-cr {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.image-content-section .image-content-rows-container .bg-left {
    /* background: url(../images/global/background-1.svg); */
    background-repeat: no-repeat;
    background-position: center left -8rem;
}

.image-content-section .image-content-rows-container .bg-right {
    /* background: url(../images/global/background-1.svg); */
    background-repeat: no-repeat;
    background-position: center right -8rem;
}

.cta-section {
    padding: 6.6rem 16.91%;
    /* background: #00bbb4 url(../images/components/cta-section/cta-section-1.svg); */
    background-position: right bottom;
    background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .cta-section {
        padding: 6.6rem 12%;
    }
}

@media (max-width: 480px) {
    .cta-section {
        padding: 6.6rem 7%;
    }
}

.cta-section .column-1 h2 {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    line-height: 1.5;
}

.cta-section .column-1 p {
    margin-top: 2.9rem;
    font-size: 15px;
    color: #fff;
    opacity: 0.9;
}

.cta-section .column-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .cta-section .column-2 {
        margin-top: 4rem;
    }
}

.cta-section .column-2 button {
    background: #fff;
    border: 1px solid #fff;
    color: #c4a646;
    font-size: 18px;
    padding: 1.5rem 3.6rem;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.6);
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.6);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.cta-section .column-2 button:hover {
    -webkit-box-shadow: 0 10px 30px -12px #000;
    box-shadow: 0 10px 30px -12px #000;
}

.cta-section .column-2 button img {
    margin-left: 1.6rem;
}

.footer-thailand-website {
    background: linear-gradient(194.61deg, #00bbb4 0, #00998e 100%);
    color: #fff;
    font-size: 16px;
    padding: 0;
    font-family: Sarabun, sans-serif;
}

.footer-thailand-website a {
    color: #fff;
}

.footer-thailand-website a:hover {
    color: #fff;
}

.footer-thailand-website .footer-container {
    padding: 3em 6em 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media (max-width: 768px) {
    .footer-thailand-website .footer-container {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 3em 1em 0;
    }
}

.footer-thailand-website .footer-container .column.about {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
}

@media (max-width: 768px) {
    .footer-thailand-website .footer-container .column.about {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
}

.footer-thailand-website .footer-container .column.about .footer-logo {
    margin-bottom: 2em;
}

.footer-thailand-website .footer-container .column.about p {
    line-height: 1.5;
    margin-bottom: 2.4rem;
}

.footer-thailand-website .footer-container .column.about .partner-images {
    margin-bottom: 3.6rem;
}

.footer-thailand-website .footer-container .column.details {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    padding-top: 1em;
}

@media (max-width: 768px) {
    .footer-thailand-website .footer-container .column.details {
        margin-top: 2em;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
}

.footer-thailand-website .footer-container .column.details li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 2.4rem;
}

.footer-thailand-website .footer-container .column.details li .icon {
    height: 1.5em;
    width: 1.5em;
    margin-right: 1.5em;
}

.footer-thailand-website .footer-container .column.details li address {
    font-style: normal;
}

.footer-thailand-website .footer-container .column.details li:not(:nth-last-child(-2n + 2)) {
    margin-bottom: 1em;
}

.footer-thailand-website .footer-container .column.footer-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 500;
    padding-top: 3.5em;
}

@media (max-width: 768px) {
    .footer-thailand-website .footer-container .column.footer-links {
        padding-top: 0;
    }
}

.footer-thailand-website .footer-container .column.footer-links li:not(:last-child) {
    margin-bottom: 1.75em;
}

.footer-thailand-website .footer-container .column .partner-images {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .footer-thailand-website .footer-container .column .partner-images {
        display: none;
    }
}

.footer-thailand-website .footer-container .column .partner-images img {
    height: 2em;
}

.footer-thailand-website .footer-container .column .partner-images img.brinks-logo {
    height: 1.5em;
}

.footer-thailand-website .footer-container .column .partner-images img:not(:last-child) {
    margin-right: 1.125em;
}

.footer-thailand-website .footer-container .column .social-icons {
    margin-left: 2em;
    margin-top: 1em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.footer-thailand-website .footer-container .column .social-icons>div {
    position: relative;
    height: 2.5em;
    width: 2.5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 1em;
    cursor: pointer;
}

.footer-thailand-website .footer-container .column .social-icons>div::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: initial;
    border-radius: 50%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.footer-thailand-website .footer-container .column .social-icons>div:hover::after {
    background-color: rgba(255, 255, 255, 0.15);
}

.footer-thailand-website .copyright {
    font-size: 0.875em;
    padding: 0 6em 3em;
}

@media (max-width: 768px) {
    .footer-thailand-website .copyright {
        text-align: center;
        padding: 1em 1em 2em;
    }
}

.secondary-button {
    border: 0;
    background: #fff;
    color: #00bbb4;
    font-weight: 500;
    font-size: 16px;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.secondary-button img {
    margin-left: 1rem;
}

.secondary-button:focus {
    border: 1px solid transparent;
    outline: 0;
}

.accordion {
    /* -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 10px;
}

.accordion .card-header {
    background: #fff;
    padding-left: 3rem;
    padding-right: 7rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
}

.accordion .card-header:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

@media (max-width: 768px) {
    .accordion .card-header {
        padding-left: 0;
        padding-right: 0;
    }
}

.accordion .card-header h2 button {
    font-size: 16px;
    line-height: 1.5;
    text-decoration: none;
    color: #333;
    text-align: left;
}
.card {
    border: none;
}
#online-accordion-1 .card h5.mb-0 a {
    color: #666666 !important;
    font: 400 22px Sarabun;
    cursor: pointer;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

.accordion .card-body {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background: #fafafa;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-size: 16px;
    line-height: 1.5;
    color: #666;
}

.delivery-banner.desktop {
    background: url('https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/delivery-banner-bg.png') no-repeat;
    background-size: 100% 100%;
    height: 120px;
    width: 100%;
    color: #083863;
    font: 700 14px/18.2px Sarabun Light;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 3%;
    text-align: center;
    margin: 3% 0;
}

.delivery-banner.desktop div img {
    display: block;
    height: 34px;
    margin: auto;
    margin-bottom: 4%;
}
.delivery-banner.desktop div p {
    font: 800 24px/31.2px Sarabun;
    color: #083863;
    float: left;
}

@media (max-width: 768px) {
    .accordion .card-body {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.getting-started-section {
    padding-left: 30rem;
    padding-right: 30rem;
    padding-bottom: 12rem;
    padding-top: 12rem;
    position: relative;
}

.getting-started-section .bg-mandala {
    position: absolute;
    left: 0;
    bottom: 0;
}

@media (max-width: 768px) {
    .getting-started-section .bg-mandala {
        display: none;
    }
}

.getting-started-section.with-background {
    background: #fbfbfb;
    -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
}

@media (max-width: 992px) {
    .getting-started-section {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media (max-width: 768px) {
    .getting-started-section {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.getting-started-section .section-title.center {
    text-align: center;
}

.getting-started-section .column-1,
.getting-started-section .column-2 {
    margin-top: 4.8rem;
}

.getting-started-section .column-1 .list-option {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 2.4rem;
    font-size: 18px;
    line-height: 23px;
    color: #ccc;
}

.getting-started-section .column-1 .list-option.active {
    background: rgba(0, 187, 180, 0.05);
    border-left: 2px solid #00bbb4;
    color: #00bbb4;
}

.getting-started-section .column-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.getting-started-section .column-2 .list-container .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.getting-started-section .column-2 .list-container .list-item.last-item .down-arrow {
    display: none;
}

.getting-started-section .column-2 .list-container .list-item:not(:first-child) {
    margin-top: 2rem;
}

.getting-started-section .column-2 .list-container .list-item.buttons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.getting-started-section .column-2 .list-container .list-item.buttons-container .buttons-outer {
    background: #f7f7f7;
    border-radius: 24px;
    position: relative;
}

.getting-started-section .column-2 .list-container .list-item.buttons-container .buttons-outer .down-arrow {
    position: absolute;
    left: 5.6rem;
    top: 102%;
}

@media (max-width: 480px) {
    .getting-started-section .column-2 .list-container .list-item.buttons-container .buttons-outer .down-arrow {
        left: 0.2rem;
    }
}

.getting-started-section .column-2 .list-container .list-item.buttons-container .buttons-outer button {
    border: 0;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    background: #f7f7f7;
    border-radius: 24px;
    font-weight: 600;
    font-size: 16px;
    color: #999;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

@media (max-width: 480px) {
    .getting-started-section .column-2 .list-container .list-item.buttons-container .buttons-outer button {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        font-size: 14px;
    }
}

.getting-started-section .column-2 .list-container .list-item.buttons-container .buttons-outer button.active {
    background: #00bbb4;
    color: #fff;
}

.getting-started-section .column-2 .list-container .list-item.buttons-container .buttons-outer button:focus {
    outline: 0;
}

.getting-started-section .column-2 .list-container .list-item span {
    margin-left: 1.4rem;
    font-size: 18px;
    line-height: 1.5;
    color: #666;
}

.getting-started-section .column-2 .list-container .list-item .number-container {
    width: 41px;
    height: 42px;
    background: #f7f7f7;
    position: relative;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.getting-started-section .column-2 .list-container .list-item .number-container span {
    color: #00bbb4;
    margin-left: 0;
}

.getting-started-section .column-2 .list-container .list-item .number-container .down-arrow {
    position: absolute;
    left: 2rem;
    top: 100%;
    width: 2px;
    height: 20px;
    background: #f7f7f7;
}

.getting-started-section .column-2 .list-container .button-container {
    margin-top: 5.4rem;
}

.getting-started-section .column-2 .list-container .api-items,
.getting-started-section .column-2 .list-container .pwa-items {
    margin-top: 2rem;
    display: none;
}

.getting-started-section .column-2 .list-container .api-items.show,
.getting-started-section .column-2 .list-container .pwa-items.show {
    display: block;
}

.two-images {
    position: absolute;
    top: 0;
    left: 0;
}

.two-images .image-2 {
    position: absolute;
    bottom: -4rem;
    right: -4rem;
}

.two-images.dashboard-images-1,
.two-images.dashboard-images-2,
.two-images.dashboard-images-3 {
    opacity: 0;
}

.two-images.dashboard-images-1 .image-1,
.two-images.dashboard-images-2 .image-1 {
    position: relative;
    top: -4rem;
}

.two-images.dashboard-images-1 .image-2,
.two-images.dashboard-images-2 .image-2 {
    position: absolute;
    bottom: -8rem;
    right: -10rem;
}

.features-section {
    padding-left: 8.6rem;
    padding-right: 8.6rem;
    margin-top: 15rem;
    padding-bottom: 36rem;
}

@media (max-width: 768px) {
    .features-section {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    .features-section {
        padding-bottom: 8rem;
    }
}

.features-section .column-1 .image-container {
    position: relative;
}

.features-section .column-1 .image-container .image-1 {
    position: absolute;
    top: 0;
    left: 10.8rem;
    z-index: 2;
}

@media (max-width: 1320px) {
    .features-section .column-1 .image-container .image-1 {
        left: 8rem;
    }
}

.features-section .column-1 .image-container .image-2 {
    position: absolute;
    top: 16rem;
    left: -2rem;
    z-index: 1;
}

@media (max-width: 992px) {
    .features-section .column-2 {
        margin-top: 56rem;
    }
}

.features-section .column-2 .owl-carousel {
    position: relative;
}

.features-section .column-2 .owl-carousel .owl-nav {
    position: absolute;
    bottom: 0;
}

.features-section .column-2 .owl-carousel .owl-nav .owl-next {
    margin-left: 7rem;
}

.features-section .column-2 .owl-carousel .owl-dots {
    position: absolute;
    bottom: 0;
    left: 2.4rem;
}

.features-section .column-2 .content {
    font-size: 22px;
    line-height: 1.5;
    color: #333;
    padding-bottom: 12.8rem;
}

@media (max-width: 992px) {
    .features-section .column-2 .content {
        padding-bottom: 4.8rem;
    }
}

.features-section .section-title {
    text-align: center;
}

.features-section .carousel-container {
    margin-top: 4.8rem;
}

@media (min-width: 992px) {
    .safety-of-gold {
        padding-left: 24rem;
        padding-right: 24rem;
    }
}

.safety-of-gold h1 {
    color: #083863;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem;
}

@media (min-width: 992px) {
    .safety-of-gold h1 {
        font-size: 36px;
        line-height: 47px;
        text-align: center;
    }
}

.safety-of-gold p {
    margin: 0 auto;
    color: #083863;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
}

.safety-of-gold ul {
    margin-top: 8rem;
}

@media (min-width: 992px) {
    .safety-of-gold ul {
        margin-top: 8.4rem;
    }
}

.safety-of-gold ul li {
    text-align: center;
    margin-bottom: 6rem;
    padding-left: 6rem;
    padding-right: 6rem;
}

.safety-of-gold ul li.spacer {
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .safety-of-gold ul li {
        margin-bottom: 7.4rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

.safety-of-gold ul li .image-container {
    margin-bottom: 18px;
}

.safety-of-gold ul li .image-container .vistra {
    width: 120px;
}

.safety-of-gold ul li .pure-gold {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.safety-of-gold ul li .pure-gold div {
    margin-left: 42px;
}

.trusted-partners.partners-mobile {
    margin-top: 12rem;
}

.trusted-partners.partners-mobile.about-m {
    margin-top: 4rem;
}

.trusted-partners.partners-mobile .section-title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #083863;
}

.trusted-partners.partners-mobile .logos-container {
    margin-top: 3rem;
}

.trusted-partners.partners-mobile .logos-container .image-container {
    margin-bottom: 3.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.trusted-partners.partners-desktop {
    margin-top: 12rem;
    padding-left: 18rem;
    padding-right: 18rem;
}

.trusted-partners.partners-desktop.a-margin {
    margin-top: 4vh;
}

.trusted-partners.partners-desktop .section-subtitle {
    font-weight: 700;
    font-size: 36px;
    color: #083863;
    text-align: center;
}

.trusted-partners.partners-desktop .section-subtitle.partner-heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: #083863;
}

.sp-animation-4.partner-heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: #083863;
}

.trusted-partners.partners-desktop .logos-container {
    margin-top: 8.5rem;
}

.trusted-partners.partners-desktop .logos-container .image-container {
    margin-bottom: 4.6rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.trusted-partners.partners-desktop .logos-container .tanishq,
.trusted-partners.partners-mobile .logos-container .tanishq {
    height: 42px;
    width: 63px;
    background: url("../../assets/images/sprite-image.png") no-repeat -355px -194px;
}

.trusted-partners.partners-desktop .logos-container .phonepe,
.trusted-partners.partners-mobile .logos-container .phonepe {
    height: 34px;
    width: 113px;
    background: url("../../assets/images/sprite-image.png") no-repeat -179px -5px;
}

.trusted-partners.partners-desktop .logos-container .amazon-pay,
.trusted-partners.partners-mobile .logos-container .amazon-pay {
    height: 24px;
    width: 124px;
    background: url("../../assets/images/sprite-image.png") no-repeat -327px -14px;
}

.trusted-partners.partners-desktop .logos-container .flipkart,
.trusted-partners.partners-mobile .logos-container .flipkart {
    height: 32px;
    width: 119px;
    background: url("../../assets/images/sprite-image.png") no-repeat -483px -4px;
}

.trusted-partners.partners-desktop .logos-container .bharatpe,
.trusted-partners.partners-mobile .logos-container .bharatpe {
    height: 33px;
    width: 119px;
    background: url("../../assets/images/sprite-image.png") no-repeat -641px -6px;
}

.trusted-partners.partners-desktop .logos-container .axis-bank,
.trusted-partners.partners-mobile .logos-container .axis-bank {
    height: 32px;
    width: 122px;
    background: url("../../assets/images/sprite-image.png") no-repeat -642px -135px;
}

.trusted-partners.partners-desktop .logos-container .federal-bank,
.trusted-partners.partners-mobile .logos-container .federal-bank {
    height: 36px;
    width: 116px;
    background: url("../../assets/images/sprite-image.png") no-repeat -10px -68px;
}

.trusted-partners.partners-desktop .logos-container .freecharge,
.trusted-partners.partners-mobile .logos-container .freecharge {
    height: 27px;
    width: 130px;
    background: url("../../assets/images/sprite-image.png") no-repeat 0px -275px;
}

.trusted-partners.partners-desktop .logos-container .caratlane,
.trusted-partners.partners-mobile .logos-container .caratlane {
    height: 33px;
    width: 125px;
    background: url("../../assets/images/sprite-image.png") no-repeat -321px -69px;
}

.trusted-partners.partners-desktop .logos-container .candere,
.trusted-partners.partners-mobile .logos-container .candere {
    height: 33px;
    width: 117px;
    background: url("../../assets/images/sprite-image.png") no-repeat -485px -67px;
}

.trusted-partners.partners-desktop .logos-container .mobikwik,
.trusted-partners.partners-mobile .logos-container .mobikwik {
    height: 41px;
    width: 112px;
    background: url("../../assets/images/sprite-image.png") no-repeat -158px -266px;
}

.trusted-partners.partners-desktop .logos-container .bajaj-markets,
.trusted-partners.partners-mobile .logos-container .bajaj-markets {
    height: 30px;
    width: 110px;
    background: url("../../assets/images/sprite-image.png") no-repeat -644px -70px;
}

.trusted-partners.partners-desktop .logos-container .fisdom,
.trusted-partners.partners-mobile .logos-container .fisdom {
    height: 27px;
    width: 125px;
    background: url("../../assets/images/sprite-image.png") no-repeat -803px -71px;
}

.trusted-partners.partners-desktop .logos-container .airtel-payments,
.trusted-partners.partners-mobile .logos-container .airtel-payments {
    height: 18px;
    width: 122px;
    background: url("../../assets/images/sprite-image.png") no-repeat -806px -139px;
}

.trusted-partners.partners-desktop .logos-container .mipay,
.trusted-partners.partners-mobile .logos-container .mipay {
    height: 33px;
    width: 104px;
    background: url("../../assets/images/sprite-image.png") no-repeat -17px -136px;
}

.trusted-partners.partners-desktop .logos-container .oppokash,
.trusted-partners.partners-mobile .logos-container .oppokash {
    height: 33px;
    width: 121px;
    background: url("../../assets/images/sprite-image.png") no-repeat -169px -139px;
}

.trusted-partners.partners-desktop .logos-container .five-paisa,
.trusted-partners.partners-mobile .logos-container .five-paisa {
    height: 44px;
    width: 47px;
    background: url("../../assets/images/sprite-image.png") no-repeat -367px -133px;
}

.trusted-partners.partners-desktop .logos-container .iifl,
.trusted-partners.partners-mobile .logos-container .iifl {
    height: 30px;
    width: 145px;
    background: url("../../assets/images/sprite-image.png") no-repeat -467px -203px;
}

.trusted-partners.partners-desktop .logos-container .khatabook,
.trusted-partners.partners-mobile .logos-container .khatabook {
    height: 25px;
    width: 120px;
    background: url("../../assets/images/sprite-image.png") no-repeat -10px -206px;
}

.trusted-partners.partners-desktop .logos-container .galaxy-card,
.trusted-partners.partners-mobile .logos-container .galaxy-card {
    height: 30px;
    width: 120px;
    background: url("../../assets/images/sprite-image.png") no-repeat -168px -206px;
}

.trusted-partners.partners-desktop .logos-container .b2c,
.trusted-partners.partners-mobile .logos-container .b2c {
    height: 27px;
    width: 125px;
    background: url("../../assets/images/sprite-image.png") no-repeat -641px -205px;
}

.trusted-partners.partners-desktop .logos-container .jarlogo,
.trusted-partners.partners-mobile .logos-container .jarlogo {
    height: 44px;
    width: 180px;
    background: url("../../assets/images/sprite-image.png") no-repeat -769px -196px;
}

.partners-section {
    background: 0;
}

.partners-desktop {
    display: none;
}

@media (min-width: 992px) {
    .partners-mobile {
        display: none;
    }
    .partners-desktop {
        display: block;
    }
}

.our-team h1 {
    margin: 78px 0 48px;
    color: #083863;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
}

@media (min-width: 992px) {
    .our-team .team-members {
        padding-left: 6.5rem;
        padding-right: 6.5rem;
    }
}

.our-team .team-members .team-member-outer {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

.our-team .team-members .team-member-outer .team-member-inner {
    background: #fff;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    max-height: 282px;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    margin-bottom: 12px;
}

@media (min-width: 992px) {
    .our-team .team-members .team-member-outer .team-member-inner {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .our-team .team-members .team-member-outer .team-member-inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.our-team .team-members .team-member-outer .team-member-inner>.image-container {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-top: 1.6rem;
}

@media (min-width: 992px) {
    .our-team .team-members .team-member-outer .team-member-inner>.image-container {
        padding-top: 0;
    }
}

.our-team .team-members .team-member-outer .team-member-inner>.image-container img {
    border-radius: 50%;
}

@media (min-width: 992px) {
    .our-team .team-members .team-member-outer .team-member-inner>.image-container img {
        height: 120px;
        width: 120px;
    }
}

.our-team .team-members .team-member-outer .team-member-inner .member-info {
    -ms-flex-preferred-size: 65%;
    flex-basis: 65%;
    text-align: left;
}

@media (min-width: 992px) {
    .our-team .team-members .team-member-outer .team-member-inner .member-info {
        text-align: center;
    }
}

.our-team .team-members .team-member-outer .team-member-inner .member-info .member-name {
    margin: 16px 0 4px;
    font-size: 18px;
    font-weight: 700;
    color: #083863;
}

.our-team .team-members .team-member-outer .team-member-inner .member-info .position {
    color: #999;
    font-size: 16px;
    font-weight: 400;
}

.our-team .team-members .team-member-outer .team-member-inner .member-info .image-container {
    margin: 12px 0 16px;
}

@media (min-width: 992px) {
    .our-team .team-members .team-member-outer .team-member-inner .member-info .image-container {
        margin-bottom: 0;
    }
}

.our-team .more-team-members {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .our-team .more-team-members {
        padding-left: 8rem;
    }
}

.our-team .more-team-members .more-team-members-inner {
    margin-top: 48px;
    max-width: 340px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media (min-width: 992px) {
    .our-team .more-team-members .more-team-members-inner {
        max-width: 420px;
        min-width: 410px;
    }
}

.our-team .more-team-members .more-team-members-inner li {
    margin: 0 9px 10px 0;
}

.our-team .more-team-members .more-team-members-inner li:nth-of-type(5n) {
    margin-right: 0;
}

.our-team .work-with-us {
    padding: 36px 5px;
    margin: 38px 0 120px;
    background: #e5f3f3;
    border-radius: 10px;
    text-align: center;
}

@media (min-width: 992px) {
    .our-team .work-with-us {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        text-align: center;
    }
}

.our-team .work-with-us h2 {
    margin-bottom: 18px;
    font-weight: 800;
    font-size: 24px;
    color: #083863;
}

.our-team .work-with-us p {
    margin-bottom: 24px;
    color: #083863;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.our-team .work-with-us p .career-email {
    color: #00bbb4;
}

.our-team .work-with-us a {
    margin: 0 auto;
    color: #00bbb4;
    font-size: 16px;
    font-weight: 500;
}

.our-team .work-with-us a.arrow::after {
    /* content: url(../images/pages/about-us/arrow.png); */
    margin-left: 11px;
    vertical-align: middle;
}

.news {
    margin-top: 12.8rem;
}

@media (min-width: 992px) {
    .news {
        padding-left: 36rem;
        padding-right: 36rem;
        margin-top: 9.6rem;
    }
}

.news h1 {
    margin-bottom: 5.6rem;
    color: #083863;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
}

@media (min-width: 992px) {
    .news h1 {
        font-weight: 300;
        font-size: 24px;
        line-height: 31px;
    }
}

.news ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .news ul {
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    }
}

.news ul li {
    margin-bottom: 26px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 145px;
}

@media (min-width: 992px) {
    .news ul li {
        min-width: 0;
    }
    .news ul li:nth-child(odd) {
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .news ul li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
    }
}

.banner-section.banner-video-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

@media (min-width: 992px) {
    .banner-section.banner-video-section {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

@media (min-width: 992px) {
    .banner-section.banner-video-section {
        padding-right: 12rem;
    }
}

@media (min-width: 992px) {
    .banner-section .column-1 {
        margin-top: 9.6rem;
    }
}

.banner-section .column-1 .banner-content .header-signup-button {
    margin-top: 4.8rem;
}

.banner-section .column-2 {
    margin-top: 3.6rem;
}

@media (min-width: 992px) {
    .banner-section .column-2 .video-container {
        padding-left: 4rem;
    }
}

.banner-section .banner-content-desktop {
    display: none;
}

.banner-section .banner-content-mobile h1 {
    margin-bottom: 0;
}

.banner-section .banner-content-mobile p {
    margin-top: 2.4rem;
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .banner-section {
        padding-left: 12rem;
        padding-right: 18.8rem;
        height: 70vh;
        position: relative;
    }
    .banner-section .banner-content-mobile {
        display: none;
    }
    .banner-section .banner-content-desktop {
        display: block;
    }
    .banner-section .sparkle {
        position: absolute;
    }
    .banner-section .sparkle1 {
        left: 5px;
        bottom: 25%;
        transform: translateY(-25%);
    }
    .banner-section .sparkle2 {
        top: 10%;
        left: 50%;
        transform: translate(-10%, -50%);
    }
    .banner-section .sparkle3 {
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
    }
    .banner-section .sparkle img {
        width: 30px;
    }
    .banner-section .sparkle3 img {
        width: 50px;
    }
}

.banner-section h1 {
    color: #083863;
    font-weight: 700;
    font-size: 4.4rem;
}

@media (min-width: 992px) {
    .banner-section h1 {
        /* font-size: 48px;
        font-weight: 800;
        margin-bottom: 0; */
    }
}

.banner-section .buy-now-button {
    padding: 8px 25px;
    border-radius: 5px;
    margin-top: 4.5rem;
    display: inline-block;
    background: #00bbb4;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 21px;
}

@media (min-width: 992px) {
    .banner-section .header-title-container p {
        margin-top: 3rem;
        font-size: 18px;
        color: #083863;
        maring-bottom: 0;
    }
}

.banner-section .banner-storage-partner {
    padding-bottom: 0;
    margin-top: 7.2rem;
}

.banner-section .banner-storage-partner h1 {
    font-weight: 600;
    font-size: 18px;
    color: #083863;
}

.banner-section .banner-storage-partner .secured-by-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.banner-section .banner-storage-partner .secured-by-images .image-container:first-child {
    margin-right: 2rem;
}

.banner-section .banner-storage-partner .image-container {
    margin-top: 2rem;
}

.banner-section .banner-storage-partner .image-container .vistra {
    width: 120px;
}

.banner-section .banner-storage-partner p {
    margin-top: 1.2rem;
    font-size: 16px;
    color: #666 !important;
}

.banner-section .video {
    margin-bottom: 42px;
    position: relative;
}

.banner-section .video .play-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.banner-section .video .play-button p {
    margin: 0 0 0 7px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.banner-section .banner-content p {
    margin-top: 2.4rem;
    margin-bottom: 0;
    color: #083863;
    font-size: 16px;
    font-weight: 400;
}

@media (min-width: 992px) {
    .banner-section .banner-content h6 {
        margin-bottom: 25px;
        color: #083863;
        font-size: 24px;
        line-height: 30px;
    }
    .banner-section .banner-content p {
        margin-top: 0;
        font-size: 21px;
        line-height: 27px;
    }
    .banner-section .banner-content p .bold_text {
        /* font-size: 24px;
        line-height: 30px; */
    }
}

.banner-section a {
    padding: 13.5px 24px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #00bbb4;
    -webkit-box-shadow: 0 4px 10px rgba(0, 187, 180, 0.25);
    box-shadow: 0 4px 10px rgba(0, 187, 180, 0.25);
    border-radius: 4px;
}

.banner-section .buy-gold-wrapper {
    padding: 0;
    border-radius: 10px;
    background: #fff;
}

.banner-section .buy-gold {
    min-height: auto;
    width: 100%;
    padding: 30px 30px;
    border-radius: 10px;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    background-size: cover;
}

.modal-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-content {
    box-shadow: none;
    border: none;
}
.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}
.modal-content .modal-body {
    padding: 1.25rem;
}
.modal-content .login-form h2, .modal-content .otp-form h2 {
    margin-bottom: 4.25rem;
    color: #00bbb4;
    font-size: 3rem;
    font-family: "Sarabun";
    font-weight: 300;
}
.login-form h2 {
    font-size: 32px;
    font-family: "Sarabun Light";
    margin-bottom: 8%;
    color: #00BBB4;
}
.modal-header .close {
    padding: 0!important;
    margin: 0 0 -1rem auto!important;
}

.modal-header .close {
    background: #000!important;
    height: 24px!important;
    width: 24px!important;
    line-height: 6px!important;
    border-radius: 50%!important;
    cursor: pointer;
}
.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}
.modal-content .btn.btn-primary1 {
    min-width: 100%;
    padding: 0.875rem 1.5rem;
    border-radius: 5px;
    background-color: #00bbb4;
    color: #fff;
}
.modal-content .checkbox_fld span {
    padding-bottom: 1.25rem;
    color: #b2b1b1;
    font-size: 1.6rem;
    font-weight: 500;
}

.public-pages .partners-index {
    /* background: none; */
}

.partners-index {
    background: url('https://www.safegold.com/assets/images/partnerships/homepage-bg-desktop.svg');
    background-repeat: no-repeat;
    background-size: 100%;
}

.public-pages .partners-index .homepage-section-1 {
    height: 70vh;
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important;
    margin-right: 0;
    margin-left: 0;
}
.public-pages .partners-index .homepage-section-1 .column-1 {
    padding: 0 0.938rem;
    justify-content: left;
} 

.partners-index .homepage-section-1 .column-1 {
    padding-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.public-pages .partners-index .homepage-section-1 {
    height: 70vh;
    padding-right: 12.875rem !important;
    padding-left: 12.875rem !important;
    margin-right: 0;
    margin-left: 0;
}
.public-pages .partners-index .homepage-section-1 .column-1 .content-container h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 3.9rem;
}
.public-pages .partners-index .homepage-section-1 .column-1 .content-container .button-video-container {
    margin-top: 2.5rem;
    align-items: flex-start;
    flex-direction: column;
}

.public-pages .partners-index .homepage-section-1 .column-1 .content-container .button-video-container .banner-cta button {
    font-size: 2rem;
}
.hero-section .column-1 .content-container .button-video-container .primary-button {
    margin-top: 0rem;
    opacity: 1;
}
.public-pages .partners-index .homepage-section-1 .column-1 .content-container .button-video-container .banner-cta {
    margin-top: 4.25rem;
    display: block;
}
.public-pages .partners-index .homepage-section-2 {
    padding: 5rem 10rem;
    margin-right: 0;
    margin-left: 0;
}

.public-pages .partners-index .grid-section {
    background: transparent;
}
.public-pages .partners-index .homepage-section-2 div {
    width: 85%;
    padding-bottom: 4rem;
    border-bottom: 1px solid #d3d3d3;
    margin: 0 auto;
    text-align: center;
}
.public-pages .partners-index .homepage-section-2 div p {
    padding: 0 3.125rem;
    margin: 0;
    font-size: 1.8rem;
    line-height: 2.5rem;
}

.public-pages .partners-index .homepage-section-3 {
    padding: 0 6.875rem;
    margin-right: 0;
    margin-left: 0;
    background: transparent;
}
.public-pages .partners-index .homepage-section-3 .grid-section__heading {
    margin: 1.125rem auto 3.125rem;
    color: #083863;
}
.public-pages .partners-index .homepage-section-3 .image-container {
    width: 60%;
    margin: 0 auto 5rem;
    display: flex;
    justify-content: space-between;
}
.image-container img {
    max-width: 100%;
}
.public-pages .partners-index .homepage-section-3 .image-container .tanishq img {
    width: 40%;
    display: block;
    margin: 0 auto;
}
.public-pages .partners-index .homepage-section-3 .heading {
    margin: 0 auto 5rem;
    color: #083863;
    font-size: 2.4rem;
    line-height: 2.25rem;
    text-align: center;
}

.public-pages .partners-index .homepage-section-3 .tab-container {
    width: 100%;
    border-radius: 1.063rem;
    position: relative;
}

.public-pages .partners-index .homepage-section-3 .tab-container .nav-tabs {
    border: 1px solid #dedede;
    border-radius: 9px;
    position: absolute;
    top: -2.5rem;
    left: 50%;
    background: #fff;
    transform: translateX(-50%);
}
.nav-tabs .nav-item {
    margin-bottom: -1px;
}
.public-pages .partners-index .homepage-section-3 .tab-container .nav-tabs .nav-link.active {
    background: #decc62;
    color: #fff;
}

.public-pages .partners-index .homepage-section-3 .tab-container .nav-tabs .nav-link {
    padding: 1rem 4rem;
    border: none;
    border-radius: 9px;
    display: inline-block;
    color: #666;
    text-align: center;
}
.public-pages .partners-index .homepage-section-3 .tab-container .nav-tabs .nav-link.active .inactive {
    display: none;
}

.public-pages .partners-index .homepage-section-3 .tab-container .nav-tabs .nav-link .active {
    display: none;
}

.public-pages .partners-index .homepage-section-3 .tab-container .nav-tabs .nav-link img {
    margin-right: 10px;
}

.public-pages .partners-index .homepage-section-3 .tab-container .tab-content {
    text-align: center;
}
.public-pages .partners-index .homepage-section-3 .tab-container .tab-content > .show {
    display: block;
}
.public-pages .partners-index .homepage-section-3 .terms-conditions {
    padding-bottom: 5px;
    /* border-bottom: 1px solid #666; */
    color: #083863;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-size: 2.3rem;
    font-weight: 500;
}

.public-pages .partners-index .homepage-section-3 .tab-container .tab-content .onlineTab-container, .public-pages .partners-index .homepage-section-3 .tab-container .tab-content .offlineTab-container {
    border-radius: 1.063rem;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
    overflow: auto;
}
.public-pages .partners-index .homepage-section-3 .tab-container .tab-content .online-exchange-steps, .public-pages .partners-index .homepage-section-3 .tab-container .tab-content .offline-exchange-steps {
    margin: 7rem 6.25rem 5rem;
    display: flex;
    justify-content: space-between;
}
.public-pages .partners-index .homepage-section-3 .tab-container .tab-content .online-exchange-steps .steps, .public-pages .partners-index .homepage-section-3 .tab-container .tab-content .offline-exchange-steps .steps {
    width: 36rem;
}
.public-pages .partners-index .homepage-section-3 .tab-container .tab-content a.button {
    padding: 1rem 4.625rem;
    border-radius: 4px;
    margin: 3.125rem auto;
    display: inline-block;
    background-color: #00bbb4;
    color: #fff;
    font-size: 1.8rem;
    cursor: pointer;
}
.public-pages .partners-index .homepage-section-3 .accordion {
    width: 100%;
    margin: 1.25rem 0;
    box-shadow: none;
    background: transparent;
    text-align: left;
}

.public-pages .partners-index .homepage-section-3 .card {
    padding: 0 1.875rem;
    border: none;
}
.public-pages .partners-index .homepage-section-3 .card .card-header {
    padding: 1.875rem;
    padding-left: 0;
    border-bottom: none;
    background-color: #fff;
}
.public-pages .partners-index .homepage-section-3 .card #collapse-1 {
    margin-top: 1.75rem;
}
.public-pages .partners-index .homepage-section-3 .card #collapse-1 .card-body {
    padding: 1.25rem 0;
    border: none;
    background: transparent;
}
.public-pages .partners-index .homepage-section-3 .card .card-body .card {
    padding: 0;
    border-bottom: 1px solid #d3d3d3;
}
.public-pages .partners-index .homepage-section-3 .tab-container .tab-content .online-exchange-steps, .public-pages .partners-index .homepage-section-3 .tab-container .tab-content .offline-exchange-steps {
    margin: 7rem 6.25rem 5rem;
    display: flex;
    justify-content: space-between;
}
.public-pages .partners-index .homepage-section-3 .tab-container .tab-content .online-exchange-steps .steps h3, .public-pages .partners-index .homepage-section-3 .tab-container .tab-content .offline-exchange-steps .steps h3 {
    margin: 4.875rem auto 1.25rem;
    color: #decc62;
    font-size: 2.4rem;
    line-height: 1.438rem;
}
.public-pages .partners-index .homepage-section-3 .tab-container .tab-content a.button {
    padding: 1.8rem 4.625rem;
    border-radius: 4px;
    margin: 3.125rem auto;
    display: inline-block;
    background-color: #00bbb4;
    color: #fff;
}
.steps p {
    font-size: 1.4rem;
    margin: 2rem;
    line-height: 2.2rem;
}

.p-20 {
    padding: 20px !important;
}
.login-form {
    max-width: 100%;
    background: #fff;
    padding: 0;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    margin: 0 auto;
    font-family: Sarabun;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

input[type="radio"] {
    display: none;
}
input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}
input[type="radio"] {
    display: none;
}
input[type="radio"] {
    display: none;
}
.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
    
}
input, textarea {
    background-image: none!important;
}
/* .form-control.b-input {
    background: transparent;
    border-bottom: 1.5px solid #707070;
    border-radius: 0px;
    font-size: 23px;
    line-height: 0px;
} */

@media (min-width: 992px) {
    .banner-section .buy-gold {
        margin: 0 auto;
    }
}

.banner-section .buy-gold .option-tabs {
    padding-top: 0;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.banner-section .buy-gold .option-tabs li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #ccc;
}

.banner-section .buy-gold .option-tabs li p {
    margin-left: 4px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
}

.banner-section .buy-gold .option-tabs .buy::before {
    content: "";
    background: url("../../assets/images/sprite_image.png") -27px 126px;
    height: 25px;
    width: 28px;
}

.banner-section .buy-gold .option-tabs .buy.active::before {
    content: "";
    background: url("../../assets/images/sprite_image.png") -90px -36px;
    height: 18px;
    width: 21px;
}

.banner-section .buy-gold .option-tabs .sell::before {
    content: "";
    background: url("../../assets/images/sprite_image.png") 5px 126px;
    height: 26px;
    width: 30px;
}

.banner-section .buy-gold .option-tabs .sell.active::before {
    content: "";
    background: url("../../assets/images/sprite_image.png") 116px -36px;
    height: 18px;
    width: 21px;
}

.banner-section .buy-gold .option-tabs .sip::before {
    content: "";
    background: url("../../assets/images/sprite_image.png") -54px 126px;
    height: 26px;
    width: 30px;
}

.banner-section .buy-gold .option-tabs .sip.active::before {
    content: "";
    background: url("../../assets/images/sprite_image.png") -152px -35px;
    height: 23px;
    width: 17px;
}

.banner-section .buy-gold .option-tabs .active {
    color: #00BBB4;
    border-bottom: 2px solid #00BBB4;
}

.banner-section .buy-gold .option-tabs .active::before {
    color: #00BBB4;
}

@media screen and (max-width: 991px) {
    .banner-section .sparkle {
        display: none;
    }
    .banner-section .buy-gold .option-tabs .active {
        color: #00bbb4;
        border-color: #00bbb4;
    }
    .banner-section .buy-gold .option-tabs .active::before {
        color: #00bbb4;
    }
    .banner-section .buy-gold .option-tabs .buy.active::before {
        height: 26px;
        width: 30px;
        background: url("../../assets/images/sprite_image.png") -113px 126px;
    }
    .banner-section .buy-gold .option-tabs .sell.active::before {
        height: 26px;
        width: 30px;
        background: url("../../assets/images/sprite_image.png") -83px 126px;
    }
    .banner-section .buy-gold .option-tabs .sip.active::before {
        height: 26px;
        width: 30px;
        background: url("../../assets/images/sprite_image.png") -143px 126px;
    }
}

.banner-section .buy-gold .buy-gold-container,
.banner-section .buy-gold .sell-gold-container,
.banner-section .buy-gold .sip-gold-container {
    display: none;
}

.banner-section .buy-gold .buy-gold-container.active {
    display: block;
    -webkit-animation: slide 0.5s forwards;
    opacity: 0;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 0.3s;
}

@-webkit-keyframes slide {
    100% {
        opacity: 1;
    }
}

@keyframes slide {
    100% {
        opacity: 1;
    }
}

.banner-section .buy-gold .sell-gold-container,
.banner-section .buy-gold .sip-gold-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.banner-section .buy-gold .sell-gold-container.active,
.banner-section .buy-gold .sip-gold-container.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 0.3s;
}

.banner-section .buy-gold .sell-gold-container .image-container,
.banner-section .buy-gold .sip-gold-container .image-container {
    margin-top: 5.4rem;
}

.banner-section .buy-gold .sell-gold-container h2,
.banner-section .buy-gold .sip-gold-container h2 {
    margin-top: 3.2rem;
    margin-bottom: 2.4rem;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #083863;
}

.banner-section .buy-gold .sell-gold-container button.primary-button,
.banner-section .buy-gold .sip-gold-container button.primary-button {
    margin: 30px auto 10px;
    padding: 10px 20px;
    background: #00bbb4;
    -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    outline: 0;
    display: flex;
    border: 0;
}

.delivery-container-1 {
    position: relative;
}

.banner-section .buy-gold .gold-info,
.delivery-container-1 .gold-info {
    padding: 12px;
    margin-bottom: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 5px;
    background: #fbfbfb;
}

@media (min-width: 992px) {
    .banner-section .buy-gold .gold-info {
        margin-bottom: 18px;
    }
}

.banner-section .buy-gold .gold-info ul,
.delivery-container-1 .gold-info ul {
    width: 100%;
    margin-bottom: 0;
    text-align: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.banner-section .buy-gold .gold-info ul .live-price,
.delivery-container-1 .gold-info ul .live-price {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.banner-section .buy-gold .gold-info ul .live-price .image-container,
.delivery-container-1 .gold-info ul .live-price .image-container {
    margin-right: 8px;
}

.banner-section .buy-gold .gold-info ul p,
.delivery-container-1 .gold-info ul p {
    font-size: 14px;
    color: #ccc;
    margin-bottom: 2px;
}

.banner-section .buy-gold .gold-info ul .bold,
.delivery-container-1 .gold-info ul .bold {
    color: #333;
    font-size: 18px;
    font-weight: 500;
}

.banner-section .buy-gold .gold-calculator {
    margin: 0 auto;
    color: #fff;
    border-radius: 5px;
    background: #00BBB4;
    position: relative;
    height: auto;
}

.banner-section .buy-gold .gold-calculator form {
    padding: 15px 15px 20px;
}

.banner-section .buy-gold .gold-calculator form label {
    padding-bottom: 8px;
    font-size: 12px;
    display: block;
    cursor: pointer;
}

.banner-section .buy-gold .gold-calculator form input {
    width: 30%;
    padding: 10px 0;
    margin-bottom: 0;
    padding-left: 31px;
    border-radius: 0px;
    background: transparent;
    outline: 0;
    border: 0;
    color: #fff;
    font-size: 33px;
    font-weight: 300;
    text-align: left;
    opacity: 1;
}

.banner-section .buy-gold .gold-calculator form .measurement_rupee {
    height: 50px;
}

.banner-section .buy-gold .gold-calculator #buyByrupee::placeholder {
    color: #fff;
}

.banner-section .buy-gold .gold-calculator .input-group {
    border-color: #fff;
}

.input-group-append .input-group-text {
    border-radius: 0px;
    background: transparent;
    outline: 0;
    border: 0;
    color: #fff;
    font-size: 11px;
    font-weight: 300;
    text-align: center;
    opacity: 1;
}

.input-group-append {
    margin-left: 0px !important;
    padding-top: 6%;
}

.input-group {
    border-bottom: 1px solid #fff;
}

.post-gm {
    font-size: 31px;
    position: absolute;
    left: 37%;
}

#buyBygram {
    padding-left: 0px;
}

.suffix-input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.banner-section .buy-gold .gold-calculator form input::-webkit-input-placeholder {
    color: #fff;
    font-size: 33px;
    font-weight: 300;
    text-align: left;
    opacity: 0.7;
}

.banner-section .buy-gold .gold-calculator form input:-ms-input-placeholder {
    color: #fff;
    font-size: 33px;
    font-weight: 300;
    text-align: left;
    opacity: 0.7;
}

.banner-section .buy-gold .gold-calculator form input::-ms-input-placeholder {
    color: #fff;
    font-size: 33px;
    font-weight: 300;
    text-align: left;
    opacity: 0.7;
}

.banner-section .buy-gold .gold-calculator form input::placeholder {
    color: #fff;
    font-size: 33px;
    font-weight: 300;
    text-align: left;
    opacity: 0.7;
}

.measurement_rupee::after {
    content: "" attr(data-domain);
    position: relative;
    top: -48px;
    left: 8px;
    color: inherit;
    font-size: 33px;
    font-family: inherit;
    width: 100%;
}

.measurement_gram::after {
    content: "" attr(data-domain);
    position: relative;
    top: -38px;
    left: 89%;
    color: inherit;
    font-size: 18px;
    font-family: inherit;
}

.btn-wrapper p {
    display: inline-block;
    height: 32px;
    width: 57px;
    font-size: 12px;
    background-color: #fff;
    color: #bd8c2b;
    text-align: center;
    border-radius: 3px;
    padding: 8px 5px;
    cursor: pointer;
}

.banner-section .buy-gold .gold-calculator form button {
    margin: 30px auto 10px;
    padding: 10px 20px;
    background: #fff;
    -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #C4A646;
    font-weight: 600;
    font-size: 18px;
    outline: 0;
    display: flex;
    border: 0;
}

.banner-section .buy-gold .gold-calculator form button::after {
    margin-left: 3px;
    content: url("https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/global/right-gold-arrow.svg");
    height: 24px;
}

.banner-section .buy-gold .sell-gold-container button.primary-button::after,
.banner-section .buy-gold .sip-gold-container button.primary-button::after {
    margin-left: 3px;
    content: url("https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/global/right-gold-arrow.svg");
    height: 24px;
}

@media (min-width: 992px) {}

@media screen and (max-width: 991px) {
    .banner-section .buy-gold .gold-calculator {
        background: #00bbb4;
        color: #fff;
    }
    .banner-section .buy-gold .gold-calculator form input,
    .input-group-append .input-group-text,
    .banner-section .buy-gold .gold-calculator .input-group,
    .banner-section .buy-gold .gold-calculator #buyByrupee::placeholder {
        color: #fff;
    }
    .banner-section .buy-gold .gold-calculator .input-group {
        border-color: #fff;
    }
    .banner-section .buy-gold .gold-calculator form button {
        background: #fff;
        color: #c4a646;
    }
    .banner-section .buy-gold .sell-gold-container button.primary-button,
    .banner-section .buy-gold .sip-gold-container button.primary-button {
        background: #00bbb4;
        color: #fff;
    }
    .banner-section .buy-gold .gold-calculator form button::after {
        content: url('https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/global/right-gold-arrow.svg');
        margin-top: 3px;
        margin-left: 0;
    }
    .banner-section .buy-gold .sell-gold-container button.primary-button::after,
    .banner-section .buy-gold .sip-gold-container button.primary-button::after {
        content: "";
    }
}

@media (min-width: 1366px) {}

@media only screen and (width: 1280px) {
    .faq-m-r {
        margin-right: 16vh;
    }
    .banner-section .buy-gold .gold-calculator form input {
        font-size: 33px;
    }
    .post-gm {
        top: 18%;
        left: 42%;
        font-size: 24px;
    }
    .measurement_rupee::after {
        top: -49px;
    }
}

.digital-section {
    margin-top: 4.8rem;
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    position: relative;
    background: #fafafa;
    -webkit-clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);
    clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);
}

@media (min-width: 992px) {
    .digital-section {
        margin-top: 9.6rem;
    }
}

.digital-section .background-dots {
    display: none;
    position: absolute;
    bottom: 3rem;
    right: -1rem;
}

@media (min-width: 992px) {
    .digital-section .background-dots {
        display: block;
    }
}

@media (min-width: 992px) {
    .digital-section {
        padding-left: 12.2rem;
        padding-right: 12.2rem;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

@media (min-width: 992px) {
    .digital-section {
        -webkit-clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
        clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
    }
}

.digital-section h1 {
    color: #083863;
    font-size: 34px;
    font-weight: 800;
}

@media (min-width: 992px) {
    .digital-section h1 {
        font-size: 36px;
        font-weight: 700;
    }
}

.digital-section .cards-container {
    margin-top: 4.2rem;
}

@media (min-width: 992px) {
    .digital-section .cards-container {
        margin-top: 7.2rem;
    }
}

.digital-section .cards-container .card-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 3.6rem;
}

@media (min-width: 992px) {
    .digital-section .cards-container .card-item {
        margin-bottom: 3.6rem;
    }
}

.digital-section .cards-container .card-item .image-container {
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-right: 2.4rem;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.digital-section .cards-container .card-item .content h2 {
    font-weight: 500;
    font-size: 22px;
    color: #083863;
}

.digital-section .cards-container .card-item .content p {
    margin-top: 0.8rem;
    font-size: 14px;
    line-height: 1.5;
    color: #666;
}

.better-than-savings-section {
    margin-top: 9.6rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

@media (min-width: 992px) {
    .better-than-savings-section .column-1 {
        padding-right: 10rem;
    }
}

@media (min-width: 992px) {
    .better-than-savings-section .column-1 h1 {
        padding-right: 5rem;
    }
}

@media (min-width: 992px) {
    .better-than-savings-section {
        margin-top: 0;
        padding-left: 18rem;
        padding-right: 18.2rem;
    }
}

@media (min-width: 992px) {
    .better-than-savings-section .section-outer {
        width: 100%;
    }
}

.better-than-savings-section .grow-wealth-smarter-desktop {
    display: none;
}

@media (min-width: 992px) {
    .better-than-savings-section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .better-than-savings-section .grow-wealth-smarter-desktop {
        display: block;
    }
    .better-than-savings-section .grow-wealth-smarter-mobile {
        display: none;
    }
}

.better-than-savings-section h1 {
    color: #083863;
    font-size: 34px;
    font-weight: 800;
}

@media (min-width: 992px) {
    .better-than-savings-section h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
    }
}

.better-than-savings-section p {
    color: #083863;
    font-size: 18px;
    font-weight: 500;
    margin-top: 3.6rem;
    line-height: 23px;
}

.better-than-savings-section .chart-container {
    margin-top: 1.8rem;
    width: 100%;
}

.better-than-savings-section .chart-container .chart.image-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.better-than-savings-section .chart-container .chart.image-container img {
    min-height: 350px;
}

.better-than-savings-section .chart-container .chart img {
    display: none;
}

.better-than-savings-section .chart-container .chart .active {
    display: block;
}

@media (min-width: 992px) {
    .better-than-savings-section .year-tabs-container {
        margin-top: 2rem;
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

.better-than-savings-section .year-tabs-container span {
    display: none;
}

@media (min-width: 992px) {
    .better-than-savings-section .year-tabs-container span {
        display: block;
        font-size: 14px;
        line-height: 18px;
        color: #999;
    }
}

.better-than-savings-section .year-tabs {
    margin-top: 2.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

@media (min-width: 992px) {
    .better-than-savings-section .year-tabs {
        margin-top: 1.2rem;
    }
}

.better-than-savings-section .year-tabs li {
    cursor: pointer;
}

.better-than-savings-section .year-tabs .disabled-button {
    padding: 0.9rem 3rem;
    background: #ccc;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.disabled-btn {
    opacity: 0.5 !important;
}

@media (min-width: 992px) {
    .better-than-savings-section .year-tabs .disabled-button {
        padding: 0.9rem 2.4rem;
    }
}

.better-than-savings-section .year-tabs .active {
    background: #00bbb4;
    -webkit-box-shadow: 0 4px 10px rgba(0, 187, 180, 0.25);
    box-shadow: 0 4px 10px rgba(0, 187, 180, 0.25);
    border-radius: 4px;
}

.better-than-savings-section .graph-data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    margin-top: 2.4rem;
}

@media (min-width: 992px) {
    .better-than-savings-section .graph-data {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.better-than-savings-section .graph-data li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.better-than-savings-section .graph-data li:first-child {
    margin-right: 3rem;
}

.better-than-savings-section .graph-data li .gold,
.better-than-savings-section .graph-data li .grey {
    width: 18px;
    height: 18px;
    margin-right: 0.8rem;
    border-radius: 50%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0.2rem;
}

.better-than-savings-section .graph-data li .gold {
    background-color: #e8a50f;
}

.better-than-savings-section .graph-data li .grey {
    background-color: #a7bcce;
}

.better-than-savings-section .graph-data li .label {
    margin-bottom: 0;
    color: #666;
    font-weight: 400;
    font-size: 15px;
    display: block;
}

.better-than-savings-section .graph-data li .price {
    color: #083863;
    font-size: 22px;
    font-weight: 500;
    margin-top: 0.4rem;
    display: block;
}

.better-than-savings-section .grow-wealth-smarter {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 5.4rem;
    border-radius: 10px;
    background: #e5f3f3;
    text-align: center;
    width: 100%;
}

@media (min-width: 992px) {
    .better-than-savings-section .grow-wealth-smarter {
        margin-top: 4.8rem;
        padding-top: 2.4rem;
        padding-bottom: 3.8rem;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        text-align: left;
    }
}

.better-than-savings-section .grow-wealth-smarter h2 {
    font-weight: 800;
    font-size: 22px;
    color: #083863;
}

.better-than-savings-section .grow-wealth-smarter p {
    margin-top: 1.8rem;
    margin-bottom: 3.8rem;
    color: #083863;
    font-weight: 400;
    font-size: 15px;
}

@media (min-width: 992px) {
    .better-than-savings-section .grow-wealth-smarter p {
        margin-top: 1rem;
        margin-bottom: 3.8rem;
    }
}

.better-than-savings-section .grow-wealth-smarter a.button {
    padding: 13px 24px;
    border-radius: 4px;
    background: #00bbb4;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.better-than-savings-section .grow-wealth-smarter a.button:hover {
    color: #fff !important;
}

.better-than-savings-section .grow-wealth-smarter a.button::after {
    content: url(https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/white-arrow.svg);
    margin-left: 13px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.better-than-savings-section .grow-wealth-smarter a.button:hover::after {
    content: url(https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/white-arrow.svg);
    margin-left: 15px;
}

.redeem-gold-section {
    margin-top: 4.8rem;
    padding-top: 12rem;
    padding-bottom: 8rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background: #fafafa;
    -webkit-clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);
    clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);
}

@media (min-width: 992px) {
    .redeem-gold-section {
        margin-top: 12rem;
        padding-top: 12rem;
        padding-bottom: 13.6rem;
        padding-left: 24rem;
        padding-right: 24rem;
        -webkit-clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
        clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
    }
}

.redeem-gold-section h1 {
    color: #083863;
    font-size: 34px;
    font-weight: 800;
}

@media (min-width: 992px) {
    .redeem-gold-section h1 {
        text-align: center;
    }
}

.redeem-gold-section p {
    color: #666;
    font-size: 15px;
    font-weight: 400;
}

.redeem-gold-section .redeem-option-tabs {
    margin-top: 4.2rem;
    margin-bottom: 4.2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .redeem-gold-section .redeem-option-tabs {
        margin-top: 5rem;
        margin-bottom: 5.4rem;
    }
}

.redeem-gold-section .redeem-option-tabs li {
    font-weight: 500;
    font-size: 18px;
    color: #ccc;
    cursor: pointer;
}

.redeem-gold-section .redeem-option-tabs .coins {
    margin-right: 3.6rem;
}

.redeem-gold-section .redeem-option-tabs .active-tab {
    color: #00bbb4;
    border-bottom: 2px solid #00bbb4;
}

.redeem-gold-section .jewellery-tab-content li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin-bottom: 36px;
}

@media (min-width: 992px) {
    .redeem-gold-section .jewellery-tab-content li {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.redeem-gold-section .jewellery-tab-content li .image-container {
    width: 32%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 1.2rem;
}

@media (min-width: 992px) {
    .redeem-gold-section .jewellery-tab-content li .image-container {
        width: auto;
        margin-right: 0;
    }
}

.redeem-gold-section .jewellery-tab-content li .info .heading {
    color: #083863;
    font-size: 22px;
    font-weight: 500;
}

@media (min-width: 992px) {
    .redeem-gold-section .jewellery-tab-content li .info .heading {
        margin-top: 2rem;
    }
}

.redeem-gold-section .jewellery-tab-content li .info .content {
    font-size: 15px;
    line-height: 1.5;
    color: #666;
}

@media (min-width: 992px) {
    .redeem-gold-section .jewellery-tab-content li .info .content {
        text-align: center;
        margin-top: 0.8rem;
        max-width: 350px;
    }
}

.redeem-gold-section .coins-tab-content {
    text-align: center;
}

.redeem-gold-section .coins-tab-content .column-2 {
    margin-top: 3.6rem;
}

@media (min-width: 992px) {
    .redeem-gold-section .coins-tab-content .column-2 {
        margin-top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.redeem-gold-section .coins-tab-content .column-2 p {
    margin-top: 1.6rem;
    font-size: 15px;
    line-height: 1.5;
    text-align: center;
    color: #666;
}

@media (min-width: 992px) {
    .redeem-gold-section .coins-tab-content .column-2 p {
        text-align: left;
        margin-top: 2.4rem;
    }
}

.redeem-gold-section .coins-tab-content .main {
    color: #083863;
    font-size: 22px;
    font-weight: 500;
}

@media (min-width: 992px) {
    .redeem-gold-section .coins-tab-content .main {
        text-align: left;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
    }
}

.redeem-gold-section .tab-option {
    display: none;
}

.redeem-gold-section .active-tab-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.security-partners-section {
    width: 95%;
    padding: 72px 0;
    margin: 0 auto;
}

.security-partners-section p {
    color: #666;
    font-size: 15px;
    font-weight: 400;
}

.security-partners-section .heading {
    margin-bottom: 41px;
    color: #083863;
    font-size: 24px;
    font-weight: 600;
}

.security-partners-section .security-partners {
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.security-partners-section .security-partners li {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin-bottom: 24px;
    width: 150px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.insights-section {
    margin-top: 12rem;
}

.insights-section h1 {
    font-size: 36px;
    font-weight: 700;
    color: #083863;
    text-align: center;
    margin-bottom: 60px;
}

.insights-section .news-container {
    margin-left: 0;
    margin-right: 0;
}

@media (min-width: 992px) {
    .insights-section .news-container {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

.insights-section .news-container .news-item-outer {
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 992px) {
    .insights-section .news-container .news-item-outer {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
}


/* term of use  */
.information-container>ol:first-child>li:before {
    font-size: 24px;
    font-weight: bold;
}

.terme_of_use_ol li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

.terme_of_use_ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    margin-bottom: 3.5rem;
}

.terme_of_use_ol li li:before {
    content: counters(item, ".") " ";
}

.terme_of_use_ol li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    font-size: 16px;
    line-height: 1.4;
}
b, strong {
    font-weight: bolder;
}


/* faq  */
ul.tabs-ul {
    width: 100%;
    height: 49px;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    place-content: center;
}
ul.tabs-ul li {
    list-style-type: none;
    display: inline-block;
    border: 1px solid #D2C061;
    color: #D2C061;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.5rem;
    margin: 0px 5px;
    line-height: 21px;
    cursor: pointer;
}

ul.tabs-ul .active {
    background-color: #D2C061;
    color: #fff !important;
}

.section-heading {
    padding: 0;
    margin-bottom: 2rem;
    display: block;
    font-size: 26px;
    font-weight: 700;
    text-align: left;
}






.insights-section .news-container .news-item {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 10px;
    margin-bottom: 24px;
}

.insights-section .news-container .news-item:hover {
    -webkit-box-shadow: 0 16px 66px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 16px 66px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(0, 0, 0, 0.1);
}

.insights-section .news-container .news-item .image-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.insights-section .news-container .news-item .image-container img {
    width: 100%;
    height: 180px;
    -o-object-fit: cover;
    object-fit: cover;
}

.insights-section .news-container .news-item .content {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
}

.insights-section .news-container .news-item .content h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
}

.insights-section .news-container .news-item .content p {
    font-size: 14px;
    line-height: 150%;
    color: #666;
    margin: 12px 0 22px;
}

.insights-section .news-container .news-item .content .link-button {
    margin-left: 0;
    font-weight: 500;
    font-size: 16px;
    color: #00bbb4;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.insights-section .news-container .news-item .content .link-button::after {
    vertical-align: middle;
    /* content: url(../images/pages/home-page-new/arrow.svg); */
    content: "";
    /* background: url(../images/pages/home-page-new/sprite_image.png) -84px 151px; */
    height: 15px;
    width: 30px;
    display: inline-flex;
}

.insights-section .news-container .news-item .content .link-button:hover::after {
    margin-left: 7px;
}

.start-investing-section {
    background: linear-gradient(210.94deg, #00bbb4 0, #00998e 74.96%);
    text-align: center;
    position: relative;
    margin-top: 12rem;
    bottom: -30px;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 6rem;
    padding-bottom: 13.2rem;
}

@media (min-width: 992px) {
    .start-investing-section {
        padding-top: 9.6rem;
        padding-bottom: 18rem;
        bottom: -5.4rem;
    }
}

@media (min-width: 992px) {
    .start-investing-section .column-1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
}

.start-investing-section .column-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .start-investing-section .column-2 {
        padding-left: 6rem;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.start-investing-section h3 {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
}

.start-investing-section a.button {
    margin-top: 4.2rem;
    padding: 16px 36px;
    color: #bd8c2b;
    font-size: 22px;
    font-weight: 600;
    background: #fff;
    -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

@media (min-width: 992px) {
    .start-investing-section a.button {
        margin-top: 0;
    }
    .start-investing-section a.button:hover {
        text-decoration: none;
    }
    .m-b {
        margin-bottom: 7%;
    }
}

.customer-say-section {
    margin-top: 9.6rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
}

@media (min-width: 992px) {
    .customer-say-section {
        padding-left: 25.4rem;
        padding-right: 25.4rem;
    }
}

.customer-say-section h1 {
    margin-bottom: 42px;
    color: #083863;
    font-size: 34px;
    font-weight: 800;
}

@media (min-width: 992px) {
    .customer-say-section h1 {
        margin-bottom: 7.2rem;
    }
}

@media (min-width: 992px) {
    .customer-say-section ul.owl-carousel {
        position: relative;
    }
    .customer-say-section ul.owl-carousel .owl-dots,
    .customer-say-section ul.owl-carousel .owl-nav {
        position: absolute;
    }
    .customer-say-section ul.owl-carousel .owl-nav {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: -2rem;
    }
    .customer-say-section ul.owl-carousel .owl-nav .owl-next {
        margin-left: 4rem;
    }
    .customer-say-section ul.owl-carousel .owl-dots {
        bottom: -2rem;
        left: 50%;
        -webkit-transform: translateX(-42%);
        transform: translateX(-42%);
    }
}

.live-price-converions {
    display: none;
}

.checkbox_fld {}

@media screen and (max-width: 991px) {
    .banner-section .buy-gold {
        padding: 20px 20px 50px;
        background: #fff;
        box-shadow: 0px 10px 60px rgb(0 0 0 / 10%), 0px 2px 4px rgb(0 0 0 / 10%);
    }
    .input-group-append {
        padding-top: 0;
    }
    .banner-section .buy-gold .sell-gold-container h2,
    .banner-section .buy-gold .sip-gold-container h2 {
        color: #083863;
    }
}

@media (max-width: 767px) {
    .customer-say-section ul.owl-carousel {
        position: relative;
    }
    .customer-say-section ul.owl-carousel .owl-dots,
    .customer-say-section ul.owl-carousel .owl-nav {
        position: absolute;
    }
    .customer-say-section ul.owl-carousel .owl-nav {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: -2.5rem;
    }
    .customer-say-section ul.owl-carousel .owl-nav .owl-next {
        margin-left: 8rem;
    }
    .customer-say-section ul.owl-carousel .owl-dots {
        bottom: -2rem;
        left: 50%;
        -webkit-transform: translateX(-42%);
        transform: translateX(-42%);
    }
    .firstpostImg {
        width: 100%;
    }
    .m-t {
        margin-top: 0;
    }
    .image-container.about-img img {
        max-width: 250px;
    }
    .about-us .banner .goal.about-t1 {
        margin: 10vh 0 15px;
        font-weight: 300;
        font-size: 28px;
    }
    .column-1.about-cs-header {
        margin-top: 0;
    }
    .banner-section .buy-gold .sell-gold-container button.primary-button,
    .banner-section .buy-gold .sip-gold-container button.primary-button {
        margin-bottom: 30px;
    }
    .js-content {
        justify-content: center !important;
        padding: 1.1rem 0 !important;
    }
    .js-content:first-child {
        margin-top: 12vh;
    }
    .banner-section .buy-gold .gold-calculator form input {
        font-size: 22px;
    }
    .measurement_rupee::after {
        font-size: 20px;
        top: -38px;
    }
    .banner-section .buy-gold .gold-calculator form input::-webkit-input-placeholder {
        font-size: 22px;
    }
    .banner-section .buy-gold .gold-calculator form input:-ms-input-placeholder {
        font-size: 22px;
    }
    .banner-section .buy-gold .gold-calculator form input::-ms-input-placeholder {
        font-size: 22px;
    }
    .banner-section .buy-gold .gold-calculator form input::placeholder {
        font-size: 22px;
    }
    .post-gm {
        font-size: 22px;
        top: 23%;
        left: 39%;
    }
    .customerName input[type="text"]:first-child {
        margin-right: 4%;
    }
    .otp-input label,
    .resend-link,
    .checkbox_fld span,
    button#email__otp__1 {
        font-size: 14px !important;
    }
    .resend-link,
    #para,
    .timer-box {
        font-size: 14px !important;
    }
    .whtsapp-logo {
        margin-top: 3%;
    }
    .switch {
        top: 31%;
    }
    .delivery-container-1 h1 {
        font: 800 26px/40.8px Sarabun;
        color: #083863;
        margin-bottom: 16%;
    }
    .delivery-container-1 .sub-1 {
        font: 500 12px/15.6px Sarabun;
    }
}


@media only screen and (min-width: 568px) and (max-width: 767px) and (orientation: landscape) {
    .input-group-append {
        padding-top: 3%;
    }
    .measurement_rupee::after {
        top: -40px;
    }
}

.customer-say-section ul li .video {
    margin-bottom: 18px;
    position: relative;
}

.customer-say-section ul li .video .play-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.gold-radio-container {
    display: flex;
    justify-content: space-between;
}

.timer-div {
    margin-bottom: 3rem !important;
}

.customer-say-section ul li .video .play-button p {
    margin: 0 0 0 7px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.customer-say-section ul li .content {
    margin-top: 2.4rem;
    padding: 27px 0;
    /* background: url(../images/pages/home-page-new/quote.svg); */
    background-repeat: no-repeat;
}

.customer-say-section ul li .content p {
    color: #666;
    font-size: 15px;
}

.customer-say-section ul li .content .name {
    color: #222;
    font-size: 14px;
    font-weight: 500;
}

.storage-partner-mobile {
    margin-top: 9.6rem;
}

.storage-partner-mobile h1 {
    color: #083863;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.storage-partner-mobile .partner-images {
    margin-top: 3.6rem;
    margin-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.storage-partner-mobile p {
    margin-top: 2.6px;
    color: #666;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

@media (min-width: 992px) {
    .storage-partner-mobile {
        display: none;
    }
}

.homepage {
    background: url(../images/header-slant-bg-desktop.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

@media (min-width: 992px) {
    .homepage {
        /* background: url(../images/pages/home-page-new/header-slant-bg-desktop.svg); */
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.wallets .wallets-section-3 {
    /* background: url(../images/global/background-1.svg); */
    background-repeat: no-repeat;
    background-position: top -50% right -150px;
    position: relative;
    padding: 9rem 16.91%;
}

@media (max-width: 768px) {
    .wallets .wallets-section-3 {
        padding: 9rem 12%;
    }
}

@media (max-width: 480px) {
    .wallets .wallets-section-3 {
        padding: 9rem 7%;
    }
}

.wallets .wallets-section-3 .row {
    margin-top: 7.2rem;
}

.wallets .wallets-section-3 .row .col {
    text-align: center;
}

@media (max-width: 768px) {
    .wallets .wallets-section-3 .row .col {
        margin-bottom: 2rem;
    }
}

.wallets .wallets-section-3 .row .col h3 {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-top: 4.2rem;
}

.wallets .wallets-section-3 .row .col p {
    font-size: 15px;
    color: #666;
    margin-top: 1.8rem;
}

.wallets .wallets-section-3 .star-gold {
    position: absolute;
    top: 16rem;
    left: 14rem;
    -webkit-animation-name: floating;
    animation-name: floating;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@media (max-width: 480px) {
    .wallets .wallets-section-3 .star-gold {
        display: none;
    }
}

.wallets .wallets-section-3 .star-turquoise {
    position: absolute;
    top: 39rem;
    right: 7.8rem;
    -webkit-animation-name: floating;
    animation-name: floating;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@media (max-width: 480px) {
    .wallets .wallets-section-3 .star-turquoise {
        display: none;
    }
}

@keyframes floating {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    65% {
        -webkit-transform: translate(0, -20px);
        transform: translate(0, -20px);
    }
    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.wallets .wallets-section-5 {
    position: relative;
    padding-top: 5rem;
    padding-bottom: 0;
}

.wallets .wallets-section-5 .star-gold {
    position: absolute;
    top: 84rem;
    right: 10.7rem;
}

@media (max-width: 480px) {
    .wallets .wallets-section-5 .star-gold {
        display: none;
    }
}

.wallets .wallets-section-5 .star-turquoise {
    position: absolute;
    top: 100rem;
    left: 8.2rem;
}

@media (max-width: 480px) {
    .wallets .wallets-section-5 .star-turquoise {
        display: none;
    }
}

.wallets .wallets-section-8 {
    padding-top: 15.2rem;
}

.corporate-gifting .corporate-gifting-section-8 {
    margin-top: 15.2rem;
}

.customers .customers-section-4 {
    padding-bottom: 0;
}

.customers .row-3 .column-1 {
    padding-right: 10rem;
}

@media (max-width: 1000px) {
    .customers .row-3 .column-1 {
        padding-right: none;
    }
}

.customers #video-modal {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem;
}

.customers #video-modal .modal-dialog {
    width: 100%;
    height: 80%;
    margin: auto 0;
    padding: 0;
    max-width: none;
}

.customers #video-modal .modal-dialog .modal-content {
    height: auto;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    border-radius: 0;
    background: #000;
    padding: 1rem;
}

.customers #video-modal .modal-dialog .modal-content .modal-body {
    height: 100%;
    width: 100%;
}

.customers #video-modal .modal-dialog .modal-content .modal-body iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.p-20 {
    padding: 20px !important;
}

.customers #video-modal.show {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.about-us {
    background: url('https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/header-slant-bg-desktop.svg');
    background-repeat: no-repeat;
    background-size: 100%;
}

@media (min-width: 992px) {
    .about-us {
        /* background: url(../images/pages/home-page-new/header-slant-bg-desktop.svg); */
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.about-us .banner {
    padding-bottom: 120px;
}

@media (min-width: 992px) {
    .about-us .banner {
        padding-right: 18rem;
        padding-left: 15rem;
    }
}

@media (min-width: 992px) {
    .about-us .banner .column-1 {
        margin-top: 12.6rem;
    }
}

@media (min-width: 992px) {
    .about-us .banner .column-2 {
        margin-top: 9.6rem;
    }
}

@media (min-width: 992px) {
    .about-us .banner {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        height: 100vh;
    }
}

.about-us .banner .image-container {
    padding-top: 28px;
    text-align: center;
}

@media (min-width: 992px) {
    .about-us .banner .image-container {
        padding-top: 0;
    }
}

.about-us .banner p {
    font-weight: 400;
    font-size: 16px;
    color: #083863;
    line-height: 1.3;
}

.about-us .banner .goal {
    margin: 36px 0;
    font-weight: 300;
    font-size: 30px;
}

.about-us .banner .primary-button {
    display: none;
}

@media (min-width: 992px) {
    .about-us .banner .primary-button {
        display: block;
        margin-top: 4.8rem;
    }
}

.about-us .about-us-partners-section {
    margin-top: 12rem;
}

@media (min-width: 992px) {
    .about-us .about-us-partners-section {
        padding-left: 18.2rem;
        padding-right: 18.2rem;
        margin-top: 4rem;
    }
}

.about-us .about-us-partners-section h1 {
    font-weight: 800;
    font-size: 30px;
    line-height: 1.25;
    color: #083863;
}

@media (min-width: 992px) {
    .about-us .about-us-partners-section h1 {
        text-align: center;
    }
}

.about-us .about-us-partners-section .subtitle {
    margin-top: 4.2rem;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #083863;
}

@media (min-width: 992px) {
    .about-us .about-us-partners-section .subtitle {
        margin-top: 7.2rem;
        text-align: center;
    }
}

.about-us .about-us-partners-section .grid-1 .image-container,
.about-us .about-us-partners-section .grid-2 .image-container {
    margin-bottom: 3.2rem;
}

@media (min-width: 992px) {
    .about-us .about-us-partners-section .grid-1 .image-container,
    .about-us .about-us-partners-section .grid-2 .image-container {
        margin-bottom: 5.4rem;
    }
}

.about-us .about-us-partners-section .grid-1 .spacer,
.about-us .about-us-partners-section .grid-2 .spacer {
    margin-top: 0;
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .about-us .team-work-with-us-container {
        margin-top: 12rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding-left: 23rem;
        padding-right: 23rem;
    }
}

.about-us .news {
    margin-top: 3.8rem;
    margin-bottom: 11rem;
}

@media (min-width: 992px) {
    .about-us .news {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }
}

@media (min-width: 992px) {
    .about-us .news h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
    }
}

.jewellers .hero-section .owl-nav {
    min-width: 9.7rem;
}

.info-page .content-container {
    margin-top: 2.4rem;
    padding: 0 16.9%;
}

@media (max-width: 768px) {
    .info-page .content-container {
        padding: 0 12%;
    }
}

@media (max-width: 480px) {
    .info-page .content-container {
        padding: 0 7%;
    }
}

.info-page .content-container .title-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.info-page .content-container .title-container .title {
    font-size: 36px;
    font-weight: 600;
}

.info-page .content-container .title-container .divider {
    margin-top: 0.7rem;
    display: block;
    width: 60px;
    height: 4px;
    border-radius: 4px;
    background: #decc62;
}

.info-page .content-container .information-container {
    margin-top: 4.8rem;
    margin-bottom: 8rem;
}

.info-page .content-container .information-container .subheading {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 2.4rem;
}

.info-page .content-container .information-container p {
    margin-bottom: 3.5rem;
    font-size: 16px;
}

.info-page .content-container .information-container p span {
    font-weight: 600;
}

.info-page .content-container .information-container ul {
    padding-left: 4rem;
    list-style-type: disc;
    margin-bottom: 3.5rem;
}

.info-page .content-container .information-container ul li {
    font-size: 16px;
}

.info-page .content-container .table {
    margin-bottom: 4.2rem;
}

.jewellery-exchange .jewellery-exchange-section-1 {
    height: calc(100vh - 65px);
    padding: 0 16%;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-1 {
        height: auto;
    }
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-1 {
        padding: 0 7%;
    }
}

@media (max-width: 480px) {
    .jewellery-exchange .jewellery-exchange-section-1 {
        padding: 0 3%;
    }
}

.jewellery-exchange .jewellery-exchange-section-1 .column-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 4rem;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-1 .column-1 {
        margin-top: 2rem;
    }
}

.jewellery-exchange .jewellery-exchange-section-1 .column-1 .title {
    font-size: 30px;
    font-weight: 600;
    color: #333;
}

.jewellery-exchange .jewellery-exchange-section-1 .column-1 .content {
    margin-top: 3rem;
    font-size: 18px;
    color: #333;
}

.jewellery-exchange .jewellery-exchange-section-1 .column-1 .green-link {
    margin-top: 4.2rem;
}

.jewellery-exchange .jewellery-exchange-section-1 .column-2 {
    position: relative;
    /* background: url(../images/pages/jewellery-exchange/decor-vertical-1.svg); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 55%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-1 .column-2 {
        margin-top: 4rem;
    }
}

.jewellery-exchange .jewellery-exchange-section-1 .column-2 .image-container img {
    border-radius: 5px;
}

.jewellery-exchange .jewellery-exchange-section-1 .column-2 .play-button {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.jewellery-exchange .jewellery-exchange-section-2 {
    position: relative;
    padding: 0 19%;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-2 {
        padding: 0 7%;
        margin-top: 4rem;
    }
}

@media (max-width: 480px) {
    .jewellery-exchange .jewellery-exchange-section-2 {
        padding: 0 3%;
    }
}

.jewellery-exchange .jewellery-exchange-section-2 .heading {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #333;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-1 .green-link {
    margin-top: 2.1rem;
}

@media (max-width: 1000px) {
    .jewellery-exchange .jewellery-exchange-section-2 .column-1 .green-link {
        margin-top: -0.9rem;
    }
}

.jewellery-exchange .jewellery-exchange-section-2 .column-2 {
    margin-top: 3.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-2 .green-link {
    margin-top: 2rem;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-3,
.jewellery-exchange .jewellery-exchange-section-2 .column-4 {
    margin-top: 9.6rem;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-2 .column-3,
    .jewellery-exchange .jewellery-exchange-section-2 .column-4 {
        margin-top: 4rem;
    }
}

.jewellery-exchange .jewellery-exchange-section-2 .column-3 .image-container img,
.jewellery-exchange .jewellery-exchange-section-2 .column-4 .image-container img {
    border-radius: 5px;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-3 .text,
.jewellery-exchange .jewellery-exchange-section-2 .column-4 .text {
    margin-top: 1.6rem;
    font-size: 15px;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-3 .gold-link,
.jewellery-exchange .jewellery-exchange-section-2 .column-4 .gold-link {
    margin-top: 1.6rem;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-5 {
    position: relative;
    margin-top: 4.2rem;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-5 .image-container {
    border-radius: 5px;
    width: 100%;
    height: 30rem;
    background: url(https://images.unsplash.com/photo-1517097473408-c0d7983cb95c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80);
    background-size: cover;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-5 .image-container .content-container {
    position: absolute;
    top: 4rem;
    left: 4rem;
}

.jewellery-exchange .jewellery-exchange-section-2 .column-5 .image-container .content-container .content {
    font-size: 15px;
    color: #fff;
    width: 34rem;
}

.jewellery-exchange .jewellery-exchange-section-2 .partners-bg-1 {
    position: absolute;
    right: 0;
    top: 20rem;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-2 .partners-bg-1 {
        display: none;
    }
}

.jewellery-exchange .jewellery-exchange-section-2 .partners-bg-2 {
    position: absolute;
    left: 7.2rem;
    top: 0;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-2 .partners-bg-2 {
        display: none;
    }
}

.jewellery-exchange .jewellery-exchange-section-2 .partners-bg-3 {
    position: absolute;
    right: 7.2rem;
    bottom: 6rem;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-2 .partners-bg-3 {
        display: none;
    }
}

.jewellery-exchange .jewellery-exchange-section-3 {
    margin-top: 15rem;
    padding: 0 7%;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-3 {
        margin-top: 8rem;
    }
}

@media (max-width: 480px) {
    .jewellery-exchange .jewellery-exchange-section-3 {
        padding: 0 3%;
    }
}

.jewellery-exchange .jewellery-exchange-section-3 .row {
    margin-top: 6rem;
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-3 .col {
        margin-top: 4rem;
    }
}

.jewellery-exchange .jewellery-exchange-section-3 .col .image-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.jewellery-exchange .jewellery-exchange-section-3 .col .image-container span {
    position: absolute;
    font-size: 42px;
    color: #333;
    font-weight: 100;
    opacity: 0.5;
    top: -2rem;
    left: 15%;
}

@media (max-width: 900px) {
    .jewellery-exchange .jewellery-exchange-section-3 .col .image-container span {
        left: 10%;
    }
}

@media (max-width: 768px) {
    .jewellery-exchange .jewellery-exchange-section-3 .col .image-container span {
        left: 35%;
    }
}

@media (max-width: 480px) {
    .jewellery-exchange .jewellery-exchange-section-3 .col .image-container span {
        left: 25%;
    }
}

.jewellery-exchange .jewellery-exchange-section-3 .col p {
    margin-top: 4.6rem;
    font-size: 15px;
    color: #333;
}

.jewellery-exchange .jewellery-exchange-section-4 {
    margin-top: 7.2rem;
    margin-bottom: 9.6rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.jewellery-exchange .jewellery-exchange-section-4 .heading {
    font-size: 24px;
    color: #333;
}

.jewellery-exchange .jewellery-exchange-section-4 .image-container {
    margin-top: 7.2rem;
}

.jewellery-partner .jewellery-partner-section-1 {
    padding: 0 14%;
    height: calc(100vh - 65px);
    background: #daa520;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-1 {
        padding: 0 7%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .jewellery-partner .jewellery-partner-section-1 {
        padding: 0 3%;
    }
}

.jewellery-partner .jewellery-partner-section-1 .content-container .content {
    font-size: 30px;
    color: #fff;
}

@media (max-width: 480px) {
    .jewellery-partner .jewellery-partner-section-1 .content-container .content {
        font-size: 24px;
    }
}

.jewellery-partner .jewellery-partner-section-1 .content-container .btn {
    margin-top: 5.4rem;
    background: #fff;
    padding: 1.6rem 3.5rem;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-1 .content-container {
        margin: 7rem 0;
    }
}

.jewellery-partner .jewellery-partner-section-2 {
    height: calc(100vh - 65px);
    padding: 0 16%;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-2 {
        height: auto;
        margin-top: 2rem;
    }
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-2 {
        padding: 0 7%;
    }
}

@media (max-width: 480px) {
    .jewellery-partner .jewellery-partner-section-2 {
        padding: 0 3%;
    }
}

.jewellery-partner .jewellery-partner-section-2 .column-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 4rem;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-2 .column-1 {
        margin-top: 2rem;
    }
}

.jewellery-partner .jewellery-partner-section-2 .column-1 .title {
    font-size: 30px;
    font-weight: 600;
    color: #333;
}

.jewellery-partner .jewellery-partner-section-2 .column-1 .content {
    margin-top: 3rem;
    font-size: 18px;
    color: #333;
}

.jewellery-partner .jewellery-partner-section-2 .column-1 .green-link {
    margin-top: 4.2rem;
}

.jewellery-partner .jewellery-partner-section-2 .column-2 {
    position: relative;
    /* background: url(../images/pages/jewellery-exchange/decor-vertical-1.svg); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 55%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-2 .column-2 {
        margin-top: 4rem;
    }
}

.jewellery-partner .jewellery-partner-section-2 .column-2 .image-container img {
    border-radius: 5px;
}

.jewellery-partner .jewellery-partner-section-2 .column-2 .play-button {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.jewellery-partner .jewellery-partner-section-3 {
    margin-top: 10.6rem;
    padding: 0 19%;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-3 {
        padding: 0 7%;
    }
}

@media (max-width: 480px) {
    .jewellery-partner .jewellery-partner-section-3 {
        padding: 0 3%;
    }
}

.jewellery-partner .jewellery-partner-section-3 .col .image-container img {
    border-radius: 5px;
}

.jewellery-partner .jewellery-partner-section-3 .col .text {
    margin-top: 1.6rem;
    font-size: 15px;
    color: #333;
}

.jewellery-partner .jewellery-partner-section-3 .col .gold-link {
    margin-top: 2rem;
}

.jewellery-partner .jewellery-partner-section-4 {
    margin-top: 15rem;
    padding: 0 7%;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-4 {
        margin-top: 8rem;
    }
}

@media (max-width: 480px) {
    .jewellery-partner .jewellery-partner-section-4 {
        padding: 0 3%;
    }
}

.jewellery-partner .jewellery-partner-section-4 .row {
    margin-top: 6rem;
}

.jewellery-partner .jewellery-partner-section-4 .row .button-container {
    text-align: center;
    margin-top: 10rem;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-4 .col {
        margin-top: 4rem;
    }
}

.jewellery-partner .jewellery-partner-section-4 .col .image-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.jewellery-partner .jewellery-partner-section-4 .col .image-container span {
    position: absolute;
    font-size: 42px;
    color: #333;
    font-weight: 100;
    opacity: 0.5;
    top: -2rem;
    left: 15%;
}

@media (max-width: 900px) {
    .jewellery-partner .jewellery-partner-section-4 .col .image-container span {
        left: 10%;
    }
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-4 .col .image-container span {
        left: 35%;
    }
}

@media (max-width: 480px) {
    .jewellery-partner .jewellery-partner-section-4 .col .image-container span {
        left: 25%;
    }
}

.jewellery-partner .jewellery-partner-section-4 .col p {
    margin-top: 4.6rem;
    font-size: 15px;
    color: #333;
}

.jewellery-partner .jewellery-partner-section-5 {
    position: relative;
    margin-top: 9.6rem;
    padding: 0 19%;
}

.jewellery-partner .jewellery-partner-section-5 .partner-bg-1 {
    position: absolute;
    top: 5rem;
    left: 7rem;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-5 .partner-bg-1 {
        display: none;
    }
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-5 {
        padding: 0 7%;
    }
}

@media (max-width: 480px) {
    .jewellery-partner .jewellery-partner-section-5 {
        padding: 0 3%;
    }
}

.jewellery-partner .jewellery-partner-section-5 .card-outer {
    -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
    padding: 0 1.8rem;
    border-radius: 5px;
    width: 100%;
}

.jewellery-partner .jewellery-partner-section-5 .card-outer .heading {
    font-size: 18px;
    font-weight: 600;
    color: #00bbb4;
    margin: 2.4rem 0;
}

.jewellery-partner .jewellery-partner-section-5 .card-outer ul li .btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.jewellery-partner .jewellery-partner-section-5 .card-outer ul li a {
    font-size: 15px;
    color: #333;
    padding: 1.8rem 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.jewellery-partner .jewellery-partner-section-5 .card-outer ul li a img {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.jewellery-partner .jewellery-partner-section-5 .card-outer ul li .collapsed img {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
}

.jewellery-partner .jewellery-partner-section-5 .card-outer ul li:not(:last-child) {
    border-bottom: 0.5px solid #ddd;
}

.jewellery-partner .jewellery-partner-section-5 .card-outer ul li .faq-content {
    font-size: 14px;
    color: #333;
    margin-bottom: 1.6rem;
}

.jewellery-partner .jewellery-partner-section-6 {
    position: relative;
    margin-top: 9.6rem;
    padding: 0 19%;
}

.jewellery-partner .jewellery-partner-section-6 .partner-bg-2 {
    position: absolute;
    top: 0;
    right: 0;
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-6 .partner-bg-2 {
        display: none;
    }
}

@media (max-width: 768px) {
    .jewellery-partner .jewellery-partner-section-6 {
        padding: 0 7%;
    }
}

@media (max-width: 480px) {
    .jewellery-partner .jewellery-partner-section-6 {
        padding: 0 7%;
    }
}

.jewellery-partner .jewellery-partner-section-6 .heading {
    font-size: 30px;
    color: #333;
    text-align: center;
    width: 100%;
}

.jewellery-partner .jewellery-partner-section-6 ol {
    margin-top: 4.2rem;
    list-style-type: decimal;
}

.jewellery-partner .jewellery-partner-section-6 ol li {
    font-size: 15px;
    color: #333;
    margin-bottom: 0.4rem;
}

.jewellery-partner .jewellery-partner-section-6 .image-container {
    margin-top: 7.2rem;
    margin-bottom: 9.6rem;
    width: 100%;
    text-align: center;
}

.raksha-bandhan-gift .section-1 {
    padding: 0 10%;
    min-height: 100vh;
}

body.locked {
    overflow: hidden;
}

[data-title="homepage-thailand"] footer {
    margin-top: -5.5rem;
    position: absolute;
    z-index: 10;
}

[data-title="about-thailand"] main,
[data-title="working-thailand"] main {
    /* background-image: url(../images/global/bg-mandala.svg); */
}

@media (max-width: 768px) {
    [data-title="about-thailand"] main,
    [data-title="working-thailand"] main {
        background-image: none;
    }
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-hero,
    .homepage-thailand .homepage-section-4 {
        margin-left: 0;
        margin-right: 0;
    }
}

.navbar .mobile-nav-container .country-container {
    margin-top: 1rem;
}

.navbar .mobile-nav-container .country-container img {
    width: 6rem;
}

.navbar .mobile-nav-container .country-container img:not(:first-child) {
    margin-left: 1.8rem;
}

.navbar .country-toggle-container {
    margin-left: 3rem;
}

.navbar .country-toggle-container:hover {
    cursor: pointer;
}

.navbar .country-toggle-container .country-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navbar .country-toggle-container .country-toggle img {
    width: 3.6rem;
}

.navbar .country-toggle-container .country-toggle img:last-child {
    margin-left: 1rem;
    width: 1rem;
}

.navbar .country-toggle-container .dropdown-menu {
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px 0;
    border: 0;
    border-radius: 0.5rem;
    padding: 1.2rem;
    text-align: center;
    min-width: 4.8rem;
}

.navbar .country-toggle-container .dropdown-menu.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.navbar .country-toggle-container .dropdown-menu img {
    display: block;
    width: 3.6rem;
    cursor: pointer;
}

.navbar .country-toggle-container .dropdown-menu img:not(:first-child) {
    margin-top: 1.2rem;
}

@media (min-width: 769px) {
    .navbar.navbar-scrolled {
        padding: 0.8rem 8rem !important;
    }
}

.navbar button {
    background-color: #00bbb4 !important;
    border: 1px solid #00bbb4 !important;
    -webkit-box-shadow: 0 10px 30px -12px rgba(0, 187, 180, 0.6) !important;
    box-shadow: 0 10px 30px -12px rgba(0, 187, 180, 0.6) !important;
}

.navbar button:hover {
    -webkit-box-shadow: 0 10px 30px -12px #00bbb4 !important;
    box-shadow: 0 10px 30px -12px #00bbb4 !important;
}

.about-thailand p,
.homepage-thailand p,
.working-thailand p {
    line-height: 1.5 !important;
}

.about-thailand .green-link,
.homepage-thailand .green-link,
.working-thailand .green-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.about-thailand .bg-mandala,
.homepage-thailand .bg-mandala,
.working-thailand .bg-mandala {
    position: absolute;
    top: -5rem;
    left: -12rem;
}

@media (max-width: 768px) {
    .about-thailand .bg-mandala,
    .homepage-thailand .bg-mandala,
    .working-thailand .bg-mandala {
        display: none;
    }
}

.homepage-thailand {
    background: 0;
}

.homepage-thailand p {
    color: #666;
    font-size: 1.6rem;
}

.homepage-thailand h2 {
    font-size: 3rem;
    font-weight: 600;
}

.homepage-thailand .homepage-hero {
    padding-top: 2rem;
    position: relative;
}

@media (min-width: 769px) {
    .homepage-thailand .homepage-hero {
        max-height: 100vh;
    }
}

.homepage-thailand .homepage-hero .image-content-rows-container {
    margin-top: 0;
}

.homepage-thailand .homepage-hero .image-content-rows-container .image-content-row {
    padding: 0 5rem 0 18rem;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-hero .image-content-rows-container .image-content-row {
        padding: 0 2rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.homepage-thailand .homepage-hero .image-content-rows-container .image-content-row .column-1 {
    -ms-flex-preferred-size: 55%;
    flex-basis: 55%;
}

.homepage-thailand .homepage-hero .image-content-rows-container .image-content-row .column-2 {
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
}

.homepage-thailand .homepage-hero .image-content-rows-container .image-content-row .column-1,
.homepage-thailand .homepage-hero .image-content-rows-container .image-content-row .column-2 {
    padding: 0;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-hero .image-content-rows-container .image-content-row .column-1,
    .homepage-thailand .homepage-hero .image-content-rows-container .image-content-row .column-2 {
        -ms-flex-preferred-size: initial;
        flex-basis: initial;
    }
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-hero {
        padding-top: 1rem;
        padding-bottom: 5rem;
    }
    .homepage-thailand .homepage-hero .image-content-rows-container {
        margin-top: 1.6rem;
    }
    .homepage-thailand .homepage-hero .image-content-rows-container .column-1 {
        margin-bottom: 4rem;
    }
}

.homepage-thailand .homepage-hero h3 {
    font-size: 3.6rem !important;
}

.homepage-thailand .homepage-hero .hero-cta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 2rem;
}

.homepage-thailand .homepage-hero .bg-diamond {
    position: absolute;
    bottom: 7rem;
    left: 4%;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-hero .bg-diamond {
        display: none;
    }
}

.homepage-thailand .hero {
    height: 59rem;
}

.homepage-thailand .hero .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 8rem;
}

.homepage-thailand .hero .container .column-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
}

.homepage-thailand .hero .container .column-1 .background-image {
    position: absolute;
    left: -4rem;
    top: 4.5rem;
}

.homepage-thailand .hero .container .column-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
    top: 6.5rem;
}

.homepage-thailand .hero .container .content-container {
    margin-top: 5rem;
}

.homepage-thailand .hero .container .content-container h1 {
    font-size: 4.2rem;
    line-height: 1.5;
    font-weight: 600;
    padding-bottom: 1.6rem;
}

.homepage-thailand .hero .container .content-container p {
    font-size: 1.8rem;
    color: #666;
}

.homepage-thailand .hero .container .blockchain-image {
    position: absolute;
    right: 3rem;
    top: 0;
}

.homepage-thailand .hero .container .blockchain-image img {
    height: 80rem;
}

.homepage-thailand .section-heading {
    color: #222;
    text-align: left;
}

.homepage-thailand .section-heading .section-divider {
    margin-top: 1.8rem;
    width: 6rem;
    height: 0.4rem;
    border: 2px solid #decc62;
}

.homepage-thailand .secure-platform {
    -webkit-clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 88%);
    background: #f7f7f7;
    padding: 20rem 18rem;
}

.homepage-thailand .secure-platform .section-heading {
    padding: 0;
}

.homepage-thailand .secure-platform .col-md-7 {
    padding-left: 3.6rem;
}

.homepage-thailand .secure-platform p.big {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.25 !important;
    margin-bottom: 2.4rem;
    color: #333;
}

@media (max-width: 768px) {
    .homepage-thailand .secure-platform {
        padding: 9.6rem 2.4rem;
        -webkit-clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 92%);
        clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 92%);
    }
    .homepage-thailand .secure-platform .section-heading {
        padding: 0 1.5rem 2.4rem;
    }
}

.homepage-thailand .benefits {
    margin-top: 4.2rem;
}

.homepage-thailand .benefits .section-heading {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.homepage-thailand .benefits .pwa-vs-api {
    padding: 8rem 20rem;
}

.homepage-thailand .benefits .pwa-vs-api h3 {
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 3.6rem;
}

.homepage-thailand .benefits .pwa-vs-api .for-partners {
    padding-left: 4.2rem;
}

.homepage-thailand .benefits .pwa-vs-api .benefits-list {
    padding-right: 1.6rem;
}

.homepage-thailand .benefits .pwa-vs-api .benefits-list .benefit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 3.6rem;
}

.homepage-thailand .benefits .pwa-vs-api .benefits-list .benefit .benefit-icon {
    margin-right: 1.8rem;
}

.homepage-thailand .benefits .pwa-vs-api .benefits-list .benefit .benefit-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.homepage-thailand .benefits .pwa-vs-api .benefits-list .benefit .benefit-content .benefit-heading {
    font-size: 1.8rem;
    font-weight: 500;
}

@media (max-width: 768px) {
    .homepage-thailand .benefits {
        margin-top: 4.2rem;
    }
    .homepage-thailand .benefits .pwa-vs-api {
        padding: 4.2rem 3rem;
    }
    .homepage-thailand .benefits .pwa-vs-api .for-partners {
        padding: 1.5rem 1.5rem;
    }
}

.homepage-thailand .customer-journeys {
    text-align: center;
    margin-top: 4.2rem;
}

.homepage-thailand .customer-journeys .section-heading {
    margin-bottom: 7.2rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.homepage-thailand .customer-journeys .image-container {
    margin-bottom: 2.4rem;
}

.homepage-thailand .customer-journeys .subtext {
    font-size: 1.2rem;
    color: #999;
}

.homepage-thailand .features {
    margin-top: -2.5rem;
}

@media (max-width: 768px) {
    .homepage-thailand .features {
        margin-top: 0;
    }
}

.homepage-thailand .features.grid-section {
    position: relative;
}

.homepage-thailand .features.grid-section .bg-diamond {
    position: absolute;
    right: 5rem;
}

.homepage-thailand .features.grid-section p {
    max-width: 24.2rem;
    line-height: 21px;
}

.homepage-thailand .features.grid-section .grid-section__item {
    margin-bottom: 4rem;
}

.homepage-thailand .tabs-section .tab-pane {
    margin: 0 -3rem;
}

.homepage-thailand .homepage-section-4 {
    position: relative;
    padding-bottom: 0;
}

.homepage-thailand .homepage-section-4 .section-heading {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
}

.homepage-thailand .homepage-section-4 .section-heading h2 {
    margin-bottom: 1.2rem;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 {
        padding-top: 5rem;
    }
}

.homepage-thailand .homepage-section-4 p {
    max-width: 70rem;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 p {
        max-width: initial;
    }
}

.homepage-thailand .homepage-section-4 .image-content-rows-container {
    margin: 8rem auto 0;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 .image-content-rows-container {
        margin: 5rem auto 0;
    }
    .homepage-thailand .homepage-section-4 .image-content-rows-container h3,
    .homepage-thailand .homepage-section-4 .image-content-rows-container p {
        text-align: center;
    }
}

.homepage-thailand .homepage-section-4 .bg-top {
    position: absolute;
    right: 0;
    top: -10rem;
}

@media (max-width: 480px) {
    .homepage-thailand .homepage-section-4 .bg-top {
        display: none;
    }
}

.homepage-thailand .homepage-section-4 .row-1 .bg-mandala-left {
    position: absolute;
    left: -16rem;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 .row-1 .bg-mandala-left {
        display: none;
    }
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 .row-1 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    .homepage-thailand .homepage-section-4 .row-1 .column-1,
    .homepage-thailand .homepage-section-4 .row-1 .column-2 {
        padding: 0;
    }
}

.homepage-thailand .homepage-section-4 .row-2 .bg-mandala-right {
    position: absolute;
    right: -10rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 .row-2 .bg-mandala-right {
        display: none;
    }
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 .row-2 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .homepage-thailand .homepage-section-4 .row-2 .column-1,
    .homepage-thailand .homepage-section-4 .row-2 .column-2 {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 .row-3 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    .homepage-thailand .homepage-section-4 .row-3 .column-1,
    .homepage-thailand .homepage-section-4 .row-3 .column-2 {
        padding: 0;
    }
}

.homepage-thailand .homepage-section-4 .row-4 {
    padding: 4.8rem 0 0;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 .row-4 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .homepage-thailand .homepage-section-4 .row-4 .column-1,
    .homepage-thailand .homepage-section-4 .row-4 .column-2 {
        padding: 0;
    }
}

.homepage-thailand .homepage-section-4 .column-1 {
    padding-left: 0 !important;
}

.homepage-thailand .homepage-section-4 .column-1 .content-container {
    width: 34rem;
}

.homepage-thailand .homepage-section-4 .column-2 {
    padding-right: 0 !important;
}

.homepage-thailand .homepage-section-4 .column-2 .content-container {
    width: 34rem;
}

@media (max-width: 768px) {
    .homepage-thailand .homepage-section-4 .column-1 .content-container,
    .homepage-thailand .homepage-section-4 .column-2 .content-container {
        width: initial;
    }
}

@media (max-width: 480px) {
    .homepage-thailand .image-content-section .bg-left,
    .homepage-thailand .image-content-section .bg-right {
        background: 0;
    }
}

.homepage-thailand .partners-section {
    /* background: url(../images/components/thailand-website/home/bg-partner-slanted.svg) center/cover no-repeat; */
}

@media (max-width: 480px) {
    .homepage-thailand .partners-section {
        background: #f7f7f7;
    }
}

.homepage-thailand .about-us-section {
    background-color: #f7f7f7;
    margin-top: 10rem;
}

@media (max-width: 768px) {
    .homepage-thailand .about-us-section {
        padding: 0 7%;
        margin-top: 2rem;
    }
}

.homepage-thailand .about-us-section .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 25rem;
    margin-top: -5.5rem;
}

@media (max-width: 480px) {
    .homepage-thailand .about-us-section .content-wrapper {
        padding: 4rem 1rem;
        margin-top: 0;
    }
}

.homepage-thailand .about-us-section .background-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.homepage-thailand .about-us-section .background-image img {
    margin-top: -5rem;
}

@media (max-width: 480px) {
    .homepage-thailand .about-us-section .background-image {
        display: none;
    }
}

.homepage-thailand .about-us-section .heading {
    font-size: 36px;
    font-weight: 700;
    color: #333;
    line-height: 1.5;
}

.homepage-thailand .about-us-section .content {
    font-size: 15px;
    margin-top: 3.6rem;
    color: #666;
    max-width: 50rem;
}

.homepage-thailand .about-us-section .green-link {
    margin-top: 3.6rem;
}

.gsap-mobile {
    margin-bottom: 3rem;
}

.gsap-mobile img {
    width: 100%;
}

@media (min-width: 901px) {
    .gsap-mobile {
        display: none;
    }
}

.gsap-heading {
    text-align: center;
    max-width: 70rem;
    margin: 15rem auto 7.2rem;
}

@media (max-width: 768px) {
    .gsap-heading {
        margin: 0 auto;
    }
}

.gsap-heading h2 {
    font-size: 3.6rem;
    font-weight: 600;
}

.gsap-heading p {
    color: #666;
    font-size: 1.8rem;
    padding-top: 1.8rem;
}

.gsap {
    margin: 10rem 0 5rem;
}

@media (max-width: 900px) {
    .gsap {
        display: none;
    }
}

.gsap.animate #circle12 {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-out 0.5s;
    transition: opacity 0.3s ease-out 0.5s;
}

.gsap.animate #circle13,
.gsap.animate #circle23,
.gsap.animate #circle33 {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-out 750ms;
    transition: opacity 0.3s ease-out 750ms;
}

.gsap.animate #circle14,
.gsap.animate #circle34 {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-out 1.5s;
    transition: opacity 0.3s ease-out 1.5s;
}

.gsap.animate #circle35 {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-out 2.25s;
    transition: opacity 0.3s ease-out 2.25s;
}

.gsap .animation-container {
    margin-top: 10rem;
    max-width: 800px;
    width: 800px;
    height: 50rem;
    max-height: 50rem;
    display: -ms-grid;
    display: grid;
    /* -ms-grid-columns: 12rem (1fr) [3];
    grid-template-columns: 12rem repeat(3, 1fr);
    -ms-grid-rows: (1fr) [3];
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    font-size: 1.6rem;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-left: -3rem; */
}

.gsap .animation-container p {
    font-size: 14px;
    text-align: center;
}

.gsap .animation-container>div {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

.gsap .animation-container>div:not(:first-child) {
    opacity: 0;
}

.gsap .wrapper .circle {
    height: 9.6rem;
    width: 9.6rem;
    color: #f7f7f7;
    border-radius: 50%;
    font-size: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
}

.gsap .line.right.blue {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 4.8rem 0 0;
    left: -25%;
    /* background: url(../images/components/thailand-website/blue-arrow.png) top/auto no-repeat; */
}

.gsap .line.bottom {
    position: absolute;
    height: 20rem;
    width: 100%;
    top: -24rem;
    right: 4rem;
    /* background: url(../images/components/thailand-website/grey-arrow-bottom.png) right top/auto no-repeat; */
}

.gsap .line.two-sided-arrow {
    position: absolute;
    left: -55%;
    margin-top: 4rem;
    height: 100%;
    width: 100%;
    /* background: url(../images/components/thailand-website/double-arrow.png) right top/auto no-repeat; */
}

.gsap #circle11 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 1/1/2/2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.gsap #circle11 .circle {
    /* background-image: url(../images/components/thailand-website/buy-gold.png); */
}

.gsap #circle11 p {
    position: absolute;
    min-width: 11rem;
    margin-top: 1rem;
}

.gsap #circle12 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 1/2/2/3;
}

.gsap #circle12 .circle {
    /* background-image: url(../images/components/thailand-website/safegold.png); */
}

.gsap #circle13 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-area: 1/3/2/4;
}

.gsap #circle13 .circle {
    /* background-image: url(../images/components/thailand-website/classic-ausiris.png); */
}

.gsap #circle13 p {
    position: absolute;
    width: 100%;
    top: -4rem;
    left: 50%;
}

.gsap #circle14 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-area: 1/4/2/5;
}

.gsap #circle14 .circle {
    /* background-image: url(../images/components/thailand-website/tfex.png); */
}

.gsap #circle14 .line {
    margin: 4.8rem 2rem 0 1rem;
}

.gsap #circle23 {
    margin-top: 2rem;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-area: 2/3/3/4;
}

.gsap #circle23 .line {
    position: absolute;
    top: -9rem;
    right: -1rem;
    width: 100%;
    height: 100%;
    /* background: url(../images/components/thailand-website/grey-arrow-slant.png) left top/auto no-repeat; */
}

.gsap #circle23 p {
    position: absolute;
    right: 0;
    max-width: 11rem;
}

.gsap #circle23 .wrapper {
    margin-top: -3rem;
}

.gsap #circle23 .circle {
    /* background-image: url(../images/components/thailand-website/classic-ausiris.png); */
}

.gsap #circle33 {
    margin-top: 4rem;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 3/2/4/3;
}

.gsap #circle33 p {
    position: absolute;
    bottom: 20rem;
    right: 6.5rem;
    max-width: 10rem;
    width: 100%;
}

.gsap #circle33 .circle {
    /* background-image: url(../images/components/thailand-website/ausiris.png); */
}

.gsap #circle34 {
    margin-top: 4rem;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-area: 3/3/4/4;
}

.gsap #circle34 .right {
    position: absolute;
    left: 1rem;
    bottom: 0;
    width: calc(100% - 12rem);
    margin: 0 0 4.8rem 0;
}

.gsap #circle34 .circle {
    /* background-image: url(../images/components/thailand-website/locker.png); */
}

.gsap #circle35 {
    margin-top: 4rem;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-area: 3/4/4/5;
}

.gsap #circle35 .right {
    position: absolute;
    left: 1rem;
    bottom: 0;
    width: calc(100% - 12rem);
    margin: 0 0 4.8rem 0;
}

.gsap #circle35 .slanted-top {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 3rem;
    top: -9rem;
    /* background: url(../images/components/thailand-website/grey-arrow-slant.png) left top/auto no-repeat; */
}

.gsap #circle35 .line-left-para {
    position: absolute;
    width: 18rem;
    max-width: 18rem;
    right: -4rem;
}

.gsap #circle35 .line-top-para {
    position: absolute;
    top: -19rem;
    left: 20rem;
    width: 8rem;
}

.gsap #circle35 .circle {
    /* background-image: url(../images/components/thailand-website/tch.png); */
}

.working-thailand p {
    font-size: 1.6rem;
    color: #666;
}

.working-thailand .section-heading {
    color: #222;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.working-thailand .section-heading .section-divider {
    margin-top: 1.8rem;
    width: 6rem;
    height: 0.4rem;
    border: 2px solid #decc62;
}

.working-thailand .image-container {
    text-align: center;
}

.working-thailand .working-hero {
    padding: 0;
    height: calc(90vh - 7.5rem);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .working-thailand .working-hero {
        height: initial;
    }
}

.working-thailand .working-hero .image-content-rows-container {
    margin-top: 0;
}

.working-thailand .working-hero .image-content-rows-container .image-content-row {
    padding: 0 3rem 0 18rem;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media (max-width: 768px) {
    .working-thailand .working-hero .image-content-rows-container .image-content-row {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0 7%;
    }
}

.working-thailand .working-hero .image-content-rows-container .image-content-row .column-1 {
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
}

.working-thailand .working-hero .image-content-rows-container .image-content-row .column-1 .content-container {
    padding-right: 8.2rem;
}

.working-thailand .working-hero .image-content-rows-container .image-content-row .column-2 {
    -ms-flex-preferred-size: 55%;
    flex-basis: 55%;
}

.working-thailand .working-hero .image-content-rows-container .image-content-row .column-1,
.working-thailand .working-hero .image-content-rows-container .image-content-row .column-2 {
    padding: 0;
}

@media (max-width: 768px) {
    .working-thailand .working-hero .image-content-rows-container .image-content-row .column-1,
    .working-thailand .working-hero .image-content-rows-container .image-content-row .column-2 {
        -ms-flex-preferred-size: initial;
        flex-basis: initial;
    }
}

.working-thailand .working-hero .image-content-rows-container p {
    padding-bottom: 3.6rem;
}

@media (max-width: 768px) {
    .working-thailand .working-hero {
        padding-top: 1rem;
        padding-bottom: 5rem;
        -webkit-box-align: initial;
        -ms-flex-align: initial;
        align-items: initial;
        height: initial;
    }
    .working-thailand .working-hero .image-content-rows-container {
        margin-top: 4rem;
    }
    .working-thailand .working-hero .image-content-rows-container .column-1 {
        margin-bottom: 4rem;
    }
}

.working-thailand .working-hero h3 {
    font-size: 4.2rem !important;
}

.working-thailand .working-hero .hero-cta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 2rem;
}

.working-thailand .working-hero .hero-cta button {
    margin-right: 1.8rem;
}

.working-thailand .working-hero .hero-cta a,
.working-thailand .working-hero .hero-cta button {
    white-space: nowrap;
}

@media (max-width: 768px) {
    .working-thailand .working-hero .hero-cta {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-top: 1rem;
    }
    .working-thailand .working-hero .hero-cta button {
        margin-right: 0;
        margin-bottom: 2.4rem;
    }
}

.working-thailand .protecting-customers {
    padding: 0 0 12rem;
}

.working-thailand .protecting-customers .image-container {
    text-align: center;
}

.working-thailand .building-trust {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.working-thailand .building-trust .image-container {
    margin-top: 4.8rem;
}

.working-thailand .building-trust green-link {
    margin-top: 2.4rem;
}

.working-thailand .nodes {
    margin: 7.2rem 9.6rem 0;
}

.working-thailand .nodes h3 {
    font-size: 2rem;
    margin-bottom: 1.6rem;
}

.working-thailand .nodes .numbered-list .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.working-thailand .nodes .numbered-list .list-item img {
    margin-right: 1rem;
}

.working-thailand .nodes .numbered-list .list-item p {
    margin-top: 0.6rem;
}

.working-thailand .nodes .green-link {
    margin-top: 2.4rem;
}

.working-thailand .node-setup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.working-thailand .node-setup button {
    margin-bottom: 2.4rem;
}

.working-thailand .contact-section {
    background: 0;
}

@media (max-width: 768px) {
    .working-thailand .contact-section {
        padding-top: 5rem;
    }
    .working-thailand .contact-section .contact-section__title {
        padding: 0 2rem;
    }
}

.working-thailand .contact-section h4 {
    font-size: 2.2rem;
    margin: 2.4rem 0 0;
    color: #666;
    font-weight: 400;
}

.working-thailand .contact-section .contact-form .column-1 {
    padding-right: 8%;
}

.working-thailand .contact-section .contact-form .column-1 .contact-detail {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.working-thailand .contact-section .contact-form .column-1 .contact-detail .image-container {
    height: 4.6rem;
    width: 4.6rem !important;
}

.working-thailand .contact-section .contact-form .column-1 .contact-detail .contact-detail-text {
    font-size: 1.8rem;
}

.working-thailand .contact-section .contact-form .column-1 .contact-detail:not(:last-child) .image-container {
    position: relative;
}

.working-thailand .contact-section .contact-form .column-1 .contact-detail:not(:last-child) .image-container::after {
    content: "";
    /* background: url(../images/components/thailand-website/contact/arrow-down.png) center/cover no-repeat; */
    height: 17px;
    width: 6px;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media (max-width: 768px) {
    .working-thailand .contact-section .contact-form .column-1,
    .working-thailand .contact-section .contact-form .column-2 {
        padding: 0 1rem;
    }
}

.working-thailand .contact-section .thailand-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (max-width: 480px) {
    .working-thailand .contact-section .thailand-form {
        margin-top: 2rem;
    }
}

.working-thailand .contact-section .thailand-form label {
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
    color: #666;
}

.working-thailand .contact-section .thailand-form .form-input {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
}

@media (max-width: 768px) {
    .working-thailand .contact-section .thailand-form .form-input {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
}

.working-thailand .contact-section .thailand-form .form-textarea {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}

.about-thailand {
    /* background: url(../images/components/hero-sectio); */
    background-repeat: no-repeat;
    background-position: -80px 0;
}

.about-thailand .image-content-section {
    padding-top: 0;
}

.about-thailand .about-footer-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 12rem;
}

.about-thailand .about-section-1 {
    margin: 0 auto;
}

@media (max-width: 768px) {
    .about-thailand .about-section-1 {
        padding-bottom: 2rem;
    }
    .about-thailand .about-section-1 .image-content-rows-container {
        margin-top: 2rem;
    }
}

.about-thailand .about-section-1 .about-heading {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

@media (max-width: 480px) {
    .about-thailand .about-section-1 .about-heading {
        margin-bottom: 2.5rem;
    }
}

.about-thailand .about-section-1 .about-heading h2 {
    font-size: 4.2rem;
    margin-bottom: 4rem;
    font-weight: 600;
}

@media (max-width: 480px) {
    .about-thailand .about-section-1 .about-heading h2 {
        text-align: left;
        margin-bottom: 2rem;
    }
}

.about-thailand .about-section-1 .content-container .para-1 {
    margin-top: 0 !important;
    font-size: 2.2rem !important;
    color: #333 !important;
}

@media (max-width: 768px) {
    .about-thailand .about-section-1 .content-container .para-1 {
        font-size: 2em !important;
    }
}

.about-thailand .about-section-1 .green-link img {
    padding-right: 1rem;
}

.about-thailand .about-section-2 {
    padding-bottom: 0;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .about-thailand .about-section-2 {
        padding-bottom: 0;
    }
    .about-thailand .about-section-2 .image-content-rows-container {
        margin-top: 4rem;
    }
}

@media (max-width: 768px) and (max-width: 768px) {
    .about-thailand .about-section-2 .image-content-row {
        padding: 1rem 0 3.8rem;
    }
    .about-thailand .about-section-2 .column-1 {
        padding: 0 !important;
    }
    .about-thailand .about-section-2 .column-1 img {
        margin-left: 2rem;
    }
}

.about-thailand .about-section-2 .primary-underline {
    position: relative;
}

.about-thailand .about-section-2 .primary-underline::after {
    content: "";
    width: 70px;
    height: 4px;
    position: absolute;
    left: 50%;
    bottom: -1rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-bottom: 4px solid #d2c060;
}

.about-thailand .about-section-2 .directors-list {
    margin: 6rem auto;
}

.about-thailand .about-section-2 .directors-list .director {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 7.2rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.about-thailand .about-section-2 .directors-list .director .profile-photo {
    margin-right: 4.2rem;
}

.about-thailand .about-section-2 .directors-list .director .profile-writeup .name {
    font-weight: 600;
    font-size: 2.4rem;
    margin-bottom: 0.8rem;
}

.about-thailand .about-section-2 .directors-list .director .profile-writeup .title {
    font-size: 1.6rem;
    color: #999;
    margin-bottom: 1.8rem;
}

.about-thailand .about-section-2 .directors-list .director .profile-writeup .bio {
    font-size: 1.4rem;
}

.about-thailand .about-section-3 {
    margin: 0 auto;
}

@media (max-width: 480px) {
    .about-thailand .about-section-3.image-content-section {
        padding-bottom: 5rem;
    }
}

.about-thailand .about-section-3 .image-content-rows-container {
    margin-top: 0;
}

.about-thailand .about-section-3 .about-heading {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

@media (max-width: 480px) {
    .about-thailand .about-section-3 .about-heading {
        margin-bottom: 2rem;
    }
}

.about-thailand .about-section-3 .about-heading h2 {
    font-size: 4.2rem;
    margin-bottom: 4rem;
    font-weight: 600;
    position: relative;
}

.about-thailand .about-section-3 .about-heading h2::after {
    content: "";
    width: 70px;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: -2rem;
    border-bottom: 4px solid #d2c060;
}

.about-thailand .about-section-3 .about-heading h4 {
    font-size: 2.2rem;
    font-weight: 300;
    max-width: 30rem;
}

.about-thailand .about-section-3 .content-container .para-1 {
    margin-top: 9rem !important;
}

@media (max-width: 480px) {
    .about-thailand .about-section-3 .content-container .para-1 {
        margin-top: 0 !important;
    }
}

.privacy-policy .content-container {
    margin-top: 7.2rem;
}

.privacy-policy .content-container .content {
    margin: 0 auto;
}

.privacy-policy .content-container .content h1 {
    font-size: 4.2rem;
    margin-bottom: 4.2rem;
    font-weight: 600;
}

.privacy-policy .content-container .content h2 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 2.4rem;
}

.privacy-policy .content-container .content p {
    font-size: 1.6rem;
    color: #666;
    text-align: justify;
    margin-bottom: 4.2rem;
}

.faq {
    background-color: #fcfcfc;
}

.faq .content-container {
    margin-top: 7.2rem;
}

.faq .content-container .content {
    margin: 0 auto;
}

.faq .content-container .content h1 {
    font-size: 4.2rem;
    margin-bottom: 4.2rem;
    font-weight: 600;
}

.faq .content-container .content h2 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 2.4rem;
}

.faq .content-container .content p {
    font-size: 1.6rem;
    color: #666;
    text-align: justify;
}

.faq .content-container .content #accordion .card {
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 2.4rem;
    border: 0;
}

.faq .content-container .content #accordion .card .card-header {
    background: #fff;
    border-bottom: 0;
    padding: 2.4rem;
}

.faq .content-container .content #accordion .card .card-header button {
    font-size: 1.8rem;
    font-weight: 500;
    color: #222;
    text-decoration: none;
    text-align: left;
    padding: 0;
}

.faq .content-container .content #accordion .card .card-body {
    padding: 0 2.4rem 2.4rem;
}

.partners-index {
    /* background: url(../images/pages/partners/homepage-bg-desktop.svg); */
    background-repeat: no-repeat;
    background-size: 100%;
}

@media (max-width: 480px) {
    .partners-index {
        /* background: url(../images/pages/partners/homepage-bg-mobile.svg); */
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.partners-index .nav-links {
    color: #083863;
}

.partners-index .banner {
    /* background: url(../images/pages/partners/bg-rect.png); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.partners-index .mobile-images {
    display: none;
}

.partners-index .link-button::after {
    /* content: url(../images/pages/partners/Vector.png); */
    vertical-align: middle;
    margin-left: 11px;
}

.partners-index .homepage-section-1 {
    padding-right: 0 !important;
    padding-left: 12rem !important;
}

@media (max-width: 992px) {
    .partners-index .homepage-section-1 {
        padding-left: 7rem !important;
    }
}

@media (max-width: 768px) {
    .partners-index .homepage-section-1 {
        padding-left: 1.6rem !important;
    }
}

.partners-index .homepage-section-1 .column-1 {
    padding-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .partners-index .homepage-section-1 .column-1 {
        margin-top: 12rem;
    }
}

@media (max-width: 480px) {
    .partners-index .homepage-section-1 .column-1 {
        margin-top: 6.4rem;
    }
}

.partners-index .homepage-section-1 .column-1 .content-container {
    margin-top: -110px;
}

@media (max-width: 768px) {
    .partners-index .homepage-section-1 .column-1 .content-container {
        margin-top: 0;
    }
}

.partners-index .homepage-section-1 .column-1 .content-container h1 {
    font-size: 52px;
    font-weight: 800;
}

@media (max-width: 768px) {
    .partners-index .homepage-section-1 .column-1 .content-container h1 {
        font-weight: 800;
        font-size: 42px;
        line-height: 1.25;
        width: auto;
    }
}

.partners-index .homepage-section-1 .column-1 .content-container .button-video-container,
.partners-index .homepage-section-1 .column-1 .content-container h1,
.partners-index .homepage-section-1 .column-1 .content-container p {
    opacity: 1;
}

.partners-index .homepage-section-1 .column-1 .content-container .button-video-container .learn-more-button,
.partners-index .homepage-section-1 .column-1 .content-container h1 .learn-more-button,
.partners-index .homepage-section-1 .column-1 .content-container p .learn-more-button {
    margin-left: 24px;
    background: 0 0 !important;
}

.partners-index .homepage-section-1 .column-2 {
    padding: 0;
    position: relative;
    right: 0;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    margin-top: 1rem;
}

@media (max-width: 480px) {
    .partners-index .homepage-section-1 .column-2 {
        padding-left: 10rem;
        margin-top: 3.2rem;
    }
}

@media (max-width: 768px) {
    .partners-index .homepage-section-1 .column-2 .image-container {
        width: auto !important;
    }
}

.partners-index .homepage-section-1 .column-2 .image-container .mobile-image {
    position: absolute;
    top: -6rem;
    right: 11rem;
}

@media (max-width: 768px) {
    .partners-index .homepage-section-1 .column-2 .image-container .mobile-image {
        top: -6rem;
        right: 11rem;
    }
}

@media (max-width: 480px) {
    .partners-index .homepage-section-1 .column-2 .image-container .mobile-image {
        top: 2rem;
        right: 6rem;
    }
}

.partners-index .homepage-section-2 {
    padding: 101px 113px 90px;
    background: linear-gradient(240deg, #fff, #fff);
}

.partners-index .homepage-section-2 .grid-section__heading {
    text-align: center;
    color: #083863;
    margin-bottom: 60px;
}

.partners-index .homepage-section-2 .learn-more-button {
    font-weight: 500;
    font-size: 16px;
    color: #00bbb4;
    margin-left: 0;
}

.partners-index .homepage-section-2 .learn-more-button:hover {
    text-decoration: none;
}

.partners-index .homepage-section-2 .grid-section__item {
    margin-bottom: 3.6rem;
    max-height: 316px;
    padding: 0 0.8rem;
}

@media (max-width: 768px) {
    .partners-index .homepage-section-2 .grid-section__item {
        margin-bottom: 1.6rem;
    }
}

.partners-index .homepage-section-2 .content-container {
    height: 316px;
    background: #fff;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 36px 30px;
}

.partners-index .homepage-section-2 .content-container h3 {
    color: #083863;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}

.partners-index .homepage-section-2 .content-container p {
    font-size: 16px;
    color: #666;
    height: 72px;
}

.partners-index .homepage-section-3 {
    /* background: url(../images/pages/partners/benefits-mandala-bg-big.svg); */
    background-size: cover;
    background-repeat: no-repeat;
    padding: 120px 125px;
}

@media (max-width: 1370px) {
    .partners-index .homepage-section-3 {
        background-size: auto;
    }
}

@media (max-width: 768px) {
    .partners-index .homepage-section-3 {
        background: -webkit-gradient(linear, left top, left bottom, color-stop(5.46%, #01b3ab), color-stop(44.54%, #00998e));
        background: linear-gradient(180deg, #01b3ab 5.46%, #00998e 44.54%);
        -webkit-clip-path: polygon(0 0, 100% 2%, 100% 98%, 0 100%);
        clip-path: polygon(0 0, 100% 2%, 100% 98%, 0 100%);
    }
}

@media (max-width: 768px) {
    .partners-index .homepage-section-3 {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

.partners-index .homepage-section-3 .grid-section__heading {
    text-align: center;
    margin-bottom: 42px;
    font-size: 32px;
}

.partners-index .homepage-section-3 .grid-section__item {
    padding-right: 0;
    margin-bottom: 1.8rem;
}

@media (max-width: 768px) {
    .partners-index .homepage-section-3 .grid-section__item {
        padding-left: 0;
        margin-bottom: 1.2rem;
    }
}

.partners-index .homepage-section-3 .content-container {
    height: 260px;
    padding: 30px 30px;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

@media (max-width: 992px) {
    .partners-index .homepage-section-3 .content-container {
        height: auto;
    }
}

.partners-index .homepage-section-3 .content-container h3 {
    color: #083863;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.partners-index .homepage-section-3 .content-container p {
    font-size: 14px;
    color: #666;
    margin-top: 0;
    line-height: 1.2;
}

.partners-index .partners-section {
    margin-top: 12rem;
    padding: 0 7.2% 65px 10.7%;
}

.partners-index .partners-section .column-1 h2 {
    color: #083863;
    font-weight: 700;
    font-size: 36px;
}

@media (max-width: 768px) {
    .partners-index .partners-section .column-1 h2 {
        margin-bottom: 4.8rem;
    }
}

.partners-index .partners-section .partner-logos-container .col {
    margin-bottom: 30px;
}

.partners-index .counter-section {
    display: flex;
    padding: 0px 7.2% 65px 10.7%;
    margin-bottom: 72px;
    position: relative;
    justify-content: center;
    
}

.partners-index .counter-section .bg-white-mandala {
    position: absolute;
    left: -14px;
    top: -215px;
}

@media (max-width: 768px) {
    .partners-index .counter-section .bg-white-mandala {
        display: none;
    }
}

.partners-index .counter-section .content-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 50px;
}

@media (max-width: 768px) {
    .partners-index .counter-section .content-container {
        margin-left: 0;
        width: 100%;
    }
}

.partners-index .counter-section .content-container div {
    width: 200px;
}

@media (max-width: 768px) {
    .partners-index .counter-section .content-container div::nth-of-type(3) {
        margin-left: 0;
    }
}

.partners-index .counter-section .content-container div::nth-of-type(3) {
    margin-left: 24px;
}

@media (max-width: 768px) {
    .partners-index .counter-section .content-container .partner-counter {
        width: 80px !important;
    }
}

@media (max-width: 768px) {
    .partners-index .counter-section .transactions-counter {
        width: 100px !important;
    }
}

@media (max-width: 768px) {
    .partners-index .counter-section .tps-counter {
        width: 120px !important;
    }
}

.partners-index .counter {
    color: #00bbb4;
    font-size: 36px;
    font-weight: 600;
    margin: 0 8px;
}

@media (max-width: 768px) {
    .partners-index .counter {
        width: auto !important;
        font-weight: 600;
        font-size: 24px;
    }
}

.partners-index .counter-name {
    color: #666;
    font-size: 16px;
}

.bg-slant-grey {
    /* background: url(../images/pages/partners/bg-slant-grey.svg); */
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    bottom: -40px;
    background-size: cover;
}

.feature-section {
    padding-top: 174px;
    padding-bottom: 150px;
    padding-left: 10rem;
    padding-right: 10rem;
    position: relative;
}

@media (max-width: 768px) {
    .feature-section {
        padding-left: 0;
        padding-right: 0;
    }
}

.feature-section h1 {
    font-size: 36px;
    font-weight: 700;
    color: #083863;
    text-align: center;
}

.feature-section .content-container {
    margin-top: 143px;
}

@media (max-width: 768px) {
    .feature-section .content-container {
        margin-top: 3.4rem;
    }
}

.feature-section .item {
    padding-top: 10px;
}

.feature-section .item img {
    -webkit-filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.1));
}

.feature-section .item p {
    color: #333;
    font-size: 22px;
    width: 68.2%;
}

@media (max-width: 768px) {
    .feature-section .item p {
        width: auto;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        font-size: 18px;
        line-height: 1.5;
    }
}

.feature-section .owl-carousel .owl-dots,
.feature-section .owl-carousel .owl-nav {
    position: absolute;
}

.feature-section .owl-carousel .owl-nav {
    left: calc(50%+1.5rem);
    bottom: 18rem;
}

@media (max-width: 768px) {
    .feature-section .owl-carousel .owl-nav {
        bottom: -4rem;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

.feature-section .owl-carousel .owl-nav .owl-next {
    margin-left: 6rem;
}

.feature-section .owl-carousel .owl-dots {
    bottom: 18rem;
    left: calc(50%+4rem);
}

@media (max-width: 768px) {
    .feature-section .owl-carousel .owl-dots {
        bottom: -4rem;
        left: 50%;
        -webkit-transform: translateX(-42%);
        transform: translateX(-42%);
    }
}

.testimonials-section-desktop {
    display: block !important;
}

.testimonials-section {
    background: url('https://www.safegold.com/assets/images/partnerships/dots.svg');
    background-repeat: no-repeat;
    background-position: 93.5% 83%;
    padding-bottom: 150px;
    padding-left: 12rem;
    padding-right: 12rem;
}

@media (max-width: 768px) {
    .testimonials-section {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

/* lease page  */
.sectiod-gold-bnr::after {
    background: rgba(0,187,180,.11);
    /* -webkit-transform: rotate(-18.98deg); */
    -ms-transform: rotate(-18.98deg);
    transform: rotate(-18.98deg);
    top: -345px;
    left: -190px;
    width: 120%;
    height: 100%;
}
.c-41F {
    color: #414a4f;
}

.f-sarabun {
    font-family: 'Sarabun',sans-serif;
}
.fw-700 {
    font-weight: 700;
}
.f-20 {
    font-size: 20px;
    line-height: 32px;
}
.irs--flat {
    height: 40px;
}
.irs {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    font-family: Arial,sans-serif;
}

.irs, .irs-line {
    position: relative;
    display: block;
}
.irs--flat {
    height: 60px!important;
}
.irs--flat .irs-bar--single {
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.irs--flat .irs-bar {
    top: 25px;
    height: 12px;
    background-color: #ed5565;
}
.irs-bar, .irs-shadow {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
}
.irs-bar {
    background: -webkit-gradient(linear,left top,right top,color-stop(4.31%,#418893),color-stop(89.09%,#44909c))!important;
    background: -webkit-linear-gradient(left,#418893 4.31%,#44909c 89.09%)!important;
    background: -o-linear-gradient(left,#418893 4.31%,#44909c 89.09%)!important;
    background: linear-gradient(90deg,#418893 4.31%,#44909c 89.09%)!important;
    height: 6px!important;
}
.irs--flat .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: #e1e4e9;
}
.irs-shadow {
    display: none;
}
.irs-bar, .irs-shadow {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
}
.irs--flat .irs-handle {
    top: 22px;
    width: 16px;
    height: 18px;
    background-color: transparent;
}
.irs-handle, .irs-max, .irs-min {
    position: absolute;
    display: block;
    cursor: default;
}
.irs-handle {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 1;
}
.irs--flat .irs-handle>i:first-child {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: #da4453;
}
.irs--flat .irs-handle i:first-child {
    background-color: none!important;
    opacity: 0;
}
.content-pad {
    padding-top: 20px;
}
.f-24 {
    font-size: 24px;
    line-height: 32px;
}
.deposit-chart-list__item {
    min-width: 150px;
    min-height: 60px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background-color: #e3f8f7;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    padding: 10px;
    margin-right: 25px;
}
.deposit-chart-list__item .c-dot {
    display: inline-block;
    width: 14px;
    height: 14px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-top: 3px;
}
.deposit-chart-list__item .d-amount {
    width: calc(100% - 23px);
}

.deposit-chart-list__item * {
    color: #414a4f;
    font-family: 'Sarabun';
}
.deposit-chart-list__item:last-child {
    margin-right: 0;
}
.deposit-chart-list__item .d-amount {
    width: calc(100% - 23px);
}

.deposit-chart-list__item * {
    color: #414a4f;
    font-family: 'Sarabun';
    font-size: 16px;
    font-weight: 600;
    line-height: 2;
}
.fa-angle-down {
    font-weight: bolder;
    transform: rotate(0deg);
    transition: transform 0.25s linear;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.profit_calc_wrapper {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profit_calc_item {
    width: 15%;
    height: 95px;
    padding: 10px 20px;
    background: rgba(255, 239, 192, 0.51);
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.profit_calc_item .first_elem {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #414A4F;
    margin-bottom: 5px;
}
.profit_calc_item .second_elem {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #414A4F;
}
.as_of {
    font-size: 13px;
    font-style: italic;
}
.frst-pd {
    padding: 0 0 50px;
}

.f-2rem {
    font-size: 1.8rem!important;
}
.f-2rem span {
    font-weight: 600;
}
.investmentImg-wrapper {
    text-align: center;
}
.h-120 {
    height: 120px;
}
.c-41F {
    color: #414a4f;
}
.c-F78 {
    color: #ffd447;
}
.content-pad {
    padding-top: 20px;
}

.f-sarabun {
    font-family: 'Sarabun',sans-serif;
}

.f-24 {
    font-size: 24px;
    line-height: 32px;
}

.container, section {
    position: relative;
}

.container {
    z-index: 2;
}
.mb-md-135 {
    margin-bottom: 100px;
}
.fw-500 {
    font-weight: 500;
}
.c-41F {
    color: #414a4f;
}
.fw-400 {
    font-weight: 400;
    
}
.f-5rem {
    font-size: 4rem;
}
.c-E37 {
    background-color: #e3f8f7;
}
.d-block {
    display: block!important;
    /* font-size: 4rem; */
}


.f-sarabun {
    font-family: 'Sarabun',sans-serif;
}
.f-sarabun .span-1 {
    font-size: 4rem;
    font-weight: 600;
}
.f-14 {
    font-size: 15px;
    line-height: 22px;
}
.text-uppercase {
    text-transform: uppercase!important;
}
.mb-0 {
    margin-bottom: 0!important;
}
.row-box-shadow {
    padding: 4rem 3rem;
    border-radius: 12px;
    -webkit-box-shadow: 4px 4px 20px rgba(0,0,0,.1);
}

.bg-F7F {
    background: #f7f7f7;
}

.sec-commit-pad {
    padding-top: 50px;
}
.ov-hidden {
    overflow: hidden;
}
.container, section {
    position: relative;
}
.d-block {
    display: block!important;
}
.px-0 {
    padding-right: 0!important;
    padding-left: 0!important;
    font-size: 1.8rem;
    line-height: 3rem;
}
.earn-upto-section .sub-section-1 {
    margin-bottom: 10px;
}

.earn-upto-section .sub-section {
    padding: 10px 15px;
    border-radius: 14px;
    background: rgba(0, 187, 180, 0.07);
}
.earn-upto-section {
    width: max-content;
    padding: 15px 30px;
    border-radius: 14px;
    margin: 40px 0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
}
.earn-upto-section .left-section {
    margin-right: 10px;
}

.earn-upto-section .left-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.earn-upto-section .right-section {
    display: flex;
    flex-direction: column;
}
.mt-4 {
    margin-top: 1.5rem!important;
}
a.web-btn {
    outline: none;
}

.web-btn {
    font-size: 16px;
    padding: 0 20px;
    line-height: 47px!important;
    min-width: 300px;
    font-weight: 700;
    text-align: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: 'Sarabun';
    cursor: pointer;
    border: 0;
    position: relative;
    color: #f6f6f6;
    text-decoration: none;
    z-index: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    overflow: hidden;
    background: #00bbb4;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}
.ft-links__item a, .web-btn {
    text-transform: uppercase;
}

.c-F00 {
    color: #ffbf00;
}
.earn-upto-section .left-section .desc span.highlight {
    font-size: 36px;
    font-weight: 600;
}

.earn-upto-section .left-section .desc span {
    color: #00bbb4;
    font-size: 14px;
    line-height: 28px;
}
.earn-upto-section .sub-section span.green-text {
    color: #00bbb4;
    font-size: 16px;
    line-height: 24px;
}
.earn-upto-section .sub-section span {
    color: #9a9999;
    font-size: 14px;
    line-height: 22px;
}
.earn-upto-section .left-section .head {
    color: #414a4f;
    font-size: 16px;
    line-height: 22px;
}
.earn-upto-section .left-section img {
    margin-right: 20px;
    display: inline-block;
}
.stats-main-wrapper {
    display: none;
}
.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.ps-1 {
    padding-left: 0.25rem!important;
}

.ms-2 {
    margin-left: 0.5rem!important;
}
.f-sarabun {
    font-family: 'Sarabun',sans-serif;
}
.f-sarabun {
    font-family: 'Sarabun',sans-serif;
}

.sec-pd {
    padding: 100px 0;
}

.f-16 {
    font-size: 16px;
    line-height: 24px;
}

.optstep-icon {
    position: absolute;
    bottom: 210px;
    right: 0;
}

.opt-stepline {
    /* display: -webkit-box; */
    /* display: -ms-flexbox; */
    display: flex;
    -webkit-box-pack: center;
    /* -ms-flex-pack: center; */
    justify-content: center;
}
.text-center {
    text-align: center!important;
}
.opt-content-width {
    width: 211px;
    margin: 0 auto;
    text-align: left;
}
.leasing-button {
    padding-top: 60px;
}

.third-pd {
    padding: 0 0 25px;
}
.cashing-subheading {
    margin-bottom: 50px!important;
}



.img-height-opt {
    height: 110px;
}
.img-fluid, .img-thumbnail {
    max-width: 100%;
    /* height: auto; */
}

.opt-stepline {
    margin: 20px 0 30px;
}
.opt-step-wrapper {
    width: 45px;
    height: 45px;
    background: -webkit-linear-gradient(209.42deg,#a7cfd5 1.97%,#5596a0 68.03%);
    background: -o-linear-gradient(209.42deg,#a7cfd5 1.97%,#5596a0 68.03%);
    background: linear-gradient(240.58deg,#a7cfd5 1.97%,#5596a0 68.03%);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding-top: 8px;
}
.opt-step-wrapper__step-number {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22.8px;
    line-height: 28px;
    letter-spacing: .03em;
    color: #fff;
}
.fourth-pd {
    margin: 50px 0 0;
}

.a-hover:hover {
    text-decoration: underline;
}

.alert-btn {
    z-index: 999;
    display: inline-block;
    color: rgb(0, 137, 150);
    font-weight: bold;
    font-family: Sarabun;
    /* border-color: rgb(0, 137, 150); */
    border: 0px;
    border-radius: 5px;
    padding: 8px 15px;
    background-image: none;
    background-color: rgb(113, 226, 215);
}









.testimonials-section h1 {
    margin-bottom: 54px;
    font-size: 36px;
    font-weight: 700;
    color: #083863;
    text-align: center;
}

.testimonials-section .testimonials-container .inner-item {
    padding: 30px 24px;
    background: #fff;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
}

@media (max-width: 768px) {
    .testimonials-section .testimonials-container .inner-item {
        margin-bottom: 1.6rem;
    }
}

.testimonials-section .testimonials-container .inner-item .bank {
    margin-bottom: 42px;
}

.testimonials-section .testimonials-container .inner-item .quote {
    position: absolute;
    top: 84px;
}

.testimonials-section .testimonials-container .inner-item p {
    font-weight: 400;
    font-size: 15px;
    color: #666;
    position: relative;
    z-index: 2;
}

.testimonials-section .testimonials-container .inner-item .author-name {
    margin-bottom: 0;
    color: #083863;
    font-weight: 500;
    font-size: 14px;
}

.testimonials-section .testimonials-container .inner-item .author-position {
    color: #666;
    font-size: 12px;
    font-weight: 400;
}

.testimonials-section .testimonials-container .inner-item .author {
    margin-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.testimonials-section .cards-container {
    margin-top: 5.4rem;
}

.testimonials-section .cards-container .testimonial-card {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.testimonials-section .cards-container .testimonial-card .testimonial {
    margin-top: 2.2rem;
    font-size: 16px;
    line-height: 1.5;
}

.testimonials-section .cards-container .testimonial-card .photo-job-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.testimonials-section .cards-container .testimonial-card .photo-job-container .profile-photo {
    width: 30px;
    height: 30px;
    background: #c4c4c4;
    border-radius: 50%;
}

.testimonials-section .cards-container .testimonial-card .photo-job-container .job {
    margin-left: 1.2rem;
    font-weight: 500;
    font-size: 14px;
}

.partners-index-section-8 {
    padding-bottom: 24.6rem;
    margin-top: 0;
}

@media (max-width: 768px) {
    .partners-index-section-8 .section-heading {
        text-align: left;
        font-weight: 800;
        font-size: 36px;
    }
}

@media (max-width: 768px) {
    .partners-index-section-8 {
        padding-bottom: 12rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

.news-section-desktop {
    padding-bottom: 196px;
}

.news-section-desktop h1 {
    font-size: 36px;
    font-weight: 700;
    color: #083863;
    text-align: center;
    margin-bottom: 60px;
}

.news-section-desktop .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.news-section-desktop .item .inner-item {
    width: 300px;
    background: #fff;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-right: 16px;
}

.news-section-desktop .item .inner-item .content {
    padding: 24px;
}

.news-section-desktop .item .inner-item .content h4 {
    font-weight: 600;
    font-size: 18px;
}

.news-section-desktop .item .inner-item .content p {
    font-size: 14px;
    margin: 12px 0 22px;
}

.news-section-desktop .item .inner-item .content .link-button {
    margin-left: 0;
    font-weight: 500;
    font-size: 16px;
    color: #00bbb4;
}

.news-section-mobile {
    display: none;
}

footer {
    border-top: 0;
}

footer .mobile-logo-footer {
    display: none;
}

footer .column-2 p {
    width: 260px;
    height: 115px;
    margin-bottom: 12px;
}

footer .trustee {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

footer .trustee .image-container {
    width: 48%;
}

footer .contact {
    font-weight: 600;
    font-size: 15px;
    color: #fff;
}

footer .contact li {
    margin-bottom: 24px;
    margin-left: 22px;
    position: relative;
}

footer .contact li div {
    margin-bottom: 14px;
}

footer .contact .address::before,
footer .contact .email::before,
footer .contact .number::before {
    position: absolute;
    left: -42px;
}

footer .contact .number::before {
    /* content: url(../images/pages/partners/phone.png); */
}

footer .contact .email::before {
    /* content: url(../images/pages/partners/email.png); */
}

footer .contact .address::before {
    /* content: url(../images/pages/partners/location.png); */
}

@media (max-width: 768px) {
    .partners-index .homepage-section-1 .dashboard,
    .partners-index .homepage-section-1 .mobile {
        position: relative;
    }
    .partners-index .desktop-images {
        display: none;
    }
    .partners-index .mobile-images {
        display: block;
        height: 350px;
    }
    .partners-index .mobile-images .dashboard {
        width: 230px;
        position: relative;
        top: 0;
        right: -123px;
    }
    .partners-index .mobile-images .mobile {
        position: relative;
        top: -401px;
        right: -4px;
    }
    .partners-index .banner {
        /* background: url(../images/pages/partners/bg-Mobile.png); */
        background-repeat: no-repeat;
    }
    .partners-index .homepage-section-1 h1 {
        font-weight: 800;
        font-size: 42px;
        width: 85%;
        margin: 0;
        margin-bottom: 48px;
    }
    .partners-index .homepage-section-1 ul {
        margin: 0;
    }
    .partners-index .homepage-section-1 .req-demo-button button {
        padding: 9.5px 25px;
        margin-right: 18px;
    }
    .partners-index .homepage-section-2 {
        padding: 0;
    }
    .partners-index .homepage-section-2 .grid-section__heading {
        font-weight: 700;
        margin: 120px 0 48px;
        font-size: 36px;
    }
    .partners-index .homepage-section-2 .content-container {
        padding: 34px 23px;
        width: 100%;
    }
    .partners-index .column-3 .show-more {
        display: none;
    }
    .partners-index .testimonials-section-mobile {
        display: block;
    }
    .partners-index .testimonials-section-mobile>div {
        margin-top: 63px;
    }
    .partners-index .testimonials-section-mobile .inner-item {
        position: relative;
    }
    .partners-index .testimonials-section-mobile .quote {
        position: absolute;
        top: -27px;
    }
    .partners-index .testimonials-section-mobile p {
        font-weight: 400;
        font-size: 16px;
        color: #666;
        position: relative;
        z-index: 2;
    }
    .partners-index .testimonials-section-mobile .author {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
    .partners-index .testimonials-section-mobile .author .author-name {
        margin-left: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #222;
    }
    .partners-index .testimonials-section-desktop {
        display: none;
    }
    .partners-index .news-section-desktop {
        display: none;
    }
    .partners-index .news-section-mobile {
        display: block;
    }
    .partners-index .news-section-mobile h1 {
        margin: 102px 0 49px;
        font-weight: 800;
        font-size: 36px;
        color: #083863;
    }
    .partners-index .news-section-mobile .news-companies {
        margin: 0 14px 96px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .partners-index .news-section-mobile .news-companies li {
        width: 142px;
        text-align: center;
    }
    .partners-index footer .mobile-logo-footer {
        display: block;
    }
    .partners-index footer .desktop-logo-footer {
        display: none;
    }
}

.partners-digital-gold-savings {
    overflow-y: hidden;
}

.partners-digital-gold-savings .navbar {
    padding-top: 2.4rem !important;
    height: auto;
}

.partners-digital-gold-savings .navbar .logo {
    width: 164px;
}

.partners-digital-gold-savings .section-title {
    color: #083863;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 1.6rem;
}

.partners-digital-gold-savings .section-subtitle {
    font-size: 16px;
    line-height: 21px;
    color: #666;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 {
    padding: 0 6.7%;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container {
    width: 450px;
    margin-top: -14rem;
    z-index: 2;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container {
        width: auto;
        margin-top: 0;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container .indent-text {
    display: block;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container h1 {
    font-size: 42px;
    font-weight: 800;
    line-height: 55px;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container .button-video-container,
.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container h1,
.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container p {
    opacity: 1;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container .button-video-container .learn-more-button,
.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container h1 .learn-more-button,
.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container p .learn-more-button {
    margin-left: 24px;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-1 .content-container .learn-more-button::after {
    margin-left: 11px;
    /* content: url(../images/pages/partners/arrow-green.svg); */
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-2 {
        margin-top: 10rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-2 img {
    position: relative;
    right: 148px;
    z-index: -1;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-1 .column-2 img {
        right: 30px;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-2 {
    padding-left: 8.6rem;
    padding-right: 8.6rem;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-2 {
        padding-left: 0;
        padding-right: 0;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-2 .section-title {
    text-align: center;
    line-height: 47px;
    margin-bottom: 5.4rem;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-2 .cards-container .card-item-outer {
        margin-bottom: 1.2rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-2 .cards-container .card-item-outer.padding-right {
    padding-right: 1.2rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-2 .cards-container .card-item-outer.padding-left {
    padding-left: 1.2rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-2 .cards-container .card-item-outer .card-item {
    padding-top: 4rem;
    padding-bottom: 3.6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background: #fafafa;
    background: #fff;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-2 .cards-container .card-item-outer .card-item .card-image {
    margin-bottom: 2.4rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-2 .cards-container .card-item-outer .card-item .card-text {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 {
    position: relative;
    margin-top: 12rem;
    padding-left: 8.6rem;
    padding-right: 8.6rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .background-dots {
    position: absolute;
    bottom: -7rem;
    right: 5rem;
    z-index: 1;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-3 {
        padding-left: 0;
        padding-right: 0;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container {
    position: relative;
    z-index: 2;
    margin-top: 4.2rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer.padding-left {
    padding-left: 18px;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer.padding-right {
    padding-right: 18px;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item {
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 10px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

@media (max-width: 480px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item.pwa {
        margin-bottom: 1.4rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item.pwa .column-1 {
    color: #00bbb4;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item.api .column-1 {
    color: #e8a50f;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-1 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 36px;
    text-transform: uppercase;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 {
    margin-left: 1.8rem;
}

@media (max-width: 480px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 {
        margin-left: 0;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .title {
    font-weight: 600;
    font-size: 24px;
    color: #222;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .subtitle {
    margin-top: 1.2rem;
    font-size: 16px;
    color: #666;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .list-container {
    margin-top: 2.4rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .list-container .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .list-container .list-item:not(:first-child) {
    margin-top: 1.6rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .list-container .list-item img {
    margin-right: 1.2rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .list-container .list-item span {
    color: #222;
    font-size: 16px;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .buttons-container {
    margin-top: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-3 .card-container .card-item-outer .card-item .column-2 .buttons-container .primary-button {
    margin-right: 2.4rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 {
    display: none;
    padding-left: 8.6rem;
    padding-right: 8.6rem;
    margin-top: 15rem;
    padding-bottom: 36rem;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-4 {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-4 {
        padding-bottom: 8rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-1 .image-container {
    position: relative;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-1 .image-container .image-1 {
    position: absolute;
    top: 0;
    left: 10.8rem;
    z-index: 2;
}

@media (max-width: 1320px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-1 .image-container .image-1 {
        left: 8rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-1 .image-container .image-2 {
    position: absolute;
    top: 16rem;
    left: -2rem;
    z-index: 1;
}

@media (max-width: 992px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-2 {
        margin-top: 56rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-2 .owl-carousel {
    position: relative;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-2 .owl-carousel .owl-nav {
    position: absolute;
    bottom: 0;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-2 .owl-carousel .owl-nav .owl-next {
    margin-left: 7rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-2 .owl-carousel .owl-dots {
    position: absolute;
    bottom: 0;
    left: 2.4rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-2 .content {
    margin-top: 152px;
    font-size: 22px;
    line-height: 1.5;
    color: #333;
    padding-bottom: 12.8rem;
}

@media (max-width: 992px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-4 .column-2 .content {
        padding-bottom: 4.8rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .section-title {
    text-align: center;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-4 .carousel-container {
    margin-top: 4.8rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-5 {
    background: linear-gradient(225deg, #00bbb4 0, #00998e 100%);
    padding-bottom: 7.2rem;
    padding-top: 7.2rem;
    padding-left: 20rem;
    padding-right: 20rem;
    margin-top: 15rem;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-5 {
        padding-left: 0;
        padding-right: 0;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-5 .column-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    color: #fff;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-5 .column-1 {
        text-align: center;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-5 .column-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-5 .column-2 {
        margin-top: 4rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-5 .column-2 .primary-button {
    color: #00bbb4;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-6 {
    padding-top: 12rem;
    padding-bottom: 16.6rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-6 .column-2 .list-item {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 {
    padding-top: 12rem;
    padding-bottom: 9rem;
    background: #fbfbfb;
    -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .section-subtitle,
.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .section-title {
    text-align: center;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container {
    padding-left: 30.2rem;
    padding-right: 30.2rem;
}

@media (max-width: 992px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container .logo-container {
    text-align: center;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container .content-container {
    position: relative;
    margin-top: 3rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container .content-container .quotes {
    position: absolute;
    top: -2rem;
    left: 1rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container .content-container .content {
    text-align: center;
    font-weight: 300;
    font-size: 22px;
    line-height: 1.5;
    color: #083863;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container .content-container .name-position-container {
    margin-top: 2.4rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container .content-container .name-position-container .name {
    font-size: 18px;
    line-height: 23px;
    color: #083863;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-7 .testimonial-container .content-container .name-position-container .position {
    margin-top: 0.4rem;
    font-size: 14px;
    line-height: 18px;
    color: #666;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-8 {
    margin-top: 11.6rem;
    padding-bottom: 12rem;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-8 .section-title {
    text-align: center;
}

.partners-digital-gold-savings .partners-digital-gold-saving-section-8 .accordian-container {
    margin-top: 7.2rem;
    padding-left: 32rem;
    padding-right: 32rem;
}

@media (max-width: 768px) {
    .partners-digital-gold-savings .partners-digital-gold-saving-section-8 .accordian-container {
        padding-left: 0;
        padding-right: 0;
    }
}

.partners-corporate-gifting {
    overflow-x: hidden;
}

.partners-corporate-gifting .section-title {
    color: #083863;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 1.6rem;
}

.partners-corporate-gifting .section-subtitle {
    font-size: 16px;
    line-height: 21px;
    color: #666;
}

.partners-corporate-gifting .partners-corporate-gifting-section-1 {
    padding: 0 6.7%;
}

.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 {
        padding-left: 0;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 .content-container {
    width: 80%;
    margin-top: -14rem;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 .content-container {
        width: auto;
        margin-top: 0;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 .content-container h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 62px;
}

.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 .content-container p {
    font-size: 18px;
    font-weight: 300;
}

.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 .content-container .button-video-container,
.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 .content-container h1,
.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-1 .content-container p {
    opacity: 1;
}

.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-2 {
    margin-top: 4.8rem;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-1 .column-2 {
        margin-top: 7.8rem;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-1 .column-2 .image-container img {
    position: relative;
    right: 104px;
    -webkit-transform: scale(1.7);
    transform: scale(1.7);
    z-index: -1;
}

@media (max-width: 992px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-1 .column-2 .image-container img {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-1 .column-2 .image-container img {
        right: 2.4rem;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 {
    /* background: url(../images/pages/partners/benefits-mandala-bg.svg); */
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 11.2rem;
    padding-right: 11.2rem;
    padding-top: 12rem;
    padding-bottom: 12rem;
    margin-left: -15px;
    margin-right: -15px;
}

@media (max-width: 1370px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-2 {
        background-size: auto;
    }
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-2 {
        background: -webkit-gradient(linear, left top, left bottom, color-stop(5.45%, #01b3ab), color-stop(44.41%, #00998e));
        background: linear-gradient(180deg, #01b3ab 5.45%, #00998e 44.41%);
        -webkit-clip-path: polygon(0 0, 100% 2%, 100% 98%, 0 100%);
        clip-path: polygon(0 0, 100% 2%, 100% 98%, 0 100%);
    }
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-2 {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 .section-title {
    text-align: center;
    color: #fff;
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 .cards-container {
    margin-top: 7.2rem;
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 .cards-container .card-outer {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-2 .cards-container .card-outer {
        margin-bottom: 1.8rem;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 .cards-container .card-outer.padding-left {
    padding-left: 1.5rem;
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 .cards-container .card-outer.padding-right {
    padding-right: 1.5rem;
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 .cards-container .card-outer .card-inner {
    min-height: 252px;
    padding-top: 4rem;
    padding-bottom: 3.6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 10px;
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 .cards-container .card-outer .card-inner .card-title {
    margin-top: 2.2rem;
    font-weight: 500;
    font-size: 24px;
    color: #222;
}

.partners-corporate-gifting .partners-corporate-gifting-section-2 .cards-container .card-outer .card-inner .card-subtitle {
    margin-top: 1.2rem;
    font-size: 14px;
    line-height: 1.5;
    color: #666;
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 {
    background-size: cover;
    background-position: center center;
    margin-top: 6rem;
    padding-bottom: 12rem;
    position: relative;
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 .background-dots {
    position: absolute;
    bottom: 10rem;
    right: 18rem;
    z-index: 1;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-3 .background-dots {
        bottom: 7.8rem;
        right: 0;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 .section-title {
    text-align: center;
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 .cards-container {
    position: relative;
    z-index: 2;
    margin-top: 7.2rem;
    padding-left: 32rem;
    padding-right: 32rem;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-3 .cards-container {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 .cards-container .card-outer {
    margin-bottom: 2.4rem;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-3 .cards-container .card-outer {
        margin-bottom: 1.8rem;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 .cards-container .card-outer.padding-left {
    padding-left: 1.2rem;
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 .cards-container .card-outer.padding-right {
    padding-right: 1.2rem;
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 .cards-container .card-outer .card-inner {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3.6rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: 204px;
    background: #fff;
}

.partners-corporate-gifting .partners-corporate-gifting-section-3 .cards-container .card-outer .card-inner .card-subtitle {
    margin-top: 2.4rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    color: #666;
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 {
    margin-top: 14rem;
    padding-bottom: 15rem;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-4 {
        margin-top: 8rem;
        padding-bottom: 8rem;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 .column-1 {
    position: relative;
    margin-top: 0;
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 .column-1 .section-title {
    display: none;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-4 .column-1 .section-title {
        display: block;
        margin-top: -16rem;
    }
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-4 .column-1 {
        margin-top: 14rem;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 .column-2 {
    margin-top: 10rem;
    display: block;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-4 .column-2 {
        margin-top: 24rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-4 .column-2 .section-title {
        display: none;
    }
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 .column-2 .list-container {
    margin-top: 6.8rem;
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 .column-2 .list-container .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1.8rem;
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 .column-2 .list-container .list-item.active img {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 .column-2 .list-container .list-item img {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.partners-corporate-gifting .partners-corporate-gifting-section-4 .column-2 .list-container .list-item span {
    margin-left: 1rem;
    font-size: 22px;
    color: #333;
}

.partners-corporate-gifting .partners-corporate-gifting-section-5 {
    padding-top: 12rem;
    padding-bottom: 10.5rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.partners-corporate-gifting .partners-corporate-gifting-section-5 .column-2 .list-item {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
}

.partners-corporate-gifting .partners-corporate-gifting-section-5 .button-container {
    text-align: center;
}

.partners-corporate-gifting .partners-corporate-gifting-section-6 {
    margin-top: 11.6rem;
    padding-bottom: 12rem;
}

.partners-corporate-gifting .partners-corporate-gifting-section-6 .section-title {
    text-align: center;
}

.partners-corporate-gifting .partners-corporate-gifting-section-6 .accordian-container {
    margin-top: 7.2rem;
    padding-left: 32rem;
    padding-right: 32rem;
}

@media (max-width: 768px) {
    .partners-corporate-gifting .partners-corporate-gifting-section-6 .accordian-container {
        padding-left: 0;
        padding-right: 0;
    }
}

.partners-schedule-a-demo .partners-schedule-a-demo-outer {
    padding-left: 12rem;
    padding-right: 18rem;
    padding-bottom: 16.2rem;
}

@media (max-width: 768px) {
    .partners-schedule-a-demo .partners-schedule-a-demo-outer {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

.partners-schedule-a-demo .column-1 {
    padding-right: 5rem;
}

@media (max-width: 768px) {
    .partners-schedule-a-demo .column-1 {
        padding-right: 1.5rem;
    }
}

.partners-schedule-a-demo .column-1 .section-title {
    margin-top: 7rem;
    font-weight: 600;
    font-size: 42px;
    color: #083863;
}

@media (max-width: 768px) {
    .partners-schedule-a-demo .column-1 .section-title {
        margin-top: 1rem;
    }
}

.partners-schedule-a-demo .column-1 .first-name {
    padding-right: 0.8rem;
}

.partners-schedule-a-demo .column-1 .last-name {
    padding-left: 0.8rem;
}

.partners-schedule-a-demo .column-1 .input-container {
    margin-top: 3rem;
}

.partners-schedule-a-demo .column-1 .input-container input,
.partners-schedule-a-demo .column-1 .input-container textarea {
    margin-top: 1.6rem;
    width: 100%;
    background: #f7f7f7;
    border-radius: 5px;
    font-size: 15px;
    line-height: 19px;
    color: #999;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    padding-left: 1.6rem;
    border: 0;
}

.partners-schedule-a-demo .column-1 .input-container input::-webkit-input-placeholder,
.partners-schedule-a-demo .column-1 .input-container textarea::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 19px;
    color: #999;
}

.partners-schedule-a-demo .column-1 .input-container input:-ms-input-placeholder,
.partners-schedule-a-demo .column-1 .input-container textarea:-ms-input-placeholder {
    font-size: 15px;
    line-height: 19px;
    color: #999;
}

.partners-schedule-a-demo .column-1 .input-container input::-ms-input-placeholder,
.partners-schedule-a-demo .column-1 .input-container textarea::-ms-input-placeholder {
    font-size: 15px;
    line-height: 19px;
    color: #999;
}

.partners-schedule-a-demo .column-1 .input-container input::placeholder,
.partners-schedule-a-demo .column-1 .input-container textarea::placeholder {
    font-size: 15px;
    line-height: 19px;
    color: #999;
}

.partners-schedule-a-demo .column-1 .input-container textarea {
    margin-top: 2.6rem;
    min-height: 96px;
}

.partners-schedule-a-demo .column-1 .input-container .button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 2.4rem;
}

.partners-schedule-a-demo .column-1 .input-container .button-container button {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

.partners-schedule-a-demo .column-1 .input-container .iti--allow-dropdown {
    margin-top: 1.6rem;
    width: 100%;
}

.partners-schedule-a-demo .column-1 .input-container .phone-input {
    padding-left: 5.6rem;
}

.partners-schedule-a-demo .column-1 .checkbox-container {
    margin-top: 1.8rem;
}

.partners-schedule-a-demo .column-1 .checkbox-container .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 15px;
    line-height: 19px;
    color: #666;
}

.partners-schedule-a-demo .column-1 .checkbox-container .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.partners-schedule-a-demo .column-1 .checkbox-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    border: 2px solid #999;
}

.partners-schedule-a-demo .column-1 .checkbox-container .container:hover input~.checkmark {
    background-color: #ccc;
}

.partners-schedule-a-demo .column-1 .checkbox-container .container input:checked~.checkmark {
    background-color: #00bbb4;
    border-radius: 4px;
    border: 0;
}

.partners-schedule-a-demo .column-1 .checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.partners-schedule-a-demo .column-1 .checkbox-container .container input:checked~.checkmark:after {
    display: block;
}

.partners-schedule-a-demo .column-1 .checkbox-container .container .checkmark:after {
    left: 8px;
    top: 2px;
    width: 8px;
    height: 16px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.partners-schedule-a-demo .column-2 {
    padding-left: 5rem;
}

@media (max-width: 768px) {
    .partners-schedule-a-demo .column-2 {
        padding-left: 1.5rem;
    }
}

.partners-schedule-a-demo .column-2 .section-subtitle {
    margin-top: 10rem;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    color: #666;
}

.partners-schedule-a-demo .column-2 .partner-logos-container {
    margin-top: 4.2rem;
}

.partners-schedule-a-demo .column-2 .partner-logos-container .col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}

.partners-jewellers {
    background: 0;
    overflow-x: hidden;
}

.partners-jewellers .container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.partners-jewellers .row {
    margin-left: 0;
    margin-right: 0;
}

.partners-jewellers .navbar {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.partners-jewellers .section-title {
    color: #083863;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 1.6rem;
}

.partners-jewellers .section-subtitle {
    font-size: 16px;
    line-height: 21px;
    color: #666;
}

.partners-jewellers .partners-jewellers-section-1 {
    padding: 0 6.7%;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-1 {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

.partners-jewellers .partners-jewellers-section-1 .column-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 0;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-1 .column-1 {
        padding-left: 0;
    }
}

.partners-jewellers .partners-jewellers-section-1 .column-1 .content-container {
    width: 88%;
    margin-top: -14rem;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-1 .column-1 .content-container {
        width: auto;
    }
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-1 .column-1 .content-container {
        margin-top: 0;
    }
}

.partners-jewellers .partners-jewellers-section-1 .column-1 .content-container h1 {
    font-size: 48px;
    font-weight: 800;
}

.partners-jewellers .partners-jewellers-section-1 .column-1 .content-container p {
    width: 80%;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-1 .column-1 .content-container p {
        width: auto;
    }
}

.partners-jewellers .partners-jewellers-section-1 .column-1 .content-container .button-video-container,
.partners-jewellers .partners-jewellers-section-1 .column-1 .content-container h1,
.partners-jewellers .partners-jewellers-section-1 .column-1 .content-container p {
    opacity: 1;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-1 .column-2 {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-1 .column-2 .image-container {
        width: 100% !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.partners-jewellers .partners-jewellers-section-1 .column-2 .image-container img {
    max-width: unset;
    position: relative;
    right: 103px;
    z-index: -1;
}

@media (max-width: 992px) {
    .partners-jewellers .partners-jewellers-section-1 .column-2 .image-container img {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-1 .column-2 .image-container img {
        right: 6rem;
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
    }
}

.partners-jewellers .partners-jewellers-section-2 {
    position: relative;
    padding-left: 11.8rem;
    padding-right: 11.8rem;
    padding-top: 12rem;
    padding-bottom: 12rem;
    /* background: url(../images/pages/partners-jewellers/bg-slant-grey.svg); */
    background-size: cover;
    text-align: center;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-2 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.partners-jewellers .partners-jewellers-section-2 .background-dots {
    position: absolute;
    bottom: 28.8rem;
    left: 6.8rem;
    z-index: 1;
}

.partners-jewellers .partners-jewellers-section-2>p {
    width: 61%;
    margin: 96px auto 48px;
    color: #083863;
    font-size: 24px;
    font-weight: 400;
}

@media (max-width: 480px) {
    .partners-jewellers .partners-jewellers-section-2>p {
        width: 100%;
        margin-top: 4rem;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 4rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.partners-jewellers .partners-jewellers-section-2 .section-title {
    margin-bottom: 54px;
    color: #083863;
    font-size: 36px;
    font-weight: 700;
}

.partners-jewellers .partners-jewellers-section-2 .cards-container {
    position: relative;
    z-index: 2;
    text-align: left;
}

.partners-jewellers .partners-jewellers-section-2 .cards-container .card-outer {
    margin-top: 2.4rem;
    padding-right: 1.2rem;
    padding-left: 1.2rem;
}

.partners-jewellers .partners-jewellers-section-2 .cards-container .card-inner {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
    background: #fff;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 230px;
}

.partners-jewellers .partners-jewellers-section-2 .cards-container .card-info {
    margin-top: 24px;
    color: #666;
    font-size: 16px;
    font-weight: 400;
}

.partners-jewellers .partners-jewellers-section-3 {
    padding: 96px 0;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-3 {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        padding-top: 4.6rem;
        padding-bottom: 4.6rem;
    }
}

.partners-jewellers .partners-jewellers-section-3 h1 {
    margin-bottom: 34px;
    color: #083863;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.partners-jewellers .partners-jewellers-section-3 ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.partners-jewellers .partners-jewellers-section-3 ul li:nth-of-type(1) {
    margin-right: 39px;
}

.partners-jewellers .partners-jewellers-section-3 ul img {
    min-height: 4.8rem;
}

.partners-jewellers .partners-jewellers-section-4 {
    margin-top: 14rem;
    padding-bottom: 15rem;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-4 {
        margin-top: 8rem;
        padding-bottom: 8rem;
    }
}

.partners-jewellers .partners-jewellers-section-4 .column-1 {
    position: relative;
    margin-top: 0;
}

.partners-jewellers .partners-jewellers-section-4 .column-1 .section-title {
    display: none;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-4 .column-1 .section-title {
        display: block;
        margin-top: -16rem;
    }
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-4 .column-1 {
        margin-top: 14rem;
    }
}

.partners-jewellers .partners-jewellers-section-4 .column-2 {
    margin-top: 10rem;
    display: block;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-4 .column-2 {
        margin-top: 24rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-4 .column-2 .section-title {
        display: none;
    }
}

.partners-jewellers .partners-jewellers-section-4 .column-2 .list-container {
    margin-top: 6.8rem;
}

.partners-jewellers .partners-jewellers-section-4 .column-2 .list-container .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1.8rem;
}

.partners-jewellers .partners-jewellers-section-4 .column-2 .list-container .list-item.active img {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
}

.partners-jewellers .partners-jewellers-section-4 .column-2 .list-container .list-item img {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.partners-jewellers .partners-jewellers-section-4 .column-2 .list-container .list-item span {
    margin-left: 1rem;
    font-size: 22px;
    color: #333;
}

.partners-jewellers .partners-jewellers-section-5 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.partners-jewellers .partners-jewellers-section-5 .section-title {
    margin-bottom: 55px;
    color: #083863;
    font-size: 36px;
    font-weight: 700;
}

.partners-jewellers .partners-jewellers-section-5 .column-2 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.partners-jewellers .partners-jewellers-section-5 .column-2 .list-item {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
}

.partners-jewellers .partners-jewellers-section-5 .column-2 .button-container {
    text-align: center;
}

.partners-jewellers .partners-jewellers-section-6 {
    margin-top: 11.6rem;
    padding-bottom: 12rem;
}

.partners-jewellers .partners-jewellers-section-6 .section-title {
    text-align: center;
}

.partners-jewellers .partners-jewellers-section-6 .accordian-container {
    margin-top: 7.2rem;
    padding-left: 32rem;
    padding-right: 32rem;
}

@media (max-width: 768px) {
    .partners-jewellers .partners-jewellers-section-6 .accordian-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.about-us {
    /* background: url(../images/pages/home-page-new/header-slant-bg-mobile.svg); */
    background-repeat: no-repeat;
    background-size: 100%;
}

@media (min-width: 992px) {
    .about-us {
        /* background: url(../images/pages/home-page-new/header-slant-bg-desktop.svg); */
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.about-us .banner {
    padding-bottom: 120px;
}

@media (min-width: 992px) {
    .about-us .banner {
        padding-right: 18rem;
        padding-left: 15rem;
    }
}

@media (min-width: 992px) {
    .about-us .banner .column-1 {
        margin-top: 7.6rem;
    }
}

@media (min-width: 992px) {
    .about-us .banner .column-2 {
        margin-top: 6.6rem;
    }
}

@media (min-width: 992px) {
    .about-us .banner {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        height: 90vh;
    }
}

.about-us .banner .image-container {
    padding-top: 28px;
    text-align: center;
}

@media (min-width: 992px) {
    .about-us .banner .image-container {
        padding-top: 0;
    }
}

.about-us .banner p {
    font-weight: 400;
    font-size: 16px;
    color: #083863;
}

.about-us .banner .goal {
    margin: 36px 0;
    font-weight: 300;
    font-size: 30px;
    line-height: 1.2;
}

.about-us .banner .primary-button {
    display: none;
}

@media (min-width: 992px) {
    .about-us .banner .primary-button {
        display: block;
        margin-top: 4.8rem;
    }
}

.about-us .about-us-partners-section {
    margin-top: 12rem;
}

@media (min-width: 992px) {
    .about-us .about-us-partners-section {
        padding-left: 18.2rem;
        padding-right: 18.2rem;
        margin-top: 4rem;
    }
}

.about-us .about-us-partners-section h1 {
    font-weight: 800;
    font-size: 30px;
    line-height: 1.25;
    color: #083863;
}

@media (min-width: 992px) {
    .about-us .about-us-partners-section h1 {
        text-align: center;
    }
}

.about-us .about-us-partners-section .subtitle {
    margin-top: 4.2rem;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #083863;
}

@media (min-width: 992px) {
    .about-us .about-us-partners-section .subtitle {
        margin-top: 7.2rem;
        text-align: center;
    }
}

.about-us .about-us-partners-section .grid-1 .image-container,
.about-us .about-us-partners-section .grid-2 .image-container {
    margin-bottom: 3.2rem;
}

@media (min-width: 992px) {
    .about-us .about-us-partners-section .grid-1 .image-container,
    .about-us .about-us-partners-section .grid-2 .image-container {
        margin-bottom: 5.4rem;
    }
}

.about-us .about-us-partners-section .grid-1 .spacer,
.about-us .about-us-partners-section .grid-2 .spacer {
    margin-top: 0;
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .about-us .team-work-with-us-container {
        margin-top: 12rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding-left: 23rem;
        padding-right: 23rem;
    }
}

.about-us .news {
    margin-top: 3.8rem;
    margin-bottom: 11rem;
}

@media (min-width: 992px) {
    .about-us .news {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }
}

@media (min-width: 992px) {
    .about-us .news h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
    }
}

.error-page {
    /* background: url(../images/pages/home-page-new/header-slant-bg-mobile.svg); */
    background-repeat: no-repeat;
    background-size: 100%;
}

@media (min-width: 992px) {
    .error-page {
        /* background: url(../images/pages/home-page-new/header-slant-bg-desktop.svg); */
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.error-page .error-page-section-1 {
    padding-bottom: 3rem;
}

@media (min-width: 992px) {
    .error-page .error-page-section-1 {
        padding-left: 15rem;
        padding-right: 9.8rem;
        height: 100vh;
        padding-bottom: 0;
    }
}

.error-page .column-1 {
    margin-top: 3rem;
}

@media (min-width: 992px) {
    .error-page .column-1 {
        margin-top: 11.6rem;
    }
}

.error-page .column-1 .title {
    font-weight: 800;
    font-size: 48px;
    line-height: 1.25;
    color: #083863;
}

.error-page .column-1 .subtitle {
    margin-top: 2.4rem;
    font-weight: 300;
    font-size: 30px;
    line-height: 1.25;
}

.error-page .column-1 .content {
    margin-top: 2.4rem;
    font-size: 16px;
    line-height: 1.5;
    color: #666;
}

.error-page .column-1 .primary-button {
    margin-top: 4.8rem;
}

.error-page .column-2 {
    margin-top: 3rem;
}

@media (min-width: 992px) {
    .error-page .column-2 {
        margin-top: 12rem;
    }
}

@-webkit-keyframes bounce {
    20%,
    53%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }
    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

@keyframes bounce {
    20%,
    53%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }
    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}

@-webkit-keyframes flash {
    50%,
    from,
    to {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}

@keyframes flash {
    50%,
    from,
    to {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}

.flash {
    -webkit-animation-name: flash;
    animation-name: flash;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

@-webkit-keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
}

@-webkit-keyframes shake {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes shake {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
}

@-webkit-keyframes headShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    6.5% {
        -webkit-transform: translateX(-6px) rotateY(-9deg);
        transform: translateX(-6px) rotateY(-9deg);
    }
    18.5% {
        -webkit-transform: translateX(5px) rotateY(7deg);
        transform: translateX(5px) rotateY(7deg);
    }
    31.5% {
        -webkit-transform: translateX(-3px) rotateY(-5deg);
        transform: translateX(-3px) rotateY(-5deg);
    }
    43.5% {
        -webkit-transform: translateX(2px) rotateY(3deg);
        transform: translateX(2px) rotateY(3deg);
    }
    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes headShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    6.5% {
        -webkit-transform: translateX(-6px) rotateY(-9deg);
        transform: translateX(-6px) rotateY(-9deg);
    }
    18.5% {
        -webkit-transform: translateX(5px) rotateY(7deg);
        transform: translateX(5px) rotateY(7deg);
    }
    31.5% {
        -webkit-transform: translateX(-3px) rotateY(-5deg);
        transform: translateX(-3px) rotateY(-5deg);
    }
    43.5% {
        -webkit-transform: translateX(2px) rotateY(3deg);
        transform: translateX(2px) rotateY(3deg);
    }
    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.headShake {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: headShake;
    animation-name: headShake;
}

@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }
    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }
    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

.swing {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
}

@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
}

@-webkit-keyframes wobble {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes wobble {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
}

@-webkit-keyframes jello {
    11.1%,
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    77.7% {
        -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
        transform: skewX(0.39062deg) skewY(0.39062deg);
    }
    88.8% {
        -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
        transform: skewX(-0.19531deg) skewY(-0.19531deg);
    }
}

@keyframes jello {
    11.1%,
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    77.7% {
        -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
        transform: skewX(0.39062deg) skewY(0.39062deg);
    }
    88.8% {
        -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
        transform: skewX(-0.19531deg) skewY(-0.19531deg);
    }
}

.jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-transform-origin: center;
    transform-origin: center;
}

@-webkit-keyframes heartBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    14% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    28% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    42% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes heartBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    14% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    28% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    42% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.heartBeat {
    -webkit-animation-name: heartBeat;
    animation-name: heartBeat;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes bounceIn {
    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounceIn {
    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.bounceIn {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInDown {
    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInLeft {
    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInRight {
    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInUp {
    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

@keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

.bounceOut {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
}

@keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
}

.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
}

.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

@keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
}

@keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
}

.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
}

@keyframes fadeOutDownBig {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
}

.fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes fadeOutLeftBig {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
}

.fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

@keyframes fadeOutRightBig {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
}

@keyframes fadeOutUpBig {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
}

.fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
    from {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    40% {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    50% {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    80% {
        -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    to {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

@keyframes flip {
    from {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    40% {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    50% {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    80% {
        -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    to {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

.animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip;
}

@-webkit-keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

.flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
}

@-webkit-keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

.flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

.flipOutX {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0;
    }
}

@keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0;
    }
}

.flipOutY {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }
    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }
    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
    from {
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0;
    }
}

@keyframes lightSpeedOut {
    from {
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0;
    }
}

.lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0;
    }
    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0;
    }
}

@keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0;
    }
}

.rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }
}

.rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

.rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

.rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1;
    }
    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0;
    }
}

.rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}

@keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}

.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-name: hinge;
    animation-name: hinge;
}

@-webkit-keyframes jackInTheBox {
    from {
        opacity: 0;
        -webkit-transform: scale(0.1) rotate(30deg);
        transform: scale(0.1) rotate(30deg);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
    }
    50% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    70% {
        -webkit-transform: rotate(3deg);
        transform: rotate(3deg);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes jackInTheBox {
    from {
        opacity: 0;
        -webkit-transform: scale(0.1) rotate(30deg);
        transform: scale(0.1) rotate(30deg);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
    }
    50% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    70% {
        -webkit-transform: rotate(3deg);
        transform: rotate(3deg);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.jackInTheBox {
    -webkit-animation-name: jackInTheBox;
    animation-name: jackInTheBox;
}

@-webkit-keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn;
}

@-webkit-keyframes rollOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
}

@keyframes rollOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
}

.rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}

@keyframes zoomOut {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}

.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
        transform: scale(0.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center;
    }
}

@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
        transform: scale(0.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center;
    }
}

.zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
        transform: scale(0.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center;
    }
}

@keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
        transform: scale(0.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center;
    }
}

.zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

.slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animated.delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.animated.delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.animated.delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.animated.delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.animated.delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

.animated.fast {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
}

.animated.faster {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
}

.animated.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

.animated.slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
}

@media (print),
(prefers-reduced-motion: reduce) {
    .animated {
        -webkit-animation-duration: 1ms !important;
        animation-duration: 1ms !important;
        -webkit-transition-duration: 1ms !important;
        transition-duration: 1ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
    }
}

.iti {
    position: relative;
    display: inline-block;
}

.iti * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.iti__hide {
    display: none;
}

.iti__v-hide {
    visibility: hidden;
}

.iti input,
.iti input[type="tel"],
.iti input[type="text"] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0;
}

.iti__flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px;
}

.iti__selected-flag {
    z-index: 1;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    padding: 0 6px 0 8px;
}

.iti__arrow {
    margin-left: 6px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
}

.iti__arrow--up {
    border-top: 0;
    border-bottom: 4px solid #555;
}

.iti__country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: 1px solid #ccc;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
    bottom: 100%;
    margin-bottom: -1px;
}

@media (max-width: 500px) {
    .iti__country-list {
        white-space: normal;
    }
}

.iti__flag-box {
    display: inline-block;
    width: 20px;
}

.iti__divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
}

.iti__country {
    padding: 5px 10px;
    outline: 0;
}

.iti__dial-code {
    color: #999;
}

.iti__country.iti__highlight {
    background-color: rgba(0, 0, 0, 0.05);
}

.iti__country-name,
.iti__dial-code,
.iti__flag-box {
    vertical-align: middle;
}

.iti__country-name,
.iti__flag-box {
    margin-right: 6px;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type="tel"],
.iti--allow-dropdown input[type="text"],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type="tel"],
.iti--separate-dial-code input[type="text"] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0;
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
    right: auto;
    left: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
    cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input[disabled]+.iti__flag-container:hover,
.iti--allow-dropdown input[readonly]+.iti__flag-container:hover {
    cursor: default;
}

.iti--allow-dropdown input[disabled]+.iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly]+.iti__flag-container:hover .iti__selected-flag {
    background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
    background-color: rgba(0, 0, 0, 0.05);
}

.iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 6px;
}

.iti--container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px;
}

.iti--container:hover {
    cursor: pointer;
}

.iti-mobile .iti--container {
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    position: fixed;
}

.iti-mobile .iti__country-list {
    max-height: 100%;
    width: 100%;
}

.iti-mobile .iti__country {
    padding: 10px 10px;
    line-height: 1.5em;
}

.iti__flag {
    width: 20px;
}

.iti__flag.iti__be {
    width: 18px;
}

.iti__flag.iti__ch {
    width: 15px;
}

.iti__flag.iti__mc {
    width: 19px;
}

.iti__flag.iti__ne {
    width: 18px;
}

.iti__flag.iti__np {
    width: 13px;
}

.iti__flag.iti__va {
    width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .iti__flag {
        background-size: 5652px 15px;
    }
}

.iti__flag.iti__ac {
    height: 10px;
    background-position: 0 0;
}

.iti__flag.iti__ad {
    height: 14px;
    background-position: -22px 0;
}

.iti__flag.iti__ae {
    height: 10px;
    background-position: -44px 0;
}

.iti__flag.iti__af {
    height: 14px;
    background-position: -66px 0;
}

.iti__flag.iti__ag {
    height: 14px;
    background-position: -88px 0;
}

.iti__flag.iti__ai {
    height: 10px;
    background-position: -110px 0;
}

.iti__flag.iti__al {
    height: 15px;
    background-position: -132px 0;
}

.iti__flag.iti__am {
    height: 10px;
    background-position: -154px 0;
}

.iti__flag.iti__ao {
    height: 14px;
    background-position: -176px 0;
}

.iti__flag.iti__aq {
    height: 14px;
    background-position: -198px 0;
}

.iti__flag.iti__ar {
    height: 13px;
    background-position: -220px 0;
}

.iti__flag.iti__as {
    height: 10px;
    background-position: -242px 0;
}

.iti__flag.iti__at {
    height: 14px;
    background-position: -264px 0;
}

.iti__flag.iti__au {
    height: 10px;
    background-position: -286px 0;
}

.iti__flag.iti__aw {
    height: 14px;
    background-position: -308px 0;
}

.iti__flag.iti__ax {
    height: 13px;
    background-position: -330px 0;
}

.iti__flag.iti__az {
    height: 10px;
    background-position: -352px 0;
}

.iti__flag.iti__ba {
    height: 10px;
    background-position: -374px 0;
}

.iti__flag.iti__bb {
    height: 14px;
    background-position: -396px 0;
}

.iti__flag.iti__bd {
    height: 12px;
    background-position: -418px 0;
}

.iti__flag.iti__be {
    height: 15px;
    background-position: -440px 0;
}

.iti__flag.iti__bf {
    height: 14px;
    background-position: -460px 0;
}

.iti__flag.iti__bg {
    height: 12px;
    background-position: -482px 0;
}

.iti__flag.iti__bh {
    height: 12px;
    background-position: -504px 0;
}

.iti__flag.iti__bi {
    height: 12px;
    background-position: -526px 0;
}

.iti__flag.iti__bj {
    height: 14px;
    background-position: -548px 0;
}

.iti__flag.iti__bl {
    height: 14px;
    background-position: -570px 0;
}

.iti__flag.iti__bm {
    height: 10px;
    background-position: -592px 0;
}

.iti__flag.iti__bn {
    height: 10px;
    background-position: -614px 0;
}

.iti__flag.iti__bo {
    height: 14px;
    background-position: -636px 0;
}

.iti__flag.iti__bq {
    height: 14px;
    background-position: -658px 0;
}

.iti__flag.iti__br {
    height: 14px;
    background-position: -680px 0;
}

.iti__flag.iti__bs {
    height: 10px;
    background-position: -702px 0;
}

.iti__flag.iti__bt {
    height: 14px;
    background-position: -724px 0;
}

.iti__flag.iti__bv {
    height: 15px;
    background-position: -746px 0;
}

.iti__flag.iti__bw {
    height: 14px;
    background-position: -768px 0;
}

.iti__flag.iti__by {
    height: 10px;
    background-position: -790px 0;
}

.iti__flag.iti__bz {
    height: 14px;
    background-position: -812px 0;
}

.iti__flag.iti__ca {
    height: 10px;
    background-position: -834px 0;
}

.iti__flag.iti__cc {
    height: 10px;
    background-position: -856px 0;
}

.iti__flag.iti__cd {
    height: 15px;
    background-position: -878px 0;
}

.iti__flag.iti__cf {
    height: 14px;
    background-position: -900px 0;
}

.iti__flag.iti__cg {
    height: 14px;
    background-position: -922px 0;
}

.iti__flag.iti__ch {
    height: 15px;
    background-position: -944px 0;
}

.iti__flag.iti__ci {
    height: 14px;
    background-position: -961px 0;
}

.iti__flag.iti__ck {
    height: 10px;
    background-position: -983px 0;
}

.iti__flag.iti__cl {
    height: 14px;
    background-position: -1005px 0;
}

.iti__flag.iti__cm {
    height: 14px;
    background-position: -1027px 0;
}

.iti__flag.iti__cn {
    height: 14px;
    background-position: -1049px 0;
}

.iti__flag.iti__co {
    height: 14px;
    background-position: -1071px 0;
}

.iti__flag.iti__cp {
    height: 14px;
    background-position: -1093px 0;
}

.iti__flag.iti__cr {
    height: 12px;
    background-position: -1115px 0;
}

.iti__flag.iti__cu {
    height: 10px;
    background-position: -1137px 0;
}

.iti__flag.iti__cv {
    height: 12px;
    background-position: -1159px 0;
}

.iti__flag.iti__cw {
    height: 14px;
    background-position: -1181px 0;
}

.iti__flag.iti__cx {
    height: 10px;
    background-position: -1203px 0;
}

.iti__flag.iti__cy {
    height: 14px;
    background-position: -1225px 0;
}

.iti__flag.iti__cz {
    height: 14px;
    background-position: -1247px 0;
}

.iti__flag.iti__de {
    height: 12px;
    background-position: -1269px 0;
}

.iti__flag.iti__dg {
    height: 10px;
    background-position: -1291px 0;
}

.iti__flag.iti__dj {
    height: 14px;
    background-position: -1313px 0;
}

.iti__flag.iti__dk {
    height: 15px;
    background-position: -1335px 0;
}

.iti__flag.iti__dm {
    height: 10px;
    background-position: -1357px 0;
}

.iti__flag.iti__do {
    height: 14px;
    background-position: -1379px 0;
}

.iti__flag.iti__dz {
    height: 14px;
    background-position: -1401px 0;
}

.iti__flag.iti__ea {
    height: 14px;
    background-position: -1423px 0;
}

.iti__flag.iti__ec {
    height: 14px;
    background-position: -1445px 0;
}

.iti__flag.iti__ee {
    height: 13px;
    background-position: -1467px 0;
}

.iti__flag.iti__eg {
    height: 14px;
    background-position: -1489px 0;
}

.iti__flag.iti__eh {
    height: 10px;
    background-position: -1511px 0;
}

.iti__flag.iti__er {
    height: 10px;
    background-position: -1533px 0;
}

.iti__flag.iti__es {
    height: 14px;
    background-position: -1555px 0;
}

.iti__flag.iti__et {
    height: 10px;
    background-position: -1577px 0;
}

.iti__flag.iti__eu {
    height: 14px;
    background-position: -1599px 0;
}

.iti__flag.iti__fi {
    height: 12px;
    background-position: -1621px 0;
}

.iti__flag.iti__fj {
    height: 10px;
    background-position: -1643px 0;
}

.iti__flag.iti__fk {
    height: 10px;
    background-position: -1665px 0;
}

.iti__flag.iti__fm {
    height: 11px;
    background-position: -1687px 0;
}

.iti__flag.iti__fo {
    height: 15px;
    background-position: -1709px 0;
}

.iti__flag.iti__fr {
    height: 14px;
    background-position: -1731px 0;
}

.iti__flag.iti__ga {
    height: 15px;
    background-position: -1753px 0;
}

.iti__flag.iti__gb {
    height: 10px;
    background-position: -1775px 0;
}

.iti__flag.iti__gd {
    height: 12px;
    background-position: -1797px 0;
}

.iti__flag.iti__ge {
    height: 14px;
    background-position: -1819px 0;
}

.iti__flag.iti__gf {
    height: 14px;
    background-position: -1841px 0;
}

.iti__flag.iti__gg {
    height: 14px;
    background-position: -1863px 0;
}

.iti__flag.iti__gh {
    height: 14px;
    background-position: -1885px 0;
}

.iti__flag.iti__gi {
    height: 10px;
    background-position: -1907px 0;
}

.iti__flag.iti__gl {
    height: 14px;
    background-position: -1929px 0;
}

.iti__flag.iti__gm {
    height: 14px;
    background-position: -1951px 0;
}

.iti__flag.iti__gn {
    height: 14px;
    background-position: -1973px 0;
}

.iti__flag.iti__gp {
    height: 14px;
    background-position: -1995px 0;
}

.iti__flag.iti__gq {
    height: 14px;
    background-position: -2017px 0;
}

.iti__flag.iti__gr {
    height: 14px;
    background-position: -2039px 0;
}

.iti__flag.iti__gs {
    height: 10px;
    background-position: -2061px 0;
}

.iti__flag.iti__gt {
    height: 13px;
    background-position: -2083px 0;
}

.iti__flag.iti__gu {
    height: 11px;
    background-position: -2105px 0;
}

.iti__flag.iti__gw {
    height: 10px;
    background-position: -2127px 0;
}

.iti__flag.iti__gy {
    height: 12px;
    background-position: -2149px 0;
}

.iti__flag.iti__hk {
    height: 14px;
    background-position: -2171px 0;
}

.iti__flag.iti__hm {
    height: 10px;
    background-position: -2193px 0;
}

.iti__flag.iti__hn {
    height: 10px;
    background-position: -2215px 0;
}

.iti__flag.iti__hr {
    height: 10px;
    background-position: -2237px 0;
}

.iti__flag.iti__ht {
    height: 12px;
    background-position: -2259px 0;
}

.iti__flag.iti__hu {
    height: 10px;
    background-position: -2281px 0;
}

.iti__flag.iti__ic {
    height: 14px;
    background-position: -2303px 0;
}

.iti__flag.iti__id {
    height: 14px;
    background-position: -2325px 0;
}

.iti__flag.iti__ie {
    height: 10px;
    background-position: -2347px 0;
}

.iti__flag.iti__il {
    height: 15px;
    background-position: -2369px 0;
}

.iti__flag.iti__im {
    height: 10px;
    background-position: -2391px 0;
}

.iti__flag.iti__in {
    height: 14px;
    background-position: -2413px 0;
}

.iti__flag.iti__io {
    height: 10px;
    background-position: -2435px 0;
}

.iti__flag.iti__iq {
    height: 14px;
    background-position: -2457px 0;
}

.iti__flag.iti__ir {
    height: 12px;
    background-position: -2479px 0;
}

.iti__flag.iti__is {
    height: 15px;
    background-position: -2501px 0;
}

.iti__flag.iti__it {
    height: 14px;
    background-position: -2523px 0;
}

.iti__flag.iti__je {
    height: 12px;
    background-position: -2545px 0;
}

.iti__flag.iti__jm {
    height: 10px;
    background-position: -2567px 0;
}

.iti__flag.iti__jo {
    height: 10px;
    background-position: -2589px 0;
}

.iti__flag.iti__jp {
    height: 14px;
    background-position: -2611px 0;
}

.iti__flag.iti__ke {
    height: 14px;
    background-position: -2633px 0;
}

.iti__flag.iti__kg {
    height: 12px;
    background-position: -2655px 0;
}

.iti__flag.iti__kh {
    height: 13px;
    background-position: -2677px 0;
}

.iti__flag.iti__ki {
    height: 10px;
    background-position: -2699px 0;
}

.iti__flag.iti__km {
    height: 12px;
    background-position: -2721px 0;
}

.iti__flag.iti__kn {
    height: 14px;
    background-position: -2743px 0;
}

.iti__flag.iti__kp {
    height: 10px;
    background-position: -2765px 0;
}

.iti__flag.iti__kr {
    height: 14px;
    background-position: -2787px 0;
}

.iti__flag.iti__kw {
    height: 10px;
    background-position: -2809px 0;
}

.iti__flag.iti__ky {
    height: 10px;
    background-position: -2831px 0;
}

.iti__flag.iti__kz {
    height: 10px;
    background-position: -2853px 0;
}

.iti__flag.iti__la {
    height: 14px;
    background-position: -2875px 0;
}

.iti__flag.iti__lb {
    height: 14px;
    background-position: -2897px 0;
}

.iti__flag.iti__lc {
    height: 10px;
    background-position: -2919px 0;
}

.iti__flag.iti__li {
    height: 12px;
    background-position: -2941px 0;
}

.iti__flag.iti__lk {
    height: 10px;
    background-position: -2963px 0;
}

.iti__flag.iti__lr {
    height: 11px;
    background-position: -2985px 0;
}

.iti__flag.iti__ls {
    height: 14px;
    background-position: -3007px 0;
}

.iti__flag.iti__lt {
    height: 12px;
    background-position: -3029px 0;
}

.iti__flag.iti__lu {
    height: 12px;
    background-position: -3051px 0;
}

.iti__flag.iti__lv {
    height: 10px;
    background-position: -3073px 0;
}

.iti__flag.iti__ly {
    height: 10px;
    background-position: -3095px 0;
}

.iti__flag.iti__ma {
    height: 14px;
    background-position: -3117px 0;
}

.iti__flag.iti__mc {
    height: 15px;
    background-position: -3139px 0;
}

.iti__flag.iti__md {
    height: 10px;
    background-position: -3160px 0;
}

.iti__flag.iti__me {
    height: 10px;
    background-position: -3182px 0;
}

.iti__flag.iti__mf {
    height: 14px;
    background-position: -3204px 0;
}

.iti__flag.iti__mg {
    height: 14px;
    background-position: -3226px 0;
}

.iti__flag.iti__mh {
    height: 11px;
    background-position: -3248px 0;
}

.iti__flag.iti__mk {
    height: 10px;
    background-position: -3270px 0;
}

.iti__flag.iti__ml {
    height: 14px;
    background-position: -3292px 0;
}

.iti__flag.iti__mm {
    height: 14px;
    background-position: -3314px 0;
}

.iti__flag.iti__mn {
    height: 10px;
    background-position: -3336px 0;
}

.iti__flag.iti__mo {
    height: 14px;
    background-position: -3358px 0;
}

.iti__flag.iti__mp {
    height: 10px;
    background-position: -3380px 0;
}

.iti__flag.iti__mq {
    height: 14px;
    background-position: -3402px 0;
}

.iti__flag.iti__mr {
    height: 14px;
    background-position: -3424px 0;
}

.iti__flag.iti__ms {
    height: 10px;
    background-position: -3446px 0;
}

.iti__flag.iti__mt {
    height: 14px;
    background-position: -3468px 0;
}

.iti__flag.iti__mu {
    height: 14px;
    background-position: -3490px 0;
}

.iti__flag.iti__mv {
    height: 14px;
    background-position: -3512px 0;
}

.iti__flag.iti__mw {
    height: 14px;
    background-position: -3534px 0;
}

.iti__flag.iti__mx {
    height: 12px;
    background-position: -3556px 0;
}

.iti__flag.iti__my {
    height: 10px;
    background-position: -3578px 0;
}

.iti__flag.iti__mz {
    height: 14px;
    background-position: -3600px 0;
}

.iti__flag.iti__na {
    height: 14px;
    background-position: -3622px 0;
}

.iti__flag.iti__nc {
    height: 10px;
    background-position: -3644px 0;
}

.iti__flag.iti__ne {
    height: 15px;
    background-position: -3666px 0;
}

.iti__flag.iti__nf {
    height: 10px;
    background-position: -3686px 0;
}

.iti__flag.iti__ng {
    height: 10px;
    background-position: -3708px 0;
}

.iti__flag.iti__ni {
    height: 12px;
    background-position: -3730px 0;
}

.iti__flag.iti__nl {
    height: 14px;
    background-position: -3752px 0;
}

.iti__flag.iti__no {
    height: 15px;
    background-position: -3774px 0;
}

.iti__flag.iti__np {
    height: 15px;
    background-position: -3796px 0;
}

.iti__flag.iti__nr {
    height: 10px;
    background-position: -3811px 0;
}

.iti__flag.iti__nu {
    height: 10px;
    background-position: -3833px 0;
}

.iti__flag.iti__nz {
    height: 10px;
    background-position: -3855px 0;
}

.iti__flag.iti__om {
    height: 10px;
    background-position: -3877px 0;
}

.iti__flag.iti__pa {
    height: 14px;
    background-position: -3899px 0;
}

.iti__flag.iti__pe {
    height: 14px;
    background-position: -3921px 0;
}

.iti__flag.iti__pf {
    height: 14px;
    background-position: -3943px 0;
}

.iti__flag.iti__pg {
    height: 15px;
    background-position: -3965px 0;
}

.iti__flag.iti__ph {
    height: 10px;
    background-position: -3987px 0;
}

.iti__flag.iti__pk {
    height: 14px;
    background-position: -4009px 0;
}

.iti__flag.iti__pl {
    height: 13px;
    background-position: -4031px 0;
}

.iti__flag.iti__pm {
    height: 14px;
    background-position: -4053px 0;
}

.iti__flag.iti__pn {
    height: 10px;
    background-position: -4075px 0;
}

.iti__flag.iti__pr {
    height: 14px;
    background-position: -4097px 0;
}

.iti__flag.iti__ps {
    height: 10px;
    background-position: -4119px 0;
}

.iti__flag.iti__pt {
    height: 14px;
    background-position: -4141px 0;
}

.iti__flag.iti__pw {
    height: 13px;
    background-position: -4163px 0;
}

.iti__flag.iti__py {
    height: 11px;
    background-position: -4185px 0;
}

.iti__flag.iti__qa {
    height: 8px;
    background-position: -4207px 0;
}

.iti__flag.iti__re {
    height: 14px;
    background-position: -4229px 0;
}

.iti__flag.iti__ro {
    height: 14px;
    background-position: -4251px 0;
}

.iti__flag.iti__rs {
    height: 14px;
    background-position: -4273px 0;
}

.iti__flag.iti__ru {
    height: 14px;
    background-position: -4295px 0;
}

.iti__flag.iti__rw {
    height: 14px;
    background-position: -4317px 0;
}

.iti__flag.iti__sa {
    height: 14px;
    background-position: -4339px 0;
}

.iti__flag.iti__sb {
    height: 10px;
    background-position: -4361px 0;
}

.iti__flag.iti__sc {
    height: 10px;
    background-position: -4383px 0;
}

.iti__flag.iti__sd {
    height: 10px;
    background-position: -4405px 0;
}

.iti__flag.iti__se {
    height: 13px;
    background-position: -4427px 0;
}

.iti__flag.iti__sg {
    height: 14px;
    background-position: -4449px 0;
}

.iti__flag.iti__sh {
    height: 10px;
    background-position: -4471px 0;
}

.iti__flag.iti__si {
    height: 10px;
    background-position: -4493px 0;
}

.iti__flag.iti__sj {
    height: 15px;
    background-position: -4515px 0;
}

.iti__flag.iti__sk {
    height: 14px;
    background-position: -4537px 0;
}

.iti__flag.iti__sl {
    height: 14px;
    background-position: -4559px 0;
}

.iti__flag.iti__sm {
    height: 15px;
    background-position: -4581px 0;
}

.iti__flag.iti__sn {
    height: 14px;
    background-position: -4603px 0;
}

.iti__flag.iti__so {
    height: 14px;
    background-position: -4625px 0;
}

.iti__flag.iti__sr {
    height: 14px;
    background-position: -4647px 0;
}

.iti__flag.iti__ss {
    height: 10px;
    background-position: -4669px 0;
}

.iti__flag.iti__st {
    height: 10px;
    background-position: -4691px 0;
}

.iti__flag.iti__sv {
    height: 12px;
    background-position: -4713px 0;
}

.iti__flag.iti__sx {
    height: 14px;
    background-position: -4735px 0;
}

.iti__flag.iti__sy {
    height: 14px;
    background-position: -4757px 0;
}

.iti__flag.iti__sz {
    height: 14px;
    background-position: -4779px 0;
}

.iti__flag.iti__ta {
    height: 10px;
    background-position: -4801px 0;
}

.iti__flag.iti__tc {
    height: 10px;
    background-position: -4823px 0;
}

.iti__flag.iti__td {
    height: 14px;
    background-position: -4845px 0;
}

.iti__flag.iti__tf {
    height: 14px;
    background-position: -4867px 0;
}

.iti__flag.iti__tg {
    height: 13px;
    background-position: -4889px 0;
}

.iti__flag.iti__th {
    height: 14px;
    background-position: -4911px 0;
}

.iti__flag.iti__tj {
    height: 10px;
    background-position: -4933px 0;
}

.iti__flag.iti__tk {
    height: 10px;
    background-position: -4955px 0;
}

.iti__flag.iti__tl {
    height: 10px;
    background-position: -4977px 0;
}

.iti__flag.iti__tm {
    height: 14px;
    background-position: -4999px 0;
}

.iti__flag.iti__tn {
    height: 14px;
    background-position: -5021px 0;
}

.iti__flag.iti__to {
    height: 10px;
    background-position: -5043px 0;
}

.iti__flag.iti__tr {
    height: 14px;
    background-position: -5065px 0;
}

.iti__flag.iti__tt {
    height: 12px;
    background-position: -5087px 0;
}

.iti__flag.iti__tv {
    height: 10px;
    background-position: -5109px 0;
}

.iti__flag.iti__tw {
    height: 14px;
    background-position: -5131px 0;
}

.iti__flag.iti__tz {
    height: 14px;
    background-position: -5153px 0;
}

.iti__flag.iti__ua {
    height: 14px;
    background-position: -5175px 0;
}

.iti__flag.iti__ug {
    height: 14px;
    background-position: -5197px 0;
}

.iti__flag.iti__um {
    height: 11px;
    background-position: -5219px 0;
}

.iti__flag.iti__un {
    height: 14px;
    background-position: -5241px 0;
}

.iti__flag.iti__us {
    height: 11px;
    background-position: -5263px 0;
}

.iti__flag.iti__uy {
    height: 14px;
    background-position: -5285px 0;
}

.iti__flag.iti__uz {
    height: 10px;
    background-position: -5307px 0;
}

.iti__flag.iti__va {
    height: 15px;
    background-position: -5329px 0;
}

.iti__flag.iti__vc {
    height: 14px;
    background-position: -5346px 0;
}

.iti__flag.iti__ve {
    height: 14px;
    background-position: -5368px 0;
}

.iti__flag.iti__vg {
    height: 10px;
    background-position: -5390px 0;
}

.iti__flag.iti__vi {
    height: 14px;
    background-position: -5412px 0;
}

.iti__flag.iti__vn {
    height: 14px;
    background-position: -5434px 0;
}

.iti__flag.iti__vu {
    height: 12px;
    background-position: -5456px 0;
}

.iti__flag.iti__wf {
    height: 14px;
    background-position: -5478px 0;
}

.iti__flag.iti__ws {
    height: 10px;
    background-position: -5500px 0;
}

.iti__flag.iti__xk {
    height: 15px;
    background-position: -5522px 0;
}

.iti__flag.iti__ye {
    height: 14px;
    background-position: -5544px 0;
}

.iti__flag.iti__yt {
    height: 14px;
    background-position: -5566px 0;
}

.iti__flag.iti__za {
    height: 14px;
    background-position: -5588px 0;
}

.iti__flag.iti__zm {
    height: 14px;
    background-position: -5610px 0;
}

.iti__flag.iti__zw {
    height: 10px;
    background-position: -5632px 0;
}

.iti__flag {
    height: 15px;
    -webkit-box-shadow: 0 0 1px 0 #888;
    box-shadow: 0 0 1px 0 #888;
    /* background-image: url(../images/global/flags.png); */
    background-repeat: no-repeat;
    background-color: #dbdbdb;
    background-position: 20px 0;
}

.maxErrMsg {
    position: absolute;
    bottom: 29%;
    left: 5%;
    font-size: 12px;
    color: #fff;
}

.disabled-timer {
    color: #b2b1b1 !important;
    cursor: not-allowed !important;
}

.btn-txt {
    color: #fff !important;
    opacity: 1 !important;
    padding: 0px !important;
    font-size: inherit !important;
    font-weight: inherit !important;
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .iti__flag {
        /* background-image: url(../images/global/flags@2x.png); */
    }
}

.iti__flag.iti__np {
    background-color: transparent;
}

.owl-carousel .owl-dots .owl-dot {
    width: 8px;
    height: 8px;
    background: #ddd;
    border-radius: 50%;
    margin-right: 0.8rem;
}

.owl-carousel .owl-dots .owl-dot.active {
    background: #00bbb4;
}

#user-name {
    color: #333;
    font-family: "Sarabun Medium";
    font-size: 18px;
    font-weight: 300;
}

.profile-link a {
    color: #00bbb4 !important;
    font-size: 14px !important;
}

.otp-input svg,
line {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
}

.otp-input input[maxlength="6"] {
    border: 0;
    letter-spacing: 65px;
    outline: none;
    background: transparent;
    border: none;
}

.otp-input input[maxlength="6"]+svg line {
    stroke-dasharray: 22;
    stroke-dashoffset: 0;
}

.otp-input label {
    font-size: 16px;
    color: #b2b1b1;
}

.otp-input1 {
    display: flex;
    justify-content: space-between;
}

.otp-input1 input {
    background: transparent;
    border: none;
    text-align: center;
    font-size: 40px;
    color: #00bbb4;
    width: 11%;
    border-bottom: 1px solid #ccc;
    outline: none;
    border-radius: 0px;
    padding: 0px;
}

.otp-input1 input:focus {
    border-bottom: 1.5px solid #decc62;
    color: #decc62;
}

.switch {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 25px;
    border-radius: 20px;
    border: 3px solid #ccc;
    background: transperent;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
}

.switch::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 2px;
    width: 16px;
    height: 17px;
    background: #ccc;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

input:checked+.switch {
    background: transperent;
    border-color: #00bbb4;
}

input:checked+.switch::before {
    left: 27px;
    background: #00bbb4;
}

input:checked+.switch:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

.whatsapp-container {
    display: flex;
}

.notify-whatsapp {
    color: #222;
    opacity: 0.6;
    font-size: 18px;
    flex: 144%;
    padding-right: 1%;
}

.whtsapp-logo {
    height: 29px;
    margin-right: 2%;
}

@media only screen and (min-width: 768px) {
    .dropdown-menu.nav-dropdown {
        position: absolute !important;
        min-width: 220px;
        padding: 0 17px;
        border: none;
    }
    .dropdown-menu {
        padding: 0 20px;
        min-width: 220px;
        background-color: #fff;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        background-clip: inherit;
    }
    .dropdown-menu>li {
        -webit-transition: border-color 0.4s;
        transition: border-color 0.4s;
        border-top: 1px solid #404455;
    }
    .dropdown-menu>li:first-child {
        border-top: 0;
    }
    .dropdown-menu>li>a {
        font-size: 15px;
        font-weight: 400;
        padding: 15px 0;
        color: #333;
    }
    .dropdown-menu>li>a:hover {
        text-decoration: none;
        color: #00bbb4;
    }
    .navbar-nav>li {
        float: left;
    }
    .navbar-nav>li a .new-link-highlight {
        padding: 1px 4px;
        border-radius: 6.5px;
        margin-top: -15px;
        display: block;
        background-color: #f5edea;
        color: #ff7044;
        font-size: 9px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
    }
    .navbar-nav>.dropdown.open>a {
        background: 0 0;
    }
    .navbar {
        justify-content: space-around;
    }
    .terms a {
        color: #777 !important;
        font-size: 13px !important;
    }
}

@media screen and (max-width: 420px) {
    .banner-section .buy-gold .gold-info ul .bold {
        font-size: 14px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    .owl-carousel .owl-item img {
        width: 50% !important;
    }
    footer .column-2 p {
        width: 186px;
    }
    #loginModal.modal.fade .modal-dialog {
        transform: translate3d(0, 110px, 0) !important;
    }
}

@media only screen and (min-device-width: 320px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
    #loginModal.modal.fade .modal-dialog {
        transform: translate3d(0, 110px, 0) !important;
    }
    .banner-section .buy-gold .gold-calculator form label,
    .radio_lb.gold-radio {
        font-size: 10px;
    }
    .banner-section .buy-gold .gold-calculator form input {
        font-size: 12px;
    }
    .measurement_rupee::after {
        font-size: 13px;
        top: -34px;
    }
    .banner-section .buy-gold .gold-calculator form input::-webkit-input-placeholder {
        font-size: 13px;
    }
    .banner-section .buy-gold .gold-calculator form input:-ms-input-placeholder {
        font-size: 13px;
    }
    .banner-section .buy-gold .gold-calculator form input::-ms-input-placeholder {
        font-size: 13px;
    }
    .banner-section .buy-gold .gold-calculator form input::placeholder {
        font-size: 13px;
    }
    .post-gm {
        font-size: 10px;
        top: 37%;
        left: 31%;
    }
}

@media only screen and (min-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: landscape) {}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (min-aspect-ratio: 13/9) and (orientation: landscape) {}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .banner-section .buy-gold .gold-calculator form input {
        font-size: 20px;
    }
    .measurement_rupee::after {
        font-size: 20px;
        top: -40px;
    }
    .mat-input {
        font-size: 22px;
    }
}

@media only screen and (min-device-width: px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .input-group-append {
        padding-top: 3%;
    }
    .measurement_rupee::after {
        top: -49px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {}

@media only screen and (max-width: 741px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    #signUpModal.modal.fade .modal-dialog {
        transform: translate3d(119px, 410px, 90px) !important;
    }
    #loginModal.modal.fade .modal-dialog {
        transform: translate3d(123px, 260px, 0) !important;
    }
    #otpModal.modal.fade .modal-dialog {
        transform: translate3d(123px, 180px, 10px) !important;
    }
    .navbar .mobile-nav-container {
        overflow-y: scroll;
    }
    .owl-carousel .owl-item img {
        width: 53% !important;
    }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (min-aspect-ratio: 13/9) and (orientation: landscape) {
    #signUpModal.modal.fade .modal-dialog {
        transform: translate3d(74px, 410px, 90px) !important;
    }
    #loginModal.modal.fade .modal-dialog {
        transform: translate3d(70px, 260px, 0) !important;
    }
    #otpModal.modal.fade .modal-dialog {
        transform: translate3d(70px, 160px, 10px) !important;
    }
    .navbar .mobile-nav-container {
        overflow-y: scroll;
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    body.modal-open {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    main.homepage .banner-section,
    main.homepage .trusted-partners.partners-desktop {
        padding-right: 5rem;
        padding-left: 5rem;
    }
    main.homepage .digital-section,
    main.homepage .redeem-gold-section,
    main.homepage .news,
    main.homepage .better-than-savings-section,
    footer .footer-outer .footer-inner {
        padding-right: 6rem;
        padding-left: 6rem;
    }
    main.homepage .measurement_rupee::after {
        left: 0;
    }
}

.landscape-message {
    display: none;
}

@media (min-width: 480px) and (max-width: 959px) and (orientation: landscape) {
    body>* {
        display: none !important;
    }
    body {
        background: linear-gradient(216.55deg, #00bbb4 0%, #00998e 85.15%) !important;
    }
    .landscape-message .landscape-message-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
        align-items: center;
        font: 500 9vh Sarabun;
        color: #fff;
        line-height: 0px;
    }
    .landscape-message .landscape-message-container span {
        color: #d2c061;
        font: 500 5vh Sarabun;
    }
    .landscape-message {
        display: block !important;
        height: 100vh !important;
    }
}

.moe-modal-branding img {
    vertical-align: bottom !important;
    width: 63px !important;
    height: 30px !important;
    padding-right: 2px !important;
    display: inline-block !important;
    margin-bottom: -9px;
    margin-left: -5px;
}

.moe-modal-branding span a {
    text-decoration: none !important;
    color: #000 !important;
    font-size: 10px !important;
    line-height: 1.2em !important;
    font-weight: 400 !important;
}

.moe-modal-branding {
    float: left;
    font-size: 10px;
    margin-top: -2px;
    font-family: Arial !important;
    line-height: 1.2em !important;
}

.moe-chrome-style-notification-safari {
    width: 422px;
    top: 1px;
    left: calc(50% - 211px);
    background-color: #ffffff;
    margin: 0;
    padding: 0;
}

.moe-chrome-style-notification {
    box-shadow: 0 0 4px #888;
    font-size: 11px;
    font-weight: 400;
    position: fixed;
    z-index: 2147483647;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
}

.moe-safari-notification-inner-wrapper {
    margin: 0;
    padding: 0 15px 10px;
}

.moe-notification-image-wrapper-safari {
    float: left;
    position: relative;
    margin: 15px 15px 0 0 !important;
    padding: 0 !important;
    display: inline-block;
}

.moe-chrome-style-notification-safari * {
    word-spacing: normal !important;
    letter-spacing: normal !important;
    font-family: "Open Sans", sans-serif !important;
}

.moe-text-wrapper-safari {
    position: relative !important;
    padding: 10px 0 0 !important;
    color: #000 !important;
    text-align: left !important;
    margin: 0 !important;
    line-height: 1.4em !important;
    display: inline-block !important;
    width: calc(100% - 80px) !important;
}

.moe-notification-title-safari {
    margin-bottom: 5px;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4em;
    color: #000;
    font-family: "Open Sans", sans-serif;
}

.moe-notification-message-safari {
    font-size: 12px !important;
    line-height: 1.4em !important;
    margin: 10px 0 !important;
    padding: 0 !important;
    text-align: left !important;
    font-family: "Open Sans", sans-serif !important;
}

.moe-chrome-style-notification .moe-button-wrapper {
    float: right !important;
    margin: 0 !important;
    padding: 0 !important;
}

.moe-chrome-style-notification-safari .moe-chrome-style-notification-btn.moe-btn-close {
    background: #fff !important;
    color: #000 !important;
    border-color: #ccc !important;
    margin-right: 15px !important;
    width: auto !important;
}

.moe-chrome-style-notification-safari .moe-chrome-style-notification-btn {
    width: 90px !important;
    height: 26px !important;
    font-size: 14px !important;
    cursor: pointer !important;
    line-height: 1.1em !important;
    border-radius: 4px !important;
    color: #fff !important;
    background: linear-gradient(to bottom, #23bd9f, #12ac8e) !important;
    border: 1px solid #12ac8e !important;
    display: inline-block !important;
    font-weight: 400 !important;
    margin: 0 !important;
    padding: 5px !important;
    text-transform: none !important;
    box-sizing: border-box !important;
    font-family: Arial !important;
    text-shadow: none !important;
    box-shadow: none !important;
    white-space: nowrap !important;
}

.moe-notification-image-wrapper-safari img {
    height: 65px !important;
    width: 65px !important;
}

@media screen and (max-width: 420px) {
    .moe-chrome-style-notification {
        width: 100%;
        top: 0;
        left: 0;
    }
}

.floating-bar-desktop {
    padding: 13px 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    background: linear-gradient(180deg, #ffd561 5.44%, #fed160 40.77%, #fcc65e 72.73%, #f9b35b 103.02%, #f49856 134.98%, #f49656 136.66%);
    text-align: center;
}

.floating-bar-desktop img {
    display: inline-block;
    vertical-align: middle;
}

.floating-bar-desktop span {
    margin: 0 40px 0 10px;
    display: inline-block;
    vertical-align: middle;
    color: #000;
    font-family: "Sarabun Bold";
    font-size: 20px;
    line-height: 26px;
}

.floating-bar-desktop a {
    padding: 5px 15px;
    border-radius: 50px;
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    color: #c4a646;
    font-family: "Sarabun";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}

.floating-bar-desktop a:hover,
.floating-bar-desktop a:active,
.floating-bar-desktop a:focus {
    color: #c4a646;
}

.floating-bar-desktop a.close-button {
    padding: 0;
    margin-top: 5px;
    margin-right: 3.5rem;
    float: right;
    background: transparent;
}

.floating-bar-desktop a.close-button:hover,
.floating-bar-desktop a.close-button:active,
.floating-bar-desktop a.close-button:focus {
    color: #fff;
}

.floating-bar-desktop a img {
    margin-left: 5px;
    vertical-align: unset;
}

.floating-bar-mobile {
    display: none;
}

@media screen and (max-width: 990px) {
    .floating-bar-desktop {
        display: none;
    }
    .floating-bar-mobile {
        width: 100%;
        padding: 10px 0;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
        display: block;
        position: fixed;
        z-index: 9999;
        bottom: -80px;
        background: linear-gradient(180deg, #ffd561 5.44%, #fed160 40.77%, #fcc65e 72.73%, #f9b35b 103.02%, #f49856 134.98%, #f49656 136.66%);
        text-align: center;
        animation-name: slideup;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-out;
    }
    @keyframes slideup {
        0% {
            bottom: -80px;
        }
        100% {
            bottom: 0;
        }
    }
    .floating-bar-mobile .floating-bar-container {
        position: relative;
    }
    .floating-bar-mobile .floating-bar-container img {
        height: 48px;
        width: 48px;
        padding: 5px;
        border-radius: 50%;
        margin-left: 15px;
        box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.15);
        position: absolute;
        top: -4.5rem;
        left: 0;
        background: #fff;
    }
    .floating-bar-mobile .floating-bar-container .text {
        margin: 10px auto;
        text-align: center;
    }
    .floating-bar-mobile .floating-bar-container .text span {
        display: block;
        font-family: "Sarabun";
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
    }
    .floating-bar-mobile .floating-bar-container .text span.jeweller {
        display: block;
        margin-bottom: 3px;
        color: #083863;
        font-size: 14px;
        line-height: 17px;
    }
    .floating-bar-mobile .floating-bar-container .floated-div {
        float: right;
    }
    .floating-bar-mobile .floating-bar-container a {
        padding: 5px 10px 5px 15px;
        border-radius: 5rem;
        margin: 0 auto;
        display: inline-block;
        background: #fff;
        color: #c4a646;
        font-family: "Sarabun";
        font-size: 10px;
        font-weight: 600;
        line-height: 13px;
    }
    .floating-bar-mobile .floating-bar-container a.close-button {
        padding: 0;
        margin: 0;
        position: absolute;
        top: -15px;
        right: 10px;
        background: transparent;
    }
    .floating-bar-mobile .floating-bar-container a img {
        height: auto;
        width: auto;
        border-radius: unset;
        margin-left: 2px;
        box-shadow: unset;
        display: inline-block;
        vertical-align: text-top;
        position: static;
        background: transparent;
        color: #fff;
    }
}

.safety-of-gold ul li .pure-gold div {
    margin-left: 32px;
}

.safety-of-gold ul li .pure-gold {
    align-items: center;
}

.banner-section .banner-content p .bold_text {
    font-size: 20px;
    font-weight: 600;
}

.user-trend-container {
    /* background: url("../../new-landingpage/images/global/user-trend-bg.png") no-repeat bottom left #ffffff; */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 29px;
    width: 1103px;
    height: 252px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-trend-container h4 {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 75px;
    text-align: center;
    color: #083863;
}

.user-trend-container h6 {
    font-family: "Sarabun";
    font-style: italic;
    font-weight: normal;
    font-size: 22px;
    line-height: 29px;
    color: #00bbb4;
}

.mobile-user-trend {
    display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
    .desktop-user-trend {
        margin-top: 100px;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1440px) {
    .desktop-user-trend {
        margin-top: 50px;
    }
}

@media screen and (min-width: 1550px) and (max-width: 1600px) {
    .desktop-user-trend {
        margin-top: 100px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1680px) {
    .desktop-user-trend {
        margin-top: 0px;
    }
}

@media screen and (max-width: 991px) {
    .user-trend-container {
        /* background: url(../../new-landingpage/images/global/user-trend-mobile-bg.png) bottom center/contain no-repeat #fff; */
        box-shadow: 0 4px 20px rgb(0 0 0 / 5%);
        border-radius: 11px;
        width: 100%;
        height: 140px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .user-trend-container h1 {
        margin-bottom: 10px !important;
        color: #083863;
        font-family: "Sarabun";
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        text-align: left;
    }
    .user-trend-container h1 span {
        display: block;
        color: #fcd055;
        font-size: 16px;
        line-height: 19px;
    }
    .user-trend-container h4 {
        color: #00bbb4;
        font-family: "Sarabun";
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
        text-align: left;
    }
    .user-trend-container h6 {
        margin-bottom: 0;
        color: #00BBB4;
        font-family: "Sarabun";
        font-size: 10px;
        font-style: italic;
        font-weight: normal;
        line-height: 13px;
        text-align: left;
    }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
    .banner-section .banner-content-mobile h1 {
        font-size: 28px;
    }
}

@media screen and (max-width: 767px) {
    .desktop-user-trend {
        display: none;
    }
    .mobile-user-trend {
        display: block;
    }
    .banner-section .column-2 {
        margin-top: 2.5rem;
    }
    .storage-partner-mobile .partner-images {
        justify-content: space-around;
    }
    .storage-partner-mobile .partner-images .vistra {
        width: 120px;
    }
}

@media screen and (max-width: 320px) {}

.nudge-wrapper {
    display: none;
}

@media screen and (max-width: 767px) {
    .nudge-wrapper {
        display: block;
        background: #ffffff;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        padding: 9px 12px;
    }
    .nudge-wrapper h6 {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #083863;
        margin: 0 9px;
    }
    .nudge-wrapper .nudge-btn {
        background: #ffffff;
        border: 0.75px solid #00bbb4;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: "Sarabun";
        font-weight: bold;
        font-size: 12px;
        line-height: 13px;
        color: #00bbb4;
        width: 170px;
        padding: 5px 6px 6px;
    }
    .nudge-wrapper .close-btn {
        padding: 0;
        background: none;
        box-shadow: none;
        border-radius: 0;
        width: 16px;
        margin-left: 10px;
        border: 0;
    }
    .nudge-wrapper .close-btn img {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 320px) {
    .nudge-wrapper .nudge-btn {
        font-size: 9px;
        line-height: 11px;
        width: 140px;
    }
    .nudge-wrapper h6 {
        font-size: 10px;
        line-height: 12px;
    }
}

@media screen and (max-width: 320px) {
    .floating-bar-mobile .floating-bar-container .text span.jeweller {
        font-size: 13px;
    }
}

.text-shine {
    background: linear-gradient(110deg, rgba(240, 164, 48, 1), 45%, rgba(255, 255, 20, 1), 55%, rgba(240, 164, 48, 1));
    background-size: 260% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textShine 2.5s ease-out infinite alternate;
}

@keyframes textShine {
    to {
        background-position: -200%;
    }
}

.navbar .text-shine.active::after {
    content: "";
    border-color: rgba(240, 164, 48, 1);
    background: rgba(240, 164, 48, 1);
}

.banner-section .buy-gold .gold-calculator form button.no-arrow::after {
    content: "";
}

.better-than-savings-section .grow-wealth-smarter a.button.no-arrow::after {
    content: "";
}

@media (max-width: 767px) {
    .nav_tabs li a .new-link-highlight {
        width: fit-content;
        padding: 1px 4px;
        border-radius: 6.5px;
        margin: -10px auto 0;
        display: block;
        background-color: #f5edea;
        color: #ff7044;
        font-size: 9px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
    }
    .js-content:first-child {
        margin-top: 3vh;
    }
}